import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';
import { IPdfReports } from 'components/pages/businessCase/reports/components/pdfReport/types';

import { assumptionsImagesPath } from '../assumptionsAndReports';
import styles from '../styles';
import { TOptionsBase } from 'i18next';

interface IProps {
  pdfReport: IPdfReports;
  evICEComparisonLineChartUrl?: string;
}
const OverallCostOverview = ({ pdfReport, evICEComparisonLineChartUrl }: IProps) => {
  const { t } = useTranslation('report');
  const { t: tCommon } = useTranslation('common');
  const T = (key: string, option?: TOptionsBase & object & { tco: number }) =>
    t(`overallCostOverview.${key}`, option!);

  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
      </View>
      <View>
        <Text style={styles.totalCostDesc}>
          <Text style={styles.totalCostTitle}>
            {T('disclaimerTitle', { tco: pdfReport?.tcoBreakevenPoint })}
          </Text>
          {T('disclaimerSubtitle')}
        </Text>
      </View>
      <View style={styles.totalCostAmount}>
        <Image style={styles.totalCostImage} src={`${assumptionsImagesPath}/totalCost.png`} />

        <View>
          <Text style={styles.totalCostUnit}>
            {tCommon('formattedPrice', { value: pdfReport?.savingPotential })}{' '}
            {pdfReport?.country?.CurrencySign}
          </Text>
          <Text style={styles.totalCostUnitDesc}>{T('totalCostUnitDesc')}</Text>
        </View>
      </View>
      <Image src={evICEComparisonLineChartUrl} />
    </PageLayout>
  );
};
export default OverallCostOverview;
