import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const InstallationIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M13.9971 3C13.9953 2.20489 13.6788 1.44284 13.1167 0.880615C12.5545 0.318387 11.7927 0.00176104 10.9977 0H2.99938C2.20389 0 1.44101 0.316053 0.87852 0.878662C0.316028 1.44127 0 2.20435 0 3C0 3.79565 0.316028 4.55869 0.87852 5.1213C1.44101 5.68391 2.20389 6 2.99938 6H3.99917V23C3.99892 23.1545 4.03309 23.307 4.09916 23.4467L6.09875 27.4467C6.18132 27.6139 6.30902 27.7548 6.46744 27.8533C6.62587 27.9518 6.80868 28.0039 6.99521 28.0039C7.18174 28.0039 7.36455 27.9518 7.52297 27.8533C7.68139 27.7548 7.80913 27.6139 7.89171 27.4467L9.89129 23.4467C9.95946 23.3075 9.99587 23.1549 9.99792 23V6H10.9977C11.7927 5.99824 12.5545 5.68161 13.1167 5.11938C13.6788 4.55716 13.9953 3.79511 13.9971 3ZM1.99958 3C1.99958 2.73478 2.10491 2.48042 2.29241 2.29289C2.47991 2.10535 2.73421 2 2.99938 2H10.9977C11.2629 2 11.5172 2.10535 11.7047 2.29289C11.8922 2.48042 11.9975 2.73478 11.9975 3C11.9975 3.26522 11.8922 3.51958 11.7047 3.70711C11.5172 3.89465 11.2629 4 10.9977 4H2.99938C2.73421 4 2.47991 3.89465 2.29241 3.70711C2.10491 3.51958 1.99958 3.26522 1.99958 3ZM7.99833 22.7666L6.99854 24.7666L5.99875 22.7666V6H7.99833V22.7666Z" />
        <path d="M31.9199 12.1467C31.7413 11.3703 31.2618 10.6965 30.5869 10.2734L23.8083 6.01338C23.1348 5.58992 22.3207 5.45142 21.5451 5.62833C20.7694 5.80524 20.0959 6.28309 19.6725 6.95674C19.2491 7.63039 19.1106 8.44468 19.2875 9.22045C19.4644 9.99623 19.9421 10.67 20.6156 11.0934L21.4621 11.6267L12.4173 26.0201C12.333 26.1505 12.2806 26.299 12.264 26.4534L11.8308 30.9001C11.8111 31.0851 11.8438 31.272 11.9251 31.4393C12.0064 31.6067 12.1331 31.7478 12.2907 31.8467C12.451 31.9458 12.6354 31.9989 12.8239 32.0001C13.0076 32.0012 13.1878 31.9504 13.3438 31.8534L17.163 29.5334C17.2965 29.4552 17.4087 29.3453 17.4896 29.2134L26.541 14.8201L27.3876 15.3534C27.864 15.6551 28.4166 15.8146 28.9806 15.8134C29.2048 15.8145 29.4284 15.7899 29.6471 15.7401C30.0315 15.6527 30.3948 15.4904 30.7164 15.2625C31.0381 15.0346 31.3116 14.7455 31.5215 14.4118C31.7313 14.0781 31.8733 13.7063 31.9394 13.3176C32.0055 12.929 31.9943 12.5311 31.9066 12.1467H31.9199ZM15.9233 27.9467L14.017 29.1067L14.2303 26.8801L23.1551 12.6934L24.8481 13.7601L15.9233 27.9467ZM29.8337 13.3467C29.7639 13.4589 29.6727 13.5561 29.5651 13.6328C29.4576 13.7094 29.3359 13.764 29.2072 13.7934C29.0791 13.8232 28.9464 13.8268 28.8169 13.8039C28.6875 13.7809 28.564 13.732 28.454 13.6601L26.7677 12.6L23.3817 10.4734L21.7154 9.40006C21.6042 9.33152 21.508 9.24131 21.4324 9.1348C21.3568 9.02828 21.3035 8.90764 21.2755 8.78006C21.245 8.65175 21.2403 8.51863 21.2618 8.3885C21.2833 8.25837 21.3304 8.13383 21.4006 8.02213C21.4707 7.91042 21.5623 7.81381 21.6702 7.73791C21.778 7.66201 21.8998 7.60836 22.0286 7.58007C22.1039 7.5703 22.1801 7.5703 22.2553 7.58007C22.4437 7.58125 22.6282 7.63429 22.7885 7.73339L29.5604 11.9934C29.7839 12.1362 29.9419 12.3614 30.0003 12.6201C30.0424 12.8739 29.9852 13.1341 29.8404 13.3467H29.8337Z" />
      </g>
    </SvgIcon>
  );
};

export default InstallationIcon;
