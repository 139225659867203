import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const PoolVehicles = (props: IProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M12 1.52588e-05C11.8011 1.52588e-05 11.6103 0.0790285 11.4697 0.219681C11.329 0.360333 11.25 0.551103 11.25 0.750015V23.25C11.25 23.4489 11.329 23.6397 11.4697 23.7803C11.6103 23.921 11.8011 24 12 24C12.1989 24 12.3897 23.921 12.5303 23.7803C12.671 23.6397 12.75 23.4489 12.75 23.25V0.750015C12.75 0.551103 12.671 0.360333 12.5303 0.219681C12.3897 0.0790285 12.1989 1.52588e-05 12 1.52588e-05Z" />
        <path d="M24 18.75C23.9985 18.1383 23.8691 17.5337 23.62 16.975C23.6219 16.9567 23.6219 16.9383 23.62 16.92C23.4039 16.4523 23.1078 16.0258 22.745 15.66L20.915 13.83L19.275 9.44997C19.0611 8.87737 18.6773 8.38379 18.1751 8.03529C17.6729 7.6868 17.0763 7.50007 16.465 7.50002H15C14.8011 7.50002 14.6103 7.57903 14.4697 7.71968C14.329 7.86033 14.25 8.0511 14.25 8.25002C14.25 8.44893 14.329 8.6397 14.4697 8.78035C14.6103 8.921 14.8011 9.00002 15 9.00002H16.44C16.7486 9.00191 17.0491 9.09895 17.3006 9.27791C17.552 9.45687 17.7421 9.70904 17.845 10L19.165 13.5H15C14.8011 13.5 14.6103 13.579 14.4697 13.7197C14.329 13.8603 14.25 14.0511 14.25 14.25C14.25 14.4489 14.329 14.6397 14.4697 14.7803C14.6103 14.921 14.8011 15 15 15H19.94L21.44 16.5H18.75C18.5511 16.5 18.3603 16.579 18.2197 16.7197C18.079 16.8603 18 17.0511 18 17.25C18 17.4489 18.079 17.6397 18.2197 17.7803C18.3603 17.921 18.5511 18 18.75 18H22.39C22.459 18.2442 22.496 18.4963 22.5 18.75C22.5 18.9489 22.421 19.1397 22.2803 19.2803C22.1397 19.421 21.9489 19.5 21.75 19.5H15C14.8011 19.5 14.6103 19.579 14.4697 19.7197C14.329 19.8603 14.25 20.0511 14.25 20.25C14.25 20.4489 14.329 20.6397 14.4697 20.7803C14.6103 20.921 14.8011 21 15 21H18V21.75C18 22.3468 18.2371 22.919 18.659 23.341C19.081 23.7629 19.6533 24 20.25 24C20.8467 24 21.419 23.7629 21.841 23.341C22.2629 22.919 22.5 22.3468 22.5 21.75V20.865C22.9373 20.7096 23.3159 20.4231 23.5844 20.0446C23.8528 19.6661 23.998 19.2141 24 18.75ZM21 21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5C20.0511 22.5 19.8603 22.421 19.7197 22.2803C19.579 22.1397 19.5 21.9489 19.5 21.75V21H21V21.75Z" />
        <path d="M7.555 9.00002H9C9.19891 9.00002 9.38968 8.921 9.53033 8.78035C9.67098 8.6397 9.75 8.44893 9.75 8.25002C9.75 8.0511 9.67098 7.86033 9.53033 7.71968C9.38968 7.57903 9.19891 7.50002 9 7.50002H7.555C6.94459 7.50108 6.34903 7.68833 5.84783 8.03676C5.34663 8.38519 4.96364 8.87816 4.75 9.44997L3.105 13.835L1.28 15.66C0.917204 16.0258 0.621089 16.4523 0.405003 16.92C0.394638 16.9373 0.386251 16.9558 0.380001 16.975C0.130917 17.5337 0.00147613 18.1383 0 18.75C0.00202914 19.2141 0.147169 19.6661 0.415615 20.0446C0.684061 20.4231 1.06274 20.7096 1.5 20.865V21.75C1.5 22.3468 1.73706 22.919 2.15901 23.341C2.58097 23.7629 3.15326 24 3.75 24C4.34674 24 4.91903 23.7629 5.34099 23.341C5.76295 22.919 6 22.3468 6 21.75V21H9C9.19891 21 9.38968 20.921 9.53033 20.7803C9.67098 20.6397 9.75 20.4489 9.75 20.25C9.75 20.0511 9.67098 19.8603 9.53033 19.7197C9.38968 19.579 9.19891 19.5 9 19.5H2.25C2.05109 19.5 1.86032 19.421 1.71967 19.2803C1.57902 19.1397 1.5 18.9489 1.5 18.75C1.50398 18.4963 1.54096 18.2442 1.61 18H5.25C5.44891 18 5.63968 17.921 5.78033 17.7803C5.92098 17.6397 6 17.4489 6 17.25C6 17.0511 5.92098 16.8603 5.78033 16.7197C5.63968 16.579 5.44891 16.5 5.25 16.5H2.56L4.06 15H9C9.19891 15 9.38968 14.921 9.53033 14.7803C9.67098 14.6397 9.75 14.4489 9.75 14.25C9.75 14.0511 9.67098 13.8603 9.53033 13.7197C9.38968 13.579 9.19891 13.5 9 13.5H4.83L6.155 10C6.25758 9.70984 6.44693 9.4582 6.69738 9.27931C6.94783 9.10042 7.24724 9.00294 7.555 9.00002ZM4.5 21V21.75C4.5 21.9489 4.42098 22.1397 4.28033 22.2803C4.13968 22.421 3.94891 22.5 3.75 22.5C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V21H4.5Z" />
      </g>
    </SvgIcon>
  );
};

export default PoolVehicles;
