import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from 'config';

import BusinessCaseReportsPage from 'pages/businessCase/reports';

import Layout from './components/layouts/layout';

function _ScrollToTop(props: React.PropsWithChildren<any>) {
  const { children } = props;
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

const ScrollToTop = _ScrollToTop;

const AppRoutes: React.FC = () => {
  return (
    <Layout>
      <ScrollToTop>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path={`/`} element={<BusinessCaseReportsPage />} />
          </Routes>
        </QueryClientProvider>
      </ScrollToTop>
    </Layout>
  );
};

const AppRouter: React.FC = () => (
  <Router>
    <AppRoutes />
  </Router>
);

export default AppRouter;
