import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const RideHailingIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M6 9C6.89002 9 7.76004 8.73609 8.50006 8.24162C9.24008 7.74716 9.81686 7.04436 10.1575 6.22209C10.498 5.39982 10.5872 4.49499 10.4135 3.62208C10.2399 2.74916 9.8113 1.94733 9.18197 1.318C8.55263 0.688662 7.75082 0.260105 6.87791 0.0864716C6.00499 -0.0871619 5.10018 0.00194013 4.27791 0.342534C3.45565 0.683129 2.75285 1.2599 2.25838 1.99992C1.76392 2.73994 1.5 3.60999 1.5 4.5C1.50176 5.69294 1.97643 6.83649 2.81997 7.68002C3.6635 8.52355 4.80707 8.99824 6 9ZM6 2.00001C6.49445 2.00001 6.97779 2.14661 7.38892 2.42131C7.80004 2.69601 8.12047 3.08648 8.30969 3.5433C8.49891 4.00011 8.54843 4.50276 8.45197 4.98772C8.3555 5.47267 8.11739 5.91811 7.76776 6.26775C7.41813 6.61738 6.97266 6.85549 6.48771 6.95195C6.00276 7.04841 5.50011 6.99892 5.04329 6.8097C4.58648 6.62048 4.19603 6.30004 3.92133 5.88892C3.64662 5.4778 3.5 4.99446 3.5 4.5C3.50176 3.8375 3.76571 3.20264 4.23417 2.73418C4.70263 2.26572 5.3375 2.00176 6 2.00001Z" />
        <path d="M18 3C18 2.20435 17.6839 1.44127 17.1213 0.878662C16.5587 0.316053 15.7956 0 15 0C14.2044 0 13.4413 0.316053 12.8787 0.878662C12.3161 1.44127 12 2.20435 12 3V7C11.9982 7.79511 11.6816 8.55716 11.1194 9.11938C10.5571 9.68161 9.79511 9.99824 9 10H5C3.67392 10 2.40214 10.5268 1.46446 11.4645C0.526775 12.4022 0 13.6739 0 15V21C0.000363915 21.6201 0.192851 22.2248 0.551005 22.731C0.909159 23.2372 1.41538 23.62 2 23.8267V28C2 29.0609 2.42142 30.0783 3.17157 30.8284C3.92172 31.5786 4.93913 32 6 32C7.06087 32 8.07829 31.5786 8.82843 30.8284C9.57858 30.0783 10 29.0609 10 28V15.9467C12.1976 15.6936 14.226 14.643 15.7006 12.9939C17.1751 11.3449 17.9933 9.21214 18 7V3ZM16 7C15.993 8.8555 15.2523 10.6329 13.9396 11.9443C12.6269 13.2558 10.8488 13.9947 8.99333 14C8.72927 14.0018 8.47662 14.1079 8.29053 14.2952C8.10443 14.4826 7.99999 14.7359 8 15V28C8 28.5304 7.78928 29.0392 7.41421 29.4142C7.03913 29.7893 6.53043 30 6 30C5.46957 30 4.96085 29.7893 4.58577 29.4142C4.2107 29.0392 4 28.5304 4 28V23C4 22.7348 3.89463 22.4804 3.70709 22.2929C3.51956 22.1054 3.26522 22 3 22C2.73478 22 2.48042 21.8946 2.29289 21.7071C2.10535 21.5196 2 21.2652 2 21V15C2 14.2043 2.31607 13.4413 2.87868 12.8787C3.44129 12.3161 4.20435 12 5 12H9C10.325 11.9965 11.5947 11.4686 12.5316 10.5317C13.4686 9.59474 13.9965 8.325 14 7V3C14 2.73478 14.1054 2.48042 14.2929 2.29289C14.4804 2.10535 14.7348 2 15 2C15.2652 2 15.5196 2.10535 15.7071 2.29289C15.8946 2.48042 16 2.73478 16 3V7Z" />
        <path d="M21.9134 21H31C31.2652 21 31.5196 20.8947 31.7071 20.7071C31.8947 20.5196 32 20.2652 32 20C32 19.7348 31.8947 19.4804 31.7071 19.2929C31.5196 19.1054 31.2652 19 31 19H22.9534L24.7134 14.3C24.856 13.9183 25.1118 13.5892 25.4466 13.3569C25.7814 13.1245 26.1792 13 26.5867 13H31.0134C31.2786 13 31.5329 12.8947 31.7205 12.7071C31.908 12.5196 32.0134 12.2652 32.0134 12C32.0134 11.7348 31.908 11.4804 31.7205 11.2929C31.5329 11.1054 31.2786 11 31.0134 11H26.5933C25.778 10.9982 24.9816 11.2456 24.3107 11.7091C23.6399 12.1726 23.1267 12.8301 22.84 13.5934L20.6533 19.4467L18.2067 21.8667C17.6638 22.4064 17.2335 23.0485 16.9405 23.7557C16.6476 24.4629 16.4979 25.2212 16.5 25.9867C16.5012 26.6049 16.6942 27.2076 17.0524 27.7115C17.4107 28.2154 17.9165 28.5958 18.5 28.8V28.9867C18.5 29.7823 18.8161 30.5454 19.3787 31.108C19.9413 31.6706 20.7044 31.9867 21.5 31.9867C22.2957 31.9867 23.0587 31.6706 23.6213 31.108C24.184 30.5454 24.5 29.7823 24.5 28.9867H31C31.2652 28.9867 31.5196 28.8813 31.7071 28.6938C31.8947 28.5062 32 28.2519 32 27.9867C32 27.7214 31.8947 27.4671 31.7071 27.2796C31.5196 27.092 31.2652 26.9867 31 26.9867H19.5C19.2348 26.9867 18.9804 26.8813 18.7929 26.6938C18.6054 26.5062 18.5 26.2519 18.5 25.9867C18.4991 25.484 18.5975 24.986 18.7898 24.5215C18.982 24.057 19.2641 23.6351 19.62 23.28L21.9134 21ZM22.5 29C22.5 29.2652 22.3947 29.5196 22.2071 29.7071C22.0196 29.8947 21.7652 30 21.5 30C21.2348 30 20.9804 29.8947 20.7929 29.7071C20.6054 29.5196 20.5 29.2652 20.5 29H22.5Z" />
      </g>
    </SvgIcon>
  );
};

export default RideHailingIcon;
