import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    color: '#4DC3AE',
  },
  header: {
    margin: '60px 0 0 60px',
  },
  headerTitle: {
    fontSize: 42,
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },
  inCharge: {
    display: 'flex',
    flexDirection: 'row',
  },
  photo: {
    width: 233,
    height: 358,
  },
  info: {
    margin: '60px 0 0 20px',
    color: '#8F92A6',
    fontFamily: 'Inter',
  },
  name: {
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#131630',
    marginBottom: 10,
  },
  position: {
    fontWeight: 600,
    fontSize: 18,
  },
  contactInfo: {
    fontSize: 14,
    marginTop: 4,
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    right: 30,
  },
  logo: {
    width: 98,
  },
});

export default styles;
