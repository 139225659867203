import { IMobileEmail } from '../components/pages/mobileModal';
import config from '../config';

import HttpService from './http.service';

class InitialCheckAPI extends HttpService {
  getCountries = async () => {
    return await this.get(`countries`);
  };

  getFleetProfile = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/fleet-profile`);
  };

  getCommercialType = async () => {
    return await this.get(`commercial-fleet-types?limit=20`);
  };
  getFleetTypes = async () => {
    return await this.get(`fleet-types`);
  };
  getFleetDetails = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/fleet-details`);
  };
  teaserOverview = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/teaser-overview`);
  };
  getElectrifyingVehicles = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/electrifying-vehicles`);
  };

  deleteParkingLocations = async ({ leadId, parkingId }: { leadId: string; parkingId: string }) => {
    return await this.delete(`fleet-check/${leadId}/parking-locations/${parkingId}`);
  };
  getElectrification = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/electrification`);
  };
  getWorkplaceOptions = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/workplace-options`);
  };
  getFleetSize = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/fleet-size`);
  };
  getTeaserSavingPotential = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/teaser-saving-potential`);
  };
  getTeaserCO2Emission = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/teaser-co2-emission`);
  };
  getParkingOptions = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/parking-locations`);
  };
  getHomeOptions = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/home-options`);
  };

  getPublicOptions = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/public-options`);
  };
  getChargeOptions = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/charging-options`);
  };

  getElectrificationProfile = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/electrification-profile`);
  };

  getParkedHours = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/parked-hours`);
  };
  getProfile = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/fleet-profile`);
  };

  getEVDetails = async (leadId: string) => {
    return await this.get(`fleet-check/${leadId}/ev-details`);
  };

  mobileEmailSend = async (data: IMobileEmail) => {
    return await this.post(`fleet-check/email-url`, data);
  };
}
export default new InitialCheckAPI({
  apiURL: config.FLEET_MANAGER_SERVICE_URL,
});
