import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  chart: {
    height: 700,
  },
  primaryBorderColor: {
    color: '#4DC3AE',
  },
  totalCostImage: {
    width: '45px',
    paddingRight: '5px',
  },
  deactiveBorderColor: {
    color: '#8F92A6',
  },
  monthlyCost: {
    paddingLeft: '10px',
  },
  chargeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  chargeBoxTitle: {
    color: '#8F92A6',
    fontSize: '14px',
  },
  icon: { width: '24px', height: '24px', marginRight: '6px' },
  chargeBox: {
    border: '1px solid #8F92A6',
    padding: '12px',
    borderRadius: '6px',
    margin: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },

  fleetIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '5px',
    marginRight: '5px',
  },

  fleetSubIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '20px',
    marginRight: '5px',
  },
  fleetDescription: {
    fontSize: '14px',
    fontWeight: 'light',
    lineHeight: '1.5px',
    padding: '4px 0',
    fontFamily: 'Inter',
    color: '#5F6380',
  },
  fleetTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '4px',
    fontFamily: 'Inter',
  },
  header: {
    paddingLeft: '8px',
    borderLeft: '4px solid #60F4D9',
  },
  headerTitle: {
    fontSize: '24px',
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },

  headerSubtitle: {
    fontSize: '18px',
    fontWeight: 'light',
    fontFamily: 'Inter',
    color: '#5F6380',
  },
  fleetWrapper: {
    marginTop: '30px',
  },
  fleet: {
    marginTop: '20px',
  },
  chargingSolar: {
    marginTop: '30px',
  },
  summary: {
    padding: '16px 0',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#282C42',
  },
  boldValue: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginRight: '8px',
    paddingRight: '10px',
    fontFamily: 'Oxanium',
    color: '#282C42',
  },
  chargeSummary: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
  checked: {
    width: '16px',
    height: '16px',
  },
  summaryRow: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  SummaryTitle: {
    fontSize: '12px',
  },
  summaryDesc: {
    padding: '12px 0',
    fontSize: '24px',
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },
  summaryUnit: {
    fontSize: '12px',
    color: '#282C42',
  },
  totalCostDesc: {
    marginTop: '30px',
    padding: '12px',
    border: '1px solid #000',
    fontSize: '9px',
    lineHeight: '1.25px',
    borderRadius: '5px',
    color: '#5F6380',
  },
  totalCostDesc2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    padding: '12px',
    border: '1px solid #000000',
    fontSize: '9px',
    lineHeight: '1px',
    position: 'relative',
    borderRadius: '5px',
    color: '#5F6380',
  },
  totalCostAmount: {
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  totalCostTitle: {
    width: '64%',
    color: '#000',
    paddingRight: '15px',
    fontWeight: 'bold',
  },
  totalCostBold: {
    color: '#282C42',
    fontWeight: 'bold',
  },
  totalCostUnitDesc: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  totalCostUnit: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Oxanium',
  },
  barChartWrapper: {
    marginTop: '80px',
  },
  investmentCostBg: {
    width: '100%',
  },
  investmentItem: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
  },
  chargeAtWork: {
    position: 'absolute',
    top: '8%',
    left: '4%',
    fontSize: '14px',
    width: '190px',
  },
  chargeAtWorkDesc: {
    position: 'absolute',
    top: '55%',
    right: '4%',
    fontSize: '10px',
    width: '210px',
  },
  chargeAtHomeDesc: {
    position: 'absolute',
    top: '8%',
    right: '4%',
    fontSize: '10px',
    width: '210px',
  },
  chargeAtHome: {
    position: 'absolute',
    top: '55%',
    left: '4%',
    fontSize: '14px',
    width: '190px',
  },
  chargeAtWorkHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  chargeAtWorkDetailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '9px',
    padding: '3px 0px',
  },
  chargeAtWorkValues: {
    fontSize: '8px',
    fontWeight: 'bold',
  },
  chargeDescWrapper: {
    position: 'relative',
  },

  chargeAtWorkDetailLastRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '8px',
    borderTop: '1px solid #000',
    paddingTop: '8px',
    marginTop: '8px',
  },
  chargeOverview: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  chargeOverviewAmount: {
    fontFamily: 'Oxanium',
    color: '#4DC3AE',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  chargeOverviewDesc: {
    fontSize: '13px',
    color: '#5F6380',
    textAlign: 'center',
  },
  packages: {
    padding: '16px',
    border: '1px solid #eaeaea',
    borderRadius: '6px',
    margin: '6px',
    width: '32%',
  },
  packagesTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '6px',
  },
  packagesSubtitle: {
    padding: '8px 0',
    fontSize: '9px',
    fontWeight: 'bold',
    color: '#5F6380',
  },

  listView: {
    margin: '1px 4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  listBullet: {
    fontSize: 10,
    color: '#4DC3AE',
    marginRight: '3px',
    marginTop: -2,
  },
  listText: {
    fontSize: '8px',
    color: '#5F6380',
  },
  perEmp: {
    fontWeight: 'bold',
    margin: '6px 0',
    fontSize: '8px',
  },
  packagesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  monthlyOperationCost: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
  },

  maxCost: {
    color: '#282C42',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 6,
  },
});

export default styles;
