import { Box, Button, Link, Typography } from '@mui/material';
import { Footer } from '../style';
import { useTranslation } from 'react-i18next';
import useBusinessCase from 'components/pages/businessCase/store';
import RegisterDialog from './modal';
import { useState } from 'react';
import ReportPDF from 'components/pages/businessCase/reports/components/pdfReport/pdfReport';
import { useMutation } from '@tanstack/react-query';
import AuthService from 'services/auth.service';
import { toast } from 'react-toastify';

function FooterComponent() {
  const { t } = useTranslation('businessCaseReports');
  const { isRegister } = useBusinessCase();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mutation = useMutation(['register'], AuthService.register, {
    onSuccess: (data) => {
      console.log('dddd', data);
      window.localStorage.setItem('token', data.data.accessToken);
      toast.success('Registered Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleDownloadClick = () => {
    if (isRegister) {
      console.log('is registered');
    } else {
      handleClickOpen();
    }
  };
  return (
    <Footer>
      <Typography variant="h3"> {t('recommendations.footerHeader')}</Typography>
      <Typography variant="body1">{t('recommendations.footerSubtitle')}</Typography>
      <Box my={2.5}>
        <Link href={process.env.REACT_APP_CALENDLY} target="_blank">
          <Button variant="contained" fullWidth>
            {t('recommendations.footerBtn1')}
          </Button>
        </Link>

        <>
          <Typography
            sx={{
              py: 2,
              textAlign: 'center',
            }}
          >
            {t('recommendations.or')}
          </Typography>
          {!mutation.isSuccess && (
            <Button variant="outlined" color="info" fullWidth onClick={() => handleDownloadClick()}>
              {t('recommendations.footerBtn2')}
            </Button>
          )}
        </>
        {/*Don't remove, used for creating report PDF */}
        <div id="report-pdf" />
        {mutation.isSuccess ? (
          <ReportPDF />
        ) : (
          <RegisterDialog open={open} close={handleClose} mutation={mutation} />
        )}
      </Box>
    </Footer>
  );
}
export default FooterComponent;
