import { create } from 'zustand';
export interface IOverview {
  chargingSolution?: string[];
  numberOfParkingSpots?: number[];
  numberOfVehicles?: number;
  acquiringType?: string;
  numberOfWorksites?: number;
}
export interface ICalculate {
  data: IOverview;
  refetch: boolean;
  // eslint-disable-next-line no-unused-vars
  setData: (data: IOverview) => void;
  // eslint-disable-next-line no-unused-vars
  makeRefetch: (flag: boolean) => void;
}
const useCalculate = create<ICalculate>((set) => ({
  data: {},
  refetch: false,
  setData: (newValues: IOverview) => set(() => ({ data: newValues })),
  makeRefetch: (flag: boolean) => set(() => ({ refetch: flag })),
}));
export default useCalculate;
