import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

async function getHash(): Promise<string> {
  const options = {
    excludes: {
      plugins: true,
      localStorage: true,
      adBlock: true,
      screenResolution: true,
      availableScreenResolution: true,
      enumerateDevices: true,
      pixelRatio: true,
      doNotTrack: true,
    },
    preprocessor: (key: any, value: any) => {
      if (key === 'userAgent') {
        const parser = new UAParser(value);
        // return customized user agent (without browser version)
        return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${
          parser.getEngine().name
        }`;
      }
      return value;
    },
  };

  const components = await Fingerprint2.getPromise(options);
  const values = components.map((component) => component.value);

  return String(Fingerprint2.x64hash128(values.join(''), 31));
}

export function createFingerprint(): Promise<string> {
  return new Promise((resolve) => {
    if ((window as any).requestIdleCallback) {
      (window as any).requestIdleCallback(async () => resolve(await getHash()));
    } else {
      setTimeout(async () => resolve(await getHash()), 500);
    }
  });
}
