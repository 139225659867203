import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'config';

import './yupLocale';

import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root')!);

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_BASE_URL || '',
  siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '0', 10),
  srcUrl: `${process.env.REACT_APP_MATOMO_BASE_URL}js/${process.env.REACT_APP_MATOMO_FILE}`,
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: false,
  },
});
Sentry.init({
  dsn: config.SENTRY_DSN_URL,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [config.FLEET_MANAGER_SERVICE_URL],
    }),
  ],
  environment: config.SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});
root.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <MatomoProvider value={instance}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </MatomoProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
