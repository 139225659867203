import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    height: '60px',
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    maxWidth: '100%',
    display: 'block',
    fontSize: '13px',
    minWidth: '100%',
    padding: `${theme.spacing(1.1)} ${theme.spacing(2)}`,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-body2': {
      fontSize: '11px',
      wordBreak: 'break-word',
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& img': {
        marginBottom: theme.spacing(0.5),
      },
    },
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  menuText: {
    fontSize: 10,
    paddingTop: theme.spacing(1),
  },
  wrapper: {
    height: '100%',
  },
  item: {
    textAlign: 'center',
    width: '100%',
  },
  footer: {
    minHeight: '163px',
    '& .MuiTypography-body2': {
      fontSize: '9px',
    },
    '& a': {
      fontSize: '8px',
    },
    '& .MuiGrid-container': {
      flexDirection: 'column',
      '& a': {
        fontSize: '11px',
      },
    },
  },
}));
