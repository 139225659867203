import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';
import { IPdfReports } from 'components/pages/businessCase/reports/components/pdfReport/types';

import PackageIcon from '../../common/packageIcon/packageIcon';
import PackageTitle from '../../common/packageTitle/packageTitle';
import styles from '../styles';
import { TOptionsBase } from 'i18next';

interface IProps {
  pdfReport: IPdfReports;
}
interface IOperationCosts {
  basicPlan: string[];
  mediumPlan: string[];
  premiumPlan: string[];
}

const OperationCosts = ({ pdfReport }: IProps) => {
  const { t } = useTranslation('report');
  const { t: tCommon } = useTranslation('common');
  const translations = t('operationCosts', {
    returnObjects: true,
  }) as IOperationCosts;

  const T = (key: string, option?: TOptionsBase & object & { package: string }) =>
    t(`operationCosts.${key}`, option!);

  const currency = pdfReport?.country?.CurrencySign || '€';
  return (
    <PageLayout caption={T('footer')}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
      </View>
      <View>
        <Text style={styles.totalCostDesc}>{T('headerDesc')}</Text>

        <View style={styles.totalCostDesc2}>
          <View style={styles.totalCostTitle}>
            <Text style={styles.totalCostBold}>
              {T('secondHeaderTitle', { package: pdfReport?.backendPackage })}
            </Text>
            <Text>{T('secondHeaderDesc')}</Text>
          </View>
          <View style={styles.monthlyOperationCost}>
            <Text style={styles.maxCost}>{`${tCommon('formattedPrice', {
              value: pdfReport?.maxOperationCostsPerMonth,
            })} ${currency}`}</Text>
            <Text style={styles.monthlyCost}>{T('monthlyCost')}</Text>
          </View>
        </View>
      </View>
      <View style={styles.packagesWrapper}>
        <View style={styles.packages}>
          <View style={styles.titleWrapper}>
            <PackageIcon type="basic" />
            <PackageTitle title={t('common.basic')} />
          </View>
          <Text style={styles.packagesSubtitle}>{T('perChargepoint')}</Text>
          {translations.basicPlan.map((value) => (
            <View key={value} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{value}</Text>
            </View>
          ))}
          <View>
            <Text style={styles.packagesSubtitle}>{T('perEmployee')}</Text>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('fleetDriveApp')}</Text>
            </View>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('ChargingCard')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.packages}>
          <View style={styles.titleWrapper}>
            <PackageIcon type="medium" />
            <PackageTitle title={`${t('common.medium')}¹`} />
          </View>
          <Text style={styles.packagesSubtitle}>{T('perChargepoint')}</Text>
          {translations.mediumPlan.map((value) => (
            <View key={value} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{value}</Text>
            </View>
          ))}
          <View>
            <Text style={styles.packagesSubtitle}>{T('perCompany')}</Text>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('invoice')}</Text>
            </View>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('chargeDetail')}</Text>
            </View>
            <Text style={styles.packagesSubtitle}>{T('perChargeAtWork')}</Text>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('integrationOfCharging')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.packages}>
          <View style={styles.titleWrapper}>
            <PackageIcon type="premium" />
            <PackageTitle title={`${t('common.premium')}²`} />
          </View>
          <Text style={styles.packagesSubtitle}>{T('perChargepoint')}</Text>
          <View>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('regularMaintenance')}</Text>
            </View>
            <View style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{T('levelSupport')}</Text>
            </View>
          </View>
        </View>
      </View>
    </PageLayout>
  );
};
export default OperationCosts;
