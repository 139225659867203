import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import businessCaseService from 'services/businessCase.service';

import Chart from './chart';
import useCalculate from 'hooks/calculate.zustand';

const EVICELineChart = ({ currency }: { currency: string }) => {
  const calcData = useCalculate((state) => state.data);
  const needRefetch = useCalculate((state) => state.refetch);
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const {
    data: evIceComparisonData,
    isLoading,
    remove,
    refetch,
  } = useQuery(['evIceComparisonLine'], () => businessCaseService.getEvIceComparison(calcData), {
    enabled: false,
    onSuccess: () => makeRefetch(false),
  });

  useEffect(() => {
    if (needRefetch) {
      refetch(calcData as unknown as any);
    }
  }, [needRefetch]);
  useEffect(() => {
    !evIceComparisonData && refetch();
    return () => remove();
  }, [remove]);
  return (
    <>
      <Chart currency={currency} data={evIceComparisonData?.data} isLoading={isLoading} />
      {/* {!isLoading && <Box pl={10}>{t('costEVICE.caption')}</Box>} */}
    </>
  );
};
export default EVICELineChart;
