import { Button, Grid, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParkingWrapper } from './styles';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  saveParkingLocations: Dispatch<SetStateAction<any>>;
  defaultParking: number[];
}
const ParkingLocation = ({ saveParkingLocations, defaultParking }: IProps) => {
  const [inputs, setInputs] = useState<number[]>(defaultParking);
  const { t } = useTranslation('businessCaseReports');
  const handleChange = (value: any, index: number) => {
    const convertedValue = parseInt(value);
    let newForm = [...inputs];
    newForm[index] = convertedValue;
    setInputs(newForm);
    saveParkingLocations(newForm);
  };
  console.log('innputs', inputs);
  const handleDelete = (index: number) => {
    let newForm = [...inputs];
    newForm.splice(index, 1);
    setInputs(newForm);
    saveParkingLocations(newForm);
  };
  const handleAddInput = () => {
    setInputs([...inputs, 1]);
    saveParkingLocations([...inputs, 1]);
  };
  return (
    <ParkingWrapper>
      <Grid container spacing={1} className="inputs">
        {inputs.map((amount, index) => (
          <Grid item xs={12} key={index}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography sx={{ mb: 0.5 }}>
                  {t('editDrawer.parkingSpace', { value: index + 1 })}
                </Typography>
              </Grid>
              {inputs.length > 1 && (
                <Grid item>
                  <DeleteIcon className="deleteIcon" onClick={() => handleDelete(index)} />
                </Grid>
              )}
            </Grid>
            <TextField
              fullWidth
              value={amount.toString()}
              onWheel={() => null}
              onKeyDown={() => null}
              onKeyUp={() => null}
              type="number"
              // inputProps={{ min: '1' }}
              disabled={index < inputs.length - 1}
              onChange={(e) => handleChange(Number(e.target.value), index)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={
            inputs.length === 3 ||
            (inputs.length > 0 &&
              inputs.every((amount) => Number(amount) === 0 || !amount || amount < 0))
          }
          onClick={() => handleAddInput()}
        >
          {t('editDrawer.addCompantLocation')}
        </Button>
      </Grid>
    </ParkingWrapper>
  );
};

export default ParkingLocation;
