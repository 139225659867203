import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const TaxiIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M8.33333 0H1.66667C1.22464 0 0.80072 0.175599 0.488159 0.488159C0.175599 0.80072 0 1.22464 0 1.66667V13.3333C0 13.7754 0.175599 14.1993 0.488159 14.5118C0.80072 14.8244 1.22464 15 1.66667 15H4V31C4 31.2652 4.10537 31.5196 4.29291 31.7071C4.48044 31.8946 4.73478 32 5 32C5.26522 32 5.51958 31.8946 5.70711 31.7071C5.89465 31.5196 6 31.2652 6 31V15H8.33333C8.77536 15 9.1993 14.8244 9.51186 14.5118C9.82442 14.1993 10 13.7754 10 13.3333V1.66667C10 1.22464 9.82442 0.80072 9.51186 0.488159C9.1993 0.175599 8.77536 0 8.33333 0ZM8 13H2V2H8V13Z" />
        <path d="M17 24H15C14.7348 24 14.4804 24.1054 14.2929 24.2929C14.1054 24.4804 14 24.7348 14 25C14 25.2652 14.1054 25.5196 14.2929 25.7071C14.4804 25.8946 14.7348 26 15 26H17C17.2652 26 17.5196 25.8946 17.7071 25.7071C17.8946 25.5196 18 25.2652 18 25C18 24.7348 17.8946 24.4804 17.7071 24.2929C17.5196 24.1054 17.2652 24 17 24Z" />
        <path d="M27 24H25C24.7348 24 24.4804 24.1054 24.2929 24.2929C24.1054 24.4804 24 24.7348 24 25C24 25.2652 24.1054 25.5196 24.2929 25.7071C24.4804 25.8946 24.7348 26 25 26H27C27.2652 26 27.5196 25.8946 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25C28 24.7348 27.8946 24.4804 27.7071 24.2929C27.5196 24.1054 27.2652 24 27 24Z" />
        <path d="M31.5002 22.94L29.9069 20.5533L28.4069 16.0533C28.2099 15.4609 27.833 14.9446 27.3287 14.5766C26.8244 14.2085 26.2178 14.007 25.5935 14H16.4402C15.8108 13.9994 15.1974 14.1976 14.6874 14.5663C14.1774 14.9351 13.7969 15.4555 13.6002 16.0533L12.0935 20.56L10.5068 22.94C10.1792 23.4339 10.0052 24.0139 10.0068 24.6066V27.0067C10.008 27.6249 10.201 28.2276 10.5593 28.7315C10.9175 29.2354 11.4233 29.6157 12.0068 29.82V31C12.0068 31.2652 12.1122 31.5196 12.2997 31.7071C12.4873 31.8946 12.7416 32 13.0068 32C13.2721 32 13.5264 31.8946 13.714 31.7071C13.9015 31.5196 14.0068 31.2652 14.0068 31V30.0067H28.0068V31C28.0068 31.2652 28.1122 31.5196 28.2997 31.7071C28.4873 31.8946 28.7416 32 29.0068 32C29.2721 32 29.5264 31.8946 29.714 31.7071C29.9015 31.5196 30.0068 31.2652 30.0068 31V29.82C30.5899 29.6149 31.0951 29.2344 31.4532 28.7306C31.8113 28.2269 32.0047 27.6247 32.0068 27.0067V24.6066C32.0081 24.013 31.8317 23.4325 31.5002 22.94V22.94ZM15.5002 16.68C15.5702 16.4849 15.6986 16.3163 15.8681 16.1969C16.0375 16.0776 16.2396 16.0135 16.4469 16.0133H25.5935C25.8014 16.0105 26.0049 16.0735 26.1749 16.1932C26.3449 16.3129 26.4728 16.4833 26.5402 16.68L27.6468 20.0133H14.3869L15.5002 16.68ZM30.0135 27.0067C30.0118 27.2673 29.9085 27.517 29.7254 27.7025C29.5424 27.8881 29.2941 27.9948 29.0335 28H12.9802C12.7202 27.9948 12.4727 27.8878 12.2907 27.7021C12.1087 27.5163 12.0068 27.2667 12.0068 27.0067V24.6066C12.0046 24.4074 12.0627 24.2122 12.1735 24.0467L13.5402 22H28.4668L29.8402 24.0533C29.949 24.2174 30.007 24.4098 30.0068 24.6066L30.0135 27.0067Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TaxiIcon;
