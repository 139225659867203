import { get } from 'lodash';
import * as yup from 'yup';

import { PASSWORD_REGEXP, PHONE_REGEXP } from 'constants/validation';
import { getTranslateFn } from 'framework/helpers';

// Simple validation for phone number
yup.addMethod(yup.string, 'phone', () => {
  return yup
    .string()
    .test('phone', getTranslateFn('errors:phone'), (value) => PHONE_REGEXP.test(value!));
});

// Simple validation for password
yup.addMethod(yup.string, 'password', () => {
  return yup
    .string()
    .test('password', getTranslateFn('errors:password'), (value) => PASSWORD_REGEXP.test(value!));
});

// Method used for objects to check that at least one of keys contains value
yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: getTranslateFn('errors:atLeastOneOf'),
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value === null || list.some((f: string) => !!get(value, f)),
  });
});

/*
  Here we have ability to add any needed translation
  for yup standart validation error messages.
*/
yup.setLocale({
  mixed: {
    required: getTranslateFn('errors:required'),
  },
  string: {
    max: getTranslateFn('errors:maxString'),
    min: getTranslateFn('errors:minString'),
    email: getTranslateFn('errors:email'),
  },
});

export default yup;
