import BenefitCars from './benefitCars';
import carSharingFleet from './carSharingFleet';
import chargingFleet from './chargingFleet';
import chartMode from './chartMode';
import close from './close';
import cookie from './cookie';
import customize from './customize';
import deliveryLogistics from './deliveryLogistics';
import fleet from './fleet';
import general from './general';
import GovernmentMunicipal from './governmentMunicipal';
import info from './info';
import infrastructure from './infrastructure';
import installationMaintenanceFleet from './installationMaintenanceFleet';
import location from './location';
import LongHaulTransportation from './longHaulTransportation';
import medicalServiceFleet from './medicalServiceFleet';
import noChoice from './noChoice';
import operations from './operations';
import others from './others';
import PoolVehicles from './poolVehicles';
import rideHailingFleet from './rideHailingFleet';
import roadmap from './roadmap';
import Sales from './sales';
import setting from './setting';
import sheet from './sheet';
import taxi from './taxi';

interface IIcons {
  [key: string]: any;
}
const icons: IIcons = {
  taxi: taxi,
  info: info,
  others: others,
  carSharingFleet: carSharingFleet,
  rideHailingFleet: rideHailingFleet,
  deliveryLogistics: deliveryLogistics,
  installationMaintenanceFleet: installationMaintenanceFleet,
  medicalCareServiceFleet: medicalServiceFleet,
  close: close,
  chargingFleet: chargingFleet,
  cookie: cookie,
  customize: customize,
  sheet: sheet,
  noChoice: noChoice,
  roadmap: roadmap,
  general: general,
  fleet: fleet,
  location: location,
  operations: operations,
  setting: setting,
  infrastructure: infrastructure,
  chartMode: chartMode,
  poolVehicles: PoolVehicles,
  sales: Sales,
  longHaulTransportation: LongHaulTransportation,
  governmentMunicipal: GovernmentMunicipal,
  benefitCars: BenefitCars,
};

const Icon = ({
  name = 'others',
  ...props
}: {
  name: string;
  fill?: string;
  fontSize?: string;
  viewBox?: string;
}) => {
  const IconName = icons[name] || icons['others'];

  return <IconName {...props} />;
};
export default Icon;
