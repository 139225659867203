import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import styles from '../styles';

const ChargeAtHome = () => {
  const { t } = useTranslation('report');
  const summary = [
    {
      title: t('chargeAtHome.firstHighlightTitle'),
      labels: [
        t('chargeAtHome.firstHighlightList1'),
        t('chargeAtHome.firstHighlightList2'),
        t('chargeAtHome.firstHighlightList3'),
        t('chargeAtHome.firstHighlightList4'),
      ],
    },
    {
      title: t('chargeAtHome.secondHighlightTitle'),
      labels: [t('chargeAtHome.secondHighlightList1'), t('chargeAtHome.secondHighlightList2')],
    },
    {
      title: t('chargeAtHome.thirdHighlightTitle'),
      labels: [
        t('chargeAtHome.thirdHighlightList1'),
        t('chargeAtHome.thirdHighlightList2'),
        t('chargeAtHome.thirdHighlightList3'),
        t('chargeAtHome.thirdHighlightList4'),
      ],
    },
  ];
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{t('chargeAtHome.headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{t('chargeAtHome.headerSubtitle')}</Text>
      </View>
      <View style={styles.highlightWrapper}>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtHome.firstHighlight')}</Text>
        </View>
        <Text style={styles.plus}>+</Text>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtHome.secondHighlight')}</Text>
        </View>
        <Text style={styles.plus}>+</Text>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtHome.thirdHighlight')}</Text>
        </View>
      </View>
      <View style={styles.summaryWrapper}>
        {summary.map(({ labels, title }) => (
          <View key={title} style={styles.summaryItem}>
            <Text style={{ marginBottom: '25px', fontSize: '13px', lineHeight: '1.5px' }}>
              {title}
            </Text>
            {labels.map((val) => (
              <View key={val} style={styles.listViewHighlight}>
                <Text style={styles.listBulletHighlight}>•</Text>
                <Text style={styles.listTextHighlight}>{val}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </PageLayout>
  );
};
export default ChargeAtHome;
