import { Box } from '@mui/material';

import LayoutWithSidebar from 'components/layouts/layoutWithSidebar';
import Reports, { IBusinessCaseReportSummary } from 'components/pages/businessCase/reports';
import { useBusinessCaseSummary } from 'components/pages/businessCase/reports/components/hooks';
import BusinessCaseSidebar from 'components/pages/businessCase/sidebar';

import SecondSideBar from 'components/pages/businessCase/reports/components/secondSidebar';
import useCalculate from 'hooks/calculate.zustand';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const BusinessCaseReports = () => {
  const { data: chartSummary, refetch } = useBusinessCaseSummary();
  // const { mutateAsync: sendOfferRequest, isSuccess } = useMutation(
  //   businessCaseService.postRequestOffer,
  // );
  const { t } = useTranslation('businessCaseReports');
  const retchStatus = useCalculate((state) => state.refetch);
  useEffect(() => {
    refetch();
  }, [retchStatus]);
  // const handleRequestOffer = async () => {
  //   await sendOfferRequest(leadId);
  // };

  // useEffect(() => {
  //   if (isSuccess) {
  //     navigate(`${INITIAL_CHECK}${DOWNLOAD}?leadId=${leadId}`);
  //   }
  // }, [isSuccess]);

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LayoutWithSidebar
        sideWidth={1}
        sidebarContent={<BusinessCaseSidebar />}
        secondSidebarContent={<SecondSideBar />}
      >
        <Reports summary={chartSummary?.data as IBusinessCaseReportSummary} />
        <Box display="flex" width="100%" justifyContent="flex-end">
          {/* <Box textAlign="right">
          <Box mt={1}>
            <Button
              variant="contained"
              className={classes.offerButton}
              onClick={() => handleRequestOffer()}
              endIcon={!isLoading && <KeyboardArrowRightIcon />}
            >
              {isLoading ? <CircularProgress color="secondary" /> : t('requestOffer')}
            </Button>
          </Box>
        </Box> */}
        </Box>
      </LayoutWithSidebar>
    </>
  );
};
export default BusinessCaseReports;
