import reports from './en/report.json';
import businessCaseEN from './en/businessCaseReports.json';
import businessCaseDE from './de/businessCaseReports.json';
import commonEN from './en/common.json';
import commonDE from './de/common.json';
import registerEN from './en/register.json';
import registerDE from './de/register.json';
import errorsEN from './en/errors.json';
import errorsDE from './de/errors.json';
export const EN = {
  report: reports,
  businessCaseReports: businessCaseEN,
  common: commonEN,
  register: registerEN,
  errors: errorsEN,
};

export const DE = {
  report: reports,
  businessCaseReports: businessCaseDE,
  common: commonDE,
  register: registerDE,
  errors: errorsDE,
};
const resources = {
  en: reports,
  de: reports,
};
export default resources;
