import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>(() => ({
  noBackground: {
    position: 'absolute',
    right: 8,
    top: 8,
    '&:hover': {
      background: 'none',
    },
  },
  actions: {
    padding: 20,
    '& button': {
      flexGrow: 1,
    },
  },
}));
