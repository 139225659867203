import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { default as MaterialSvgIcon } from '@mui/material/SvgIcon';

export interface IProps {
  children?: React.ReactNode;
  viewBox?: string;
  className?: string;
  style?: any;
}

const SvgIcon = ({ children, viewBox, className, ...props }: IProps) => {
  const classes = useStyles();

  return (
    <MaterialSvgIcon
      className={[classes.root, className].join(' ')}
      viewBox={viewBox || '0 0 32 32'}
      {...props}
    >
      {children}
    </MaterialSvgIcon>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    color: 'inherit',
  },
}));

export default SvgIcon;
