import { Tooltip } from '@mui/material';
import InfoIcon from 'components/icons/info';

import { useStyle } from './style';

export interface IProps {
  title: string;
  iconSize?: number;
}

const InfoTooltip = ({ title, iconSize = 20, ...props }: IProps) => {
  const classes = useStyle();
  return (
    <div className={classes.tooltipWrapper} {...props}>
      <Tooltip arrow className={classes.root} title={title}>
        <InfoIcon style={{ fontSize: iconSize }} />
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
