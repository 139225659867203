import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles<Theme>(() => ({
  openConsent: {
    width: 50,
    height: 50,
    minWidth: 50,
    padding: 0,
    position: 'fixed',
    bottom: 0,
    '& svg': {
      fill: 'white',
    },
  },
  dialogWidth: {
    maxWidth: 750,
  },
  consentText: {
    lineHeight: 1.8,
  },
}));
