import { createTheme, adaptV4Theme } from '@mui/material/styles';

type TPosition =
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'fixed'
  | '-webkit-sticky'
  | 'absolute'
  | 'relative'
  | 'static'
  | 'sticky'
  | undefined;

const palette: any = {
  palette: {
    text: {
      primary: '#282C42',
      secondary: '#5F6380', // Electric Blue/100
    },
    hint: {
      main: '#A1A7BB', // Cadet Blue
    },
    info: {
      main: '#FDF6E7', // Note
      dark: '#43455A', // Electric Blue/130
      light: '#BFC1CC', // Electric Blue/40
    },
    action: {
      hover: '#4DC3AE', // Aquamarine/130
    },
    background: {
      default: '#131630', // Gunmetal/Dark
    },
    primary: {
      light: '#2ECBE0',
      main: '#60F4D9', // Aquamarine/100
      dark: '#32f6a1',
    },
    success: {
      main: '#0bb537',
      light: '#daece6',
    },
    warning: {
      main: '#f0932b',
    },
    secondary: {
      light: '#F8F7F6',
      main: '#F6F7F8',
      dark: '#5F6380',
    },
    divider: '#bcc2c9',
    chart: {
      infra: {
        from: '#D33EE6',
        to: '#6F2EE0',
      },
      energy: '#f0932b',
      backendCosts: '#D33EE6',
      energyFuel: '#F0932B',
      investmentCosts: '#84F473',
      oldCost: '#60F4D9',
      maintenance: '#0941E5',
      taxesInsurance: '#6F2EE0',
      vehicles: '#5F6380',
      fleet: '#BFFBF0',
      wallboxes: '#4DC3AE',
    },
  },
};

export const theme = createTheme(
  adaptV4Theme({
    ...palette,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            height: '100%',
          },
          a: {
            textDecoration: 'none',
          },
          body: {
            height: '100%',
            margin: 0,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            backgroundColor: '#fff',
          },
          '#root': {
            position: 'relative' as TPosition,
            display: 'flex',
            flexDirection: 'column' as 'column',
            height: '100vh',
          },
          '.MuiSnackbar-anchorOriginBottomCenter': {
            '& .MuiBox-root': {
              backgroundColor: '#fff',
            },
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          padding: 20,
          backgroundColor: palette?.primary?.main,
        },
      },
      MuiButton: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette?.action?.hover,
          },
          '&:active': {
            backgroundColor: palette?.action?.hover,
          },
          '&:disabled': {
            backgroundColor: palette?.hint?.main,
          },
        },
      },
      MuiStepConnector: {
        alternativeLabel: {
          top: 32,
        },
        active: {
          '& $line': {
            backgroundColor: palette?.primary?.main,
          },
        },
        completed: {
          '& $line': {
            backgroundColor: palette?.primary?.main,
          },
        },
        line: {
          height: 4,
          border: 0,
          backgroundColor: '',
          borderRadius: 1,
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: '10px 14px',
        },
      },
    },
    breakpoints: {
      values: {
        xl: 1920,
        lg: 1420,
        md: 960,
        sm: 600,
        xs: 0,
      },
    },

    shape: {
      borderRadius: 6,
    },
    typography: {
      /* configure typography from design */
      fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      body1: {
        fontSize: '18px',
        fontWeight: 400,
      },
      body2: {
        fontSize: '14px',
        fontWeight: 'normal',
      },
      h1: {
        fontFamily: ['Oxanium', 'Helvetica', 'Arial'].join(','),
        fontSize: '60px',
        fontWeight: 600,
        lineHeight: '78px',
      },
      h2: {
        fontFamily: ['Oxanium', 'Helvetica', 'Arial'].join(','),
        fontSize: '42px',
        fontWeight: 600,
        lineHeight: '55px',
      },
      h3: {
        fontFamily: ['Oxanium', 'Helvetica', 'Arial'].join(','),
        fontSize: '36px',
        fontWeight: 600,
        lineHeight: '47px',
      },
      h4: {
        fontFamily: ['Oxanium', 'Helvetica', 'Arial'].join(','),
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '31px',
      },
    },
  }),
);

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    hint: {
      main: string;
    };
    chart: {
      infra: {
        from: string;
        to: string;
      };
      investmentCosts: string;
      oldCost: string;
      energyFuel: string;
      maintenance: string;
      energy: string;
      backendCosts: string;
      taxesInsurance: string;
      vehicles: string;
      fleet: string;
      wallboxes: string;
    };
  }
  // eslint-disable-next-line no-unused-vars
  interface PaletteOptions {
    hint: {
      main: string;
    };
    chart: {
      infra: {
        from: string;
        to: string;
      };
      investmentCosts: string;
      oldCost: string;
      energyFuel: string;
      maintenance: string;
      energy: string;
      backendCosts: string;
      taxesInsurance: string;
      vehicles: string;
      fleet: string;
      wallboxes: string;
    };
  }
}
