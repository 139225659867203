import { ReactNode } from 'react';
import { Image, Page, Text, View } from '@react-pdf/renderer';

import styles from './styles';

interface IPage {
  children: ReactNode;
  caption?: string;
}

const PageLayout = ({ children, caption }: IPage) => {
  return (
    <Page orientation="landscape">
      <View style={styles.wrapper}>{children}</View>
      <View style={styles.footerLogo}>
        <Image src="/img/inno2fleet-logo-pdf.png" style={styles.logo} />
      </View>
      {caption && (
        <View style={styles.caption}>
          <Text>{caption}</Text>
        </View>
      )}
    </Page>
  );
};
export default PageLayout;
