import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import businessCaseService from 'services/businessCase.service';

import Chart from './chart';
import useCalculate from 'hooks/calculate.zustand';

const InfraChart = () => {
  const calcData = useCalculate((state) => state.data);
  const needRefetch = useCalculate((state) => state.refetch);
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const {
    data: vehiclesData,
    isLoading: getVehicles,
    refetch,
    remove,
  } = useQuery(['InfraVehicleData'], () => businessCaseService.getRoadmapInfrastructure(calcData), {
    enabled: false,
    onSuccess: () => makeRefetch(false),
  });
  useEffect(() => {
    !vehiclesData && refetch();
    return () => remove();
  }, [remove]);

  useEffect(() => {
    if (needRefetch) {
      refetch(calcData as unknown as any);
    }
  }, [needRefetch]);

  return <Chart data={vehiclesData?.data} isLoading={getVehicles} />;
};
export default InfraChart;
