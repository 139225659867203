import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button } from '@mui/material';

import { BootstrapTooltip } from 'components/bootstrapTooltip';
import Icons from 'components/icons';
import SidebarFooter from 'components/pages/initialCheck/sidebar/components/sidebarFooter';

import { useStyles } from './styles';

const BusinessCaseSidebar = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation('businessCaseReports');
  console.log('t', t(''));
  const buttons = [
    { title: t('sidebar.general'), icon: 'general' },
    { title: t('sidebar.fleet'), icon: 'fleet' },
  ];
  return (
    <Box my={2} height="100%">
      {ready && (
        <Grid
          className={classes.wrapper}
          container
          spacing={2}
          direction="row"
          justifyContent="center"
        >
          <Grid item>
            <img src="/img/business-case/businesscase-side.png" alt="business-case-logo" />
            <Box textAlign="center">
              <img src="/img/beta.png" alt="beta" width="40px" height="25px" />
            </Box>
          </Grid>
          <Grid item className={classes.item}>
            {buttons.map(({ title, icon }) => (
              <BootstrapTooltip key={title} title={t('comingSoon') as string}>
                <Button className={classes.button}>
                  <Icons name={icon} fill="#fff" fontSize="30px" viewBox="0 0 24 24" />
                  <Typography className={classes.menuText}>{title}</Typography>
                </Button>
              </BootstrapTooltip>
            ))}
          </Grid>
          <Grid item>
            <BootstrapTooltip title={t('comingSoon') as string}>
              <Button className={classes.button}>
                <Icons name="setting" fill="#fff" fontSize="30px" viewBox="0 0 24 24" />
                <Typography className={classes.menuText}>
                  {t('sidebar.personalSettings')}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </Grid>
          <Grid item className={classes.footer}>
            <SidebarFooter disclaimer={false} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default BusinessCaseSidebar;
