import { Text, View } from '@react-pdf/renderer';

import PageLayout from '../pageLayout/pageLayout';

import styles from './style';

interface IProps {
  title: string;
  chapter?: number;
}
const FirstPage = ({ title, chapter }: IProps) => {
  return (
    <PageLayout>
      <View style={styles.titleView}>
        {chapter && <Text style={styles.chapterNumber}>{chapter}</Text>}
        <Text style={styles.chapterTitle}>{title}</Text>
      </View>
      <View style={styles.separator} />
    </PageLayout>
  );
};
export default FirstPage;
