import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: '50%',
    padding: 10,
    margin: '0 10px 5px 0',
    width: 30,
    height: 30,
  },
  packageIcon: {
    width: 9,
    opacity: 1,
  },
  normal: {
    backgroundColor: '#90F7E4',
  },
  light: {
    backgroundColor: '#BFFBF0',
    opacity: 0.2,
  },
});

export default styles;
