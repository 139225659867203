import { StyleSheet } from '@react-pdf/renderer';

const useStyles = StyleSheet.create({
  titleView: {
    margin: '150px 0 0 50px',
  },
  chapterTitle: {
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
    fontSize: 50,
  },
  chapterNumber: {
    fontSize: 16,
    color: '#60F4D9',
    backgroundColor: '#282C42',
    width: 20,
    height: 30,
    border: `3px solid #282C42`,
    borderRadius: 6,
    padding: '7px 13px 0 10px',
    marginBottom: '15px',
    fontFamily: 'Oxanium',
  },
  separator: {
    backgroundColor: '#60F4D9',
    height: 5,
    width: '40%',
    margin: '30px 50px 0',
    borderRadius: 4,
  },
});

export default useStyles;
