import React from 'react';
import ContentLoader from 'react-content-loader';

const BarChartSkeleton = (props: any) => {
  return (
    <div>
      <ContentLoader width="100%" height="40vh" viewBox="0 0 250 250" {...props}>
        <rect x="20" y="160" rx="0" ry="0" width="25" height="40" />
        <rect x="50" y="145" rx="0" ry="0" width="25" height="55" />
        <rect x="80" y="126" rx="0" ry="0" width="25" height="74" />
        <rect x="110" y="80" rx="0" ry="0" width="25" height="120" />
        <rect x="140" y="142" rx="0" ry="0" width="25" height="58" />
        <rect x="0" y="90" rx="0" ry="0" width="8" height="125" />
        <rect x="0" y="210" rx="0" ry="0" width="180" height="8" />
      </ContentLoader>
    </div>
  );
};

export default BarChartSkeleton;
