import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import businessCaseService from 'services/businessCase.service';

import Chart from './chart';
import useCalculate from 'hooks/calculate.zustand';

const Co2Emission = () => {
  const calcData = useCalculate((state) => state.data);
  const needRefetch = useCalculate((state) => state.refetch);
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const {
    data: co2Emission,
    isLoading,
    refetch,
    remove,
  } = useQuery(['co2Emission'], () => businessCaseService.getCo2Emissions(calcData), {
    enabled: false,
    onSuccess: () => makeRefetch(false),
  });
  useEffect(() => {
    if (needRefetch) {
      refetch(calcData as unknown as any);
    }
  }, [needRefetch]);
  useEffect(() => {
    !co2Emission && refetch();

    return () => remove();
  }, [remove]);
  return <Chart data={co2Emission?.data} isLoading={isLoading} />;
};
export default Co2Emission;
