import { useEffect } from 'react';

interface IProps {
  loading: boolean;
}
const PreventClosing = ({ loading }: IProps) => {
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();

      return (event.returnValue = 'Are you sure you want to exit?');
    };
    if (loading) {
      window.addEventListener('beforeunload', handleTabClose);
    }

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [loading]);
  return <div />;
};
export default PreventClosing;
