import { QueryClient } from '@tanstack/react-query';

import packageInfo from '../package.json';

interface IConfigEnv {
  [key: string]: any;
}

interface IConfig {
  local: IConfigEnv;
  test: IConfigEnv;
  development: IConfigEnv;
  staging: IConfigEnv;
  production: IConfigEnv;
  cacheTime: number | string | undefined;
}

const configOptions: IConfig = {
  local: {
    FLEET_MANAGER_SERVICE_URL: 'http://localhost:3015/api/v1',
    SENTRY_DSN_URL: 'https://43719621bdf0461da9c2fd1864620757@o963834.ingest.sentry.io/6509551',
    SENTRY_ENVIRONMENT: 'local',
  },
  test: {
    FLEET_MANAGER_SERVICE_URL: `https://i2g-digital-consultant-be.development.${process.env.REACT_APP_DOMAIN_URL}/api/v1`,
  },
  development: {
    FLEET_MANAGER_SERVICE_URL: `https://i2g-digital-consultant-be.development.${process.env.REACT_APP_DOMAIN_URL}/api/v2`,
    FLEET_MANAGER_AUTH_SERVICE_URL: `https://i2g-digital-consultant-be.development.${process.env.REACT_APP_DOMAIN_URL}/api/v1`,
    SENTRY_DSN_URL: 'https://43719621bdf0461da9c2fd1864620757@o963834.ingest.sentry.io/6509551',
    SENTRY_ENVIRONMENT: 'development',
  },

  staging: {
    FLEET_MANAGER_SERVICE_URL: `https://i2g-digital-consultant-be.staging.${process.env.REACT_APP_DOMAIN_URL}/api/v2`,
    FLEET_MANAGER_AUTH_SERVICE_URL: `https://i2g-digital-consultant-be.development.${process.env.REACT_APP_DOMAIN_URL}/api/v1`,
    SENTRY_DSN_URL: 'https://43719621bdf0461da9c2fd1864620757@o963834.ingest.sentry.io/6509551',
    SENTRY_ENVIRONMENT: 'staging',
  },
  production: {
    FLEET_MANAGER_SERVICE_URL: `https://econsultant-api.inno2fleet.com/api/v2`,
    SENTRY_DSN_URL: 'https://43719621bdf0461da9c2fd1864620757@o963834.ingest.sentry.io/6509551',
    FLEET_MANAGER_AUTH_SERVICE_URL: `https://i2g-digital-consultant-be.development.${process.env.REACT_APP_DOMAIN_URL}/api/v1`,
    SENTRY_ENVIRONMENT: 'production',
  },
  cacheTime: process.env.REACT_APP_CACHE_TIME || 1000 * 6,
};
// REACT_QUERY Config
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const env = process.env.REACT_APP_CUSTOM_NODE_ENV || 'development';
// @ts-ignore
const config: IConfigEnv = { ...configOptions[env], VERSION: packageInfo.version };

export default config;
