import { StyleSheet } from '@react-pdf/renderer';

const useStyles = StyleSheet.create({
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    zIndex: -1,
  },
  logo: {
    width: 98,
  },
  wrapper: {
    fontFamily: 'Inter',
    position: 'relative',
    margin: '30px 70px',
  },
  caption: {
    position: 'absolute',
    bottom: 5,
    textAlign: 'center',
    width: '100%',
    color: '#5B667C',
    fontFamily: 'Inter',
    fontSize: 6,
  },
});

export default useStyles;
