import { Box, Grid, Skeleton, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRecommendation from './hooks/useRecommendation';
import { SideWrapper, CToggleButtonGroup } from './style';
import FooterComponent from './components/footer';

const SecondSideBar = () => {
  const { ready } = useTranslation('businessCaseReports');
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('businessCaseReports');
  const { data, isLoading } = useRecommendation();

  return (
    <SideWrapper>
      {ready && (
        <>
          <Box
            py={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {isLoading ? (
              <Box sx={{ width: 300 }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : (
              <Box>
                <Typography variant="h5">
                  {t('recommendations.header')}
                  <span className="lessAttention">
                    {`${tCommon('formattedPrice', {
                      value: data?.data?.totalInvestment,
                    })} €`}
                  </span>
                </Typography>
                <Box mt={2} className="investment">
                  <Typography variant="body1">{t('recommendations.subtitle')}</Typography>
                  <ul>
                    <li>
                      {t('recommendations.firstList')}
                      <span>{`${tCommon('formattedPrice', {
                        value: data?.data.installationCosts,
                      })} €`}</span>
                    </li>
                    <li>
                      {t('recommendations.secondList')}
                      <span>{`${tCommon('formattedPrice', {
                        value: data?.data.infrastructureCosts,
                      })} €`}</span>
                    </li>
                    <li>
                      {t('recommendations.thirdList')}
                      <span>{`${tCommon('formattedPrice', {
                        value: data?.data.operationalCosts,
                      })} €`}</span>
                    </li>
                  </ul>
                  <CToggleButtonGroup color="primary" exclusive aria-label="type">
                    <ToggleButton value={1}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <img src="/img/house-car.png" width="32px" />
                        </Grid>
                        <Grid item>
                          <Box>
                            <Typography className="title">
                              {t('recommendations.cpAtHome')}
                            </Typography>
                          </Box>
                          <Typography className="amount">
                            {data?.data.chargingPointsAtHome}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ToggleButton>
                    <ToggleButton value={2}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <img src="/img/charging-station.png" width="32px" />
                        </Grid>
                        <Grid item>
                          <Box>
                            <Typography className="title">
                              {t('recommendations.cpAtWork')}
                            </Typography>
                          </Box>
                          <Typography className="amount">
                            {data?.data.chargingPointsAtWork}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ToggleButton>
                    <ToggleButton value={3}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <img src="/img/rfid.png" width="32px" />
                        </Grid>
                        <Grid item>
                          <Box>
                            <Typography className="title">
                              {t('recommendations.cpAtPublic')}
                            </Typography>
                          </Box>
                          <Typography className="amount">
                            {data?.data.chargingPointsAtPublic}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ToggleButton>
                  </CToggleButtonGroup>
                </Box>
              </Box>
            )}
            <FooterComponent />
          </Box>
        </>
      )}
    </SideWrapper>
  );
};
export default SecondSideBar;
