import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Button,
} from '@mui/material';

import CloseIcon from 'components/icons/close';

import { useStyles } from './style';
import { DefaultTFuncReturn } from 'i18next';

export interface IProps {
  open: boolean;
  title?: DefaultTFuncReturn;
  children: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  actions?: IAction[];
  classes?: DialogProps['classes'];
  scroll?: DialogProps['scroll'];
  onClose?: () => void;
  disableBackdropClick?: boolean;
  fullScreen?: boolean;
}

export interface IAction {
  label: string;
  onClick?: () => void;
  color?: 'inherit' | 'primary' | 'secondary' | undefined;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
}

const Dialog = ({
  open,
  maxWidth,
  title,
  children,
  actions,
  classes,
  scroll,
  fullScreen = false,
  onClose,
  disableBackdropClick,
}: IProps) => {
  const classesStyle = useStyles();
  return (
    <MuiDialog
      fullScreen={fullScreen}
      open={open}
      disableEscapeKeyDown={disableBackdropClick}
      maxWidth={maxWidth}
      classes={classes}
      scroll={scroll}
      fullWidth={true}
      onClose={() => (onClose ? onClose() : {})}
    >
      <DialogTitle>
        {title}
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classesStyle.noBackground}
            size="large"
          >
            <CloseIcon viewBox="0 0 1000 1000" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classesStyle.actions}>
        {actions?.map((action) => (
          <Button
            variant={action.variant ? action.variant : 'contained'}
            color={action.color ? action.color : 'primary'}
            key={action.label}
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
