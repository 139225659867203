import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, Grid, MenuItem, Select, Typography } from '@mui/material';

// import useProfile from 'hooks/profile.hook';

import Co2Emissions from './components/charts/co2Emissions';
import ComparisonEVICEBar from './components/charts/comparisonEV&ICE/components/barChart';
import ComparisonEVICELine from './components/charts/comparisonEV&ICE/components/lineChart';
import RoadmapInfrastructure from './components/charts/roadmap/components/infraChart';
// import RoadmapVehicles from './components/charts/roadmap/components/vehiclesChart';
import ReportsHeader from './components/header/header';
import { useStyles } from './styles';
import EditDrawer from '../editDrawer';
import useRecommendation from './components/secondSidebar/hooks/useRecommendation';
import useCalculate from 'hooks/calculate.zustand';

export interface IBusinessCaseReportSummary {
  costComparison: any;
  co2EmissionsSaved: number;
  savingPotential: number;
  totalEnergy: number;
}
interface IProps {
  summary: IBusinessCaseReportSummary;
}

const Reports = ({ summary }: IProps) => {
  // const { profile } = useProfile();
  const classes = useStyles();
  const { t } = useTranslation('businessCaseReports');
  const { i18n } = useTranslation();
  const defaultLang = localStorage.getItem('i18nextLng')?.split('-')?.[0];
  const { data: recData, isLoading: recIsLoading, refetch } = useRecommendation();
  const needRefetch = useCalculate((state) => state.refetch);
  const setCalcData = useCalculate((state) => state.setData);

  useEffect(() => {
    refetch();
  }, [needRefetch]);

  useEffect(() => {
    if (recData) {
      setCalcData(recData.data.inputValues);
    }
  }, [recData]);
  const [lang, setLang] = React.useState(defaultLang);
  const handleLang = (event: any) => {
    i18n.changeLanguage(event.target.value as string);
    setLang(event.target.value as string);
  };
  const [activeChart, setActiveChart] = useState<number>(2);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const MapActiveChart = () => {
    switch (activeChart) {
      case 1:
        return <div>no match</div>;
      case 2:
        return <ComparisonEVICEBar currency={'€'} />;
      case 3:
        return <ComparisonEVICELine currency={'€'} />;
      // case 7:
      //   return <RoadmapVehicles />;
      case 8:
        return <RoadmapInfrastructure />;
      case 9:
        return <Co2Emissions />;
      // default:
      //   return <div>no match</div>;
    }
  };
  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.header} variant="h1">
            {t('yourFleetElectrification')}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Button color="inherit" variant="outlined" onClick={() => setOpenEditor(true)}>
                {t('headerEditBtn')}
              </Button>
            </Grid>
            <Grid item>
              <div className={classes.container}>
                <Grid item xs={1} className={classes.selectWrapper}>
                  <Select
                    labelId="language"
                    id="language"
                    variant={'outlined'}
                    value={lang}
                    onChange={handleLang}
                  >
                    <MenuItem value="de">DE</MenuItem>
                    <MenuItem value="en">EN</MenuItem>
                  </Select>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <ReportsHeader
          summary={summary}
          activeChart={activeChart}
          setActiveChart={setActiveChart}
        />
      </Box>
      <Box
        height="100%"
        maxHeight="53vh"
        width="100%"
        pt={5}
        pb={1}
        position="relative"
        sx={{ overflow: 'hidden' }}
      >
        {MapActiveChart()}
      </Box>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={openEditor}
        onClose={() => setOpenEditor(false)}
      >
        {!recIsLoading && recData && (
          <EditDrawer onClose={() => setOpenEditor(false)} recommendation={recData.data} />
        )}
      </Drawer>
    </>
  );
};
export default Reports;
