import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page2: {
    display: 'flex',
    flexDirection: 'row',
  },
  column1: {
    flex: 3,
    padding: '120px 150px 70px 70px',
  },
  column1title: {
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
    fontSize: 25,
    marginBottom: 25,
  },
  paragraph: {
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 1.5,
    color: '#A1A7BC',
    marginBottom: 20,
  },
  column2: {
    flex: 2,
    backgroundColor: '#282C42',
    padding: '180px 0px 0px 30px',
  },
  column2title: {
    fontSize: 8,
    marginBottom: 20,
    color: '#60F4D9',
  },
  listitems: {
    position: 'relative',
  },
  listitemsContent: {
    position: 'absolute',
  },
  item: {
    left: -43,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },
  number: {
    fontSize: 16,
    color: '#60F4D9',
    backgroundColor: '#282C42',
    width: 20,
    height: 30,
    border: '3px solid white',
    borderRadius: 6,
    padding: '7px 13px 0 9px',
  },
  title: {
    fontSize: 16,
    paddingLeft: 15,
    color: '#F8F7F6',
  },
});

export default styles;
