import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';

import BarChartSkeleton from 'components/pages/businessCase/reports/components/skeleton';
import { useStyles } from 'components/pages/businessCase/reports/styles';
import { mapChartData } from 'components/pages/initialCheck/sidebarWithChart';

interface IProps {
  data: any;
  isLoading: boolean;
  isDone?: () => void;
}
const InfraChart = ({ isLoading, data, isDone }: IProps) => {
  const classes = useStyles();
  const d = new Date();
  let firstYear = d.getFullYear();
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('businessCaseReports');

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={17} y={0} dy={16} textAnchor="end" fill="#666">
          {firstYear + +payload.value}
        </text>
      </g>
    );
  };
  const renderTooltipText = (value: number, name: string) => {
    return [`${tCommon('formattedPrice', { value })}`, t(`roadmaps.${name}`)];
  };
  const labelFormatter = (value: string) => {
    const year = new Date();

    return `${tCommon('month')} ${+value * 12}/ ${year.getFullYear() + +value} ${tCommon('year')}`;
  };

  const renderLegendText = (value: string) => (
    <span style={{ color: theme.palette.common.black, margin: '30px 6px 0px 6px' }}>
      {t(`roadmaps.${value}`)}
    </span>
  );
  return (
    <ResponsiveContainer width="100%" height="100%">
      {isLoading ? (
        <BarChartSkeleton />
      ) : (
        <BarChart
          className={classes.wrapper}
          width={500}
          height={300}
          data={mapChartData(data)}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickLine={false} dataKey="name" tick={<CustomizedAxisTick />} />
          <YAxis tickLine={false} width={100} />
          {/* @ts-ignore */}
          <Tooltip cursor={false} formatter={renderTooltipText} labelFormatter={labelFormatter} />
          <Legend
            align="left"
            wrapperStyle={{
              paddingLeft: '5%',
              paddingTop: '20px',
            }}
            iconType="rect"
            formatter={renderLegendText}
          />
          <Bar
            radius={[10, 10, 10, 10]}
            dataKey="chargingStations"
            fill={theme.palette.chart.fleet}
            animationDuration={1}
            onAnimationEnd={() => isDone?.()}
          />
          <Bar
            animationDuration={1}
            onAnimationEnd={() => isDone?.()}
            radius={[10, 10, 10, 10]}
            dataKey="wallboxes"
            fill={theme.palette.chart.wallboxes}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
export default InfraChart;
