/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { IProfile } from '../../../../hooks/profile.hook';
import SidebarFooter from '../sidebar/components/sidebarFooter';

import Co2Chart from './co2Chart';
import Header from './header';
import SavingPotentialChart from './savingPotentialChart';
import { useStyles } from './style';

export interface ITeaserOverview {
  savingPotential: number;
  co2EmissionPCF: number;
}
export interface IChartData {
  capexInfra: number;
  capexVehicles: number;
  opexEnergy: number;
  opexMaintenance: number;
  opexLeasing: number;
  opexTaxInsurance: number;
  opexUsageBackend: number;
  opexOldIce: number;
  name?: string;
  year?: string;
}
export interface ICo2ChartData {
  co2EmissionEvEnergyUsed: number;
  co2EmissionIceFuelUsed: number;
  co2EmissionInfrastracture: number;
  co2EmissionVehicleProduction: number;
  name?: string;
  year?: string;
}
export interface IChartCo2 {
  ev: ICo2ChartData;
  ice: ICo2ChartData;
}
export interface IChartSavingPotential {
  ev: IChartData;
  ice: IChartData;
}
export type TSavingPotential = keyof IChartSavingPotential;
export type TCo2 = keyof IChartCo2;
export interface IProps {
  teaserOverview: {
    overview: ITeaserOverview;
    tcoChart: IChartSavingPotential;
    co2Emission: IChartCo2;
  };
  isLoading: boolean;
  chartIsLoading: boolean;
  profile: IProfile;
}
export enum EChart {
  savingPotential = 'saving-potential',
  Co2 = 'co2',
}
export const mapChartData = (data: IChartSavingPotential | IChartCo2) => {
  let arr: any[] = [];
  let MappedKey: IChartData | ICo2ChartData = {} as IChartData | ICo2ChartData;
  data &&
    Object.keys(data).map((key) => {
      MappedKey = data[key as TSavingPotential | TCo2];
      Object.keys(MappedKey).map(() => {
        MappedKey.name = key;
      });
      arr.push(MappedKey);
    });
  return arr;
};
export const mapChartNestedData = (data: IChartSavingPotential | IChartCo2) => {
  let arr: any[] = [];
  let MappedKey: IChartData | ICo2ChartData = {} as IChartData | ICo2ChartData;
  data &&
    Object.keys(data).map((key) => {
      MappedKey = data[key as TSavingPotential | TCo2];
      Object.keys(MappedKey).map((innerKey) => {
        // @ts-ignore
        const innerObj = MappedKey[innerKey];
        if (typeof innerObj === 'object') {
          Object.keys(innerObj).map(() => {
            innerObj.name = innerKey;
            if (MappedKey?.year) innerObj.year = MappedKey.year;
          });
          arr.push(innerObj);
        }
      });
    });
  return arr;
};
const SidebarWithChart = ({ teaserOverview, isLoading, chartIsLoading, profile }: IProps) => {
  const classes = useStyles();
  const [headerBtn, setHeaderBtn] = useState<EChart>(EChart.savingPotential);
  const currency = profile?.country.currencySign;
  return (
    <div className={classes.main}>
      <Header
        teaserOverview={teaserOverview?.overview}
        isLoading={isLoading}
        selected={headerBtn}
        setSelected={setHeaderBtn}
      />
      <Box height="100%" width="100%" pt={5} pb={1}>
        {chartIsLoading ? (
          <Box textAlign="center">
            <CircularProgress size={30} />
          </Box>
        ) : (
          <>
            {headerBtn === EChart.savingPotential ? (
              <SavingPotentialChart
                teaserSavingPotential={teaserOverview?.tcoChart}
                currency={currency}
              />
            ) : (
              <Co2Chart teaserCo2={teaserOverview?.co2Emission} />
            )}
          </>
        )}
      </Box>
      <SidebarFooter disclaimer={true} />
    </div>
  );
};
export default SidebarWithChart;
