import React, { useEffect } from 'react';

function useDetectView() {
  const [isMobile, setMobile] = React.useState(false);
  const handleWindowSize = () => {
    const width = window.innerWidth;

    if (width <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSize);
    handleWindowSize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowSize);
  }, []);
  return { isMobile };
}
export { useDetectView };
