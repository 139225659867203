import { forwardRef, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Grid, TextField, Typography, Button, Checkbox, CircularProgress } from '@mui/material';
import cx from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import useCalculate from 'hooks/calculate.zustand';

import { DATA_PRIVACY } from 'constants/routes';

import { useStyles } from './style';
// import { DefaultTFuncReturn } from 'i18next';

// interface IProps {
//   onSubmit: (value: any) => void;
//   model?: IRegister;
//   isLoading: boolean;
//   submitLabel?: DefaultTFuncReturn;
// }
export interface IRegister {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  phone: string;
  message: string;
}
const CustomTextField = (props: any) => <TextField fullWidth variant="outlined" {...props} />;
interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (value: any) => void;
  isLoading: boolean;
}
const Register = forwardRef((props: IProps) => {
  const { onSubmit: mutate, isLoading } = props;
  const { t } = useTranslation('register');
  const { t: tError } = useTranslation('errors');
  const calcData = useCalculate((state) => state.data);
  const classes = useStyles();
  const methods = useForm<FieldValues>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });
  const onSubmit = (values: any) => {
    mutate({ ...values, inputData: { ...calcData } });
  };
  const [privacy, setPrivacy] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={7} className={classes.firstInput}>
            <Typography variant="body2">{t('firstName')}*</Typography>
            <TextField
              fullWidth
              placeholder="Max"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message as string}
              variant="outlined"
              {...register('firstName', {
                required: `${tError('required')}`,
              })}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{t('lastName')}*</Typography>
            <TextField
              fullWidth
              placeholder="Musterfrau"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message as string}
              variant="outlined"
              {...register('lastName', {
                required: `${tError('required')}`,
              })}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{t('organization')}*</Typography>
            <TextField
              fullWidth
              placeholder="Musterfirma"
              error={!!errors.organization}
              helperText={errors?.organization?.message as string}
              variant="outlined"
              {...register('organization', {
                required: `${tError('required')}`,
              })}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{t('email')}*</Typography>
            <TextField
              fullWidth
              placeholder="max@musterfrau.com"
              error={!!errors.email}
              helperText={errors?.email?.message as string}
              variant="outlined"
              {...register('email', {
                required: `${tError('required')}`,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: `${tError('email')}`,
                },
              })}
            />
          </Grid>
          <Grid item sm={7} className={classes.number}>
            <Typography variant="body2">{t('telephone')}</Typography>

            <Controller
              control={control}
              key="phone"
              name="phone"
              render={({ field: { value, onChange } }) => (
                <>
                  <NumberFormat
                    customInput={CustomTextField}
                    allowNegative={false}
                    key="phone"
                    format="+## ### ##########"
                    value={value}
                    placeholder="+49 123 4567890000"
                    decimalScale={2}
                    className={cx({
                      numberFormat_error: !!errors?.phone,
                    })}
                    onValueChange={(v) => onChange(v.formattedValue)}
                  />
                  <span className={classes.errorLabel}>{errors?.phone?.message as string}</span>
                </>
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{t('message')}</Typography>
            <TextField
              fullWidth
              multiline
              error={!!errors.message}
              helperText={errors?.message?.message as string}
              variant="outlined"
              {...register('message')}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{t('terms')}</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              control={<Checkbox value={privacy} onChange={() => setPrivacy(!privacy)} />}
              label={
                <Trans i18nKey={'register:privacy'}>
                  <Typography variant="body2">main</Typography>
                  <a target="_blank" href={DATA_PRIVACY} rel="noreferrer">
                    link
                  </a>
                </Trans>
              }
            />
          </Grid>
          <Grid item xs={7} sx={{ textAlign: 'right' }}>
            <Button disabled={!privacy} type="submit" variant="contained">
              {isLoading ? <CircularProgress color="secondary" size={20} /> : t('submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
});
Register.displayName = 'Register';
export default Register;
