import { useTranslation } from 'react-i18next';

import FirstPage from '../../layout/firstPage/firstPage';

import AdditionalAnalysis from './components/additionalAnalysis';
import ChargeAtHome from './components/chargeAtHome';
import ChargeAtPublic from './components/chargeAtPublic';
import ChargeAtWork from './components/chargeAtWork';
import FiveSteps from './components/fiveSteps';
import SuccessfulFleet from './components/successfulFleet';

const OurSolution = () => {
  const { t } = useTranslation('report');
  return (
    <>
      <FirstPage title={t('ourSolutionTitle')} chapter={3} />
      {/*    PAGE 2  */}
      <FiveSteps />
      {/*    PAGE 3   */}
      <ChargeAtWork />
      {/*    PAGE 4   */}
      <ChargeAtHome />
      {/*    PAGE 5   */}
      <ChargeAtPublic />
      {/*    PAGE 6  */}
      <AdditionalAnalysis />
      {/*    PAGE 7    */}
      <SuccessfulFleet />
    </>
  );
};
export default OurSolution;
