import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import config from 'config';

import BusinessCaseReport from './report';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: config.cacheTime,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const Report = () => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <BusinessCaseReport />
      <ReactQueryDevtools initialIsOpen />
    </PersistQueryClientProvider>
  );
};
export default Report;
