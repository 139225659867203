import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import FirstPage from '../../layout/firstPage/firstPage';
import PageLayout from '../../layout/pageLayout/pageLayout';
import { IPdfReports, ISection } from '../../types';

import styles from './styles';

interface IChapter {
  pdfReport?: IPdfReports;
  roadmapInfrastructureUrl?: string;
}

interface IChapterTranslation {
  firstPage: ISection;
  summary: ISummaryPage;
}

interface ISummaryPage {
  header: ISection;
  chargeAtWork: string;
  chargeAtHome: string;
  chargeAtPublic: string;
  requiredCP: string;
  requiredCPT: string;
  description: ISection;
  savingPotential: string;
  investmentCost: string;
  operationalCost: string;
  pageCaption: string;
  savingPotentialEmission: string;
}

const ExecutiveSummary: FC<IChapter> = ({ pdfReport, roadmapInfrastructureUrl }: IChapter) => {
  const { t } = useTranslation('report');
  const currency = pdfReport?.country?.CurrencySign || '€';
  const { t: tCommon } = useTranslation('common');

  const trans = t('executiveSummary', {
    returnObjects: true,
    maxOperationCostsStartMonth: pdfReport?.maxOperationCostsStartMonth,
  }) as IChapterTranslation;

  const getImagePath = (name: string) => {
    return `/img/business-case/reports/assumptions/${name}.png`;
  };

  return (
    <>
      {/* PAGE 1 */}
      <FirstPage chapter={1} title={trans.firstPage.title} />
      {/* PAGE 2 */}
      <PageLayout>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{trans.summary.header.title}</Text>
          <Text style={styles.headerSubtitle}>{trans.summary.header.subtitle}</Text>
        </View>
        <View style={styles.chargeWrapper}>
          <View style={styles.chargeItem}>
            <Image src={getImagePath('chargeWorkColored')} style={styles.fleetIcon} />
            <Text style={styles.chargeBoxTitle}>{trans.summary.chargeAtWork}</Text>
          </View>
          <View style={styles.chargeItem}>
            <Image src={getImagePath('chargeHomeColored')} style={styles.fleetIcon} />
            <Text style={styles.chargeBoxTitle}>{trans.summary.chargeAtHome}</Text>
          </View>
          <View style={styles.chargeItem}>
            <Image src={getImagePath('chargePublicColored')} style={styles.fleetIcon} />
            <Text style={styles.chargeBoxTitle}>{trans.summary.chargeAtPublic}</Text>
          </View>
        </View>
        <View style={styles.chargeWrapper}>
          <View style={styles.chargeItem}>
            <Text style={styles.chargeStations}>{pdfReport?.workChargingStations}</Text>
            <Text style={styles.chargeBoxText}>{trans.summary.requiredCP}</Text>
          </View>
          <View style={styles.chargeItem}>
            <Text style={styles.chargeStations}>{pdfReport?.homeWallboxes}</Text>
            <Text style={styles.chargeBoxText}>{trans.summary.requiredCP}</Text>
          </View>
          <View style={styles.chargeItem}>
            <Text style={styles.chargeStations}>{pdfReport?.publicRFIDCards}</Text>
            <Text style={styles.chargeBoxText}>{trans.summary.requiredCPT}</Text>
          </View>
        </View>
        <View style={styles.description}>
          <Text style={styles.descriptionTitle}>{trans.summary.description.title}</Text>
          <Text style={styles.descriptionContent}>{trans.summary.description.subtitle}</Text>
        </View>
        <View>
          <Image style={styles.barChartWrapper} src={roadmapInfrastructureUrl} />
        </View>
      </PageLayout>
      {/* PAGE 3 */}
      <PageLayout caption={trans.summary.pageCaption}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{trans.summary.header.title}</Text>
          <Text style={styles.headerSubtitle}>{trans.summary.header.subtitle}</Text>
        </View>
        <View style={styles.statWrapper}>
          <View style={styles.stat}>
            <Image src={getImagePath('savingPotential')} style={styles.statIcon} />
            <View style={styles.statText}>
              <Text style={styles.statValue}>
                {tCommon('formattedPrice', { value: Math.round(pdfReport?.savingPotential || 0) })}
                {currency}
              </Text>
              <Text style={styles.statTitle}>{trans.summary.savingPotential}</Text>
            </View>
          </View>
          <View style={styles.stat}>
            <Image src={getImagePath('estimatedInvestment')} style={styles.statIcon} />
            <View style={styles.statText}>
              <Text style={styles.statValue}>
                {tCommon('formattedPrice', { value: pdfReport?.estimatedInvestmentCostMin || 0 })} -{' '}
                {tCommon('formattedPrice', { value: pdfReport?.estimatedInvestmentCostMax || 0 })}
                {currency}
              </Text>
              <Text style={styles.statTitle}>{trans.summary.investmentCost}</Text>
            </View>
          </View>
          <View style={styles.stat}>
            <Image src={getImagePath('operationCost')} style={styles.statIcon} />
            <View style={styles.statText}>
              <Text style={styles.statValue}>
                {tCommon('formattedPrice', {
                  value: Math.round(pdfReport?.maxOperationCostsPerMonth || 0),
                })}
                {currency}
              </Text>
              <Text style={styles.statTitle}>{trans.summary.operationalCost}</Text>
            </View>
          </View>
          <View style={styles.stat}>
            <Image src={getImagePath('co2Saved')} style={styles.statIcon} />
            <View style={styles.statText}>
              <Text style={styles.statValue}>
                {tCommon('formattedPrice', { value: (pdfReport?.co2EmissionsSaved || 0) / 1000 })} t
                CO₂-EQV
              </Text>
              <Text style={styles.statTitle}>{trans.summary.savingPotentialEmission}</Text>
            </View>
          </View>
        </View>
      </PageLayout>
    </>
  );
};

export default ExecutiveSummary;
