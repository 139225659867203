import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    height: 50,
    minWidth: 267,
    width: '100%',
    '&.Mui-disabled': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  info: {
    fontSize: '12px',
  },
}));

export default useStyles;
