import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FINGERPRINT } from 'constants/user';
import { createFingerprint } from 'framework/fingerprint';

import AppRouter from './AppRouter';
import { theme } from './theme';

import './fonts.css';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line no-unused-vars
  interface DefaultTheme extends Theme {}
}

const initFingerprint = async () => {
  if (!localStorage.getItem(FINGERPRINT)) {
    const fingerprint = await createFingerprint();
    localStorage.setItem(FINGERPRINT, fingerprint);
  }
};

const App = () => {
  useEffect(() => {
    initFingerprint();
  }, []);

  return (
    <CookiesProvider>
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Beschleunigen Sie Ihren Umstieg auf eine emissionsfreie Flotte, indem Sie Ihre
Kostenersparnis und Ihre CO2 Einsparungen berechnen. Sie können Ihre maßgeschneiderte
Roadmap zur vollständigen Elektrifizierung Ihrer Flotte hier erstellen."
          />
          {/*<meta name="viewport" content="width=1920" />*/}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <StylesProvider injectFirst>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppRouter />
              <ToastContainer limit={4} />
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </HelmetProvider>
    </CookiesProvider>
  );
};

export default App;
