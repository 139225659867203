import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Document, Font, PDFDownloadLink } from '@react-pdf/renderer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import InfoTooltip from 'components/infoTooltip/infoTooltip';

import { usePdfReport } from '../hooks';

import AssumptionsAndReports from './chapters/assumptionsAndReports/assumptionsAndReports';
import Co2EmissionLineChart from './chapters/assumptionsAndReports/components/charts/co2EmissionLineChart';
import EvIceComparisonBarChart from './chapters/assumptionsAndReports/components/charts/evIceComparisonBarChart';
import EVICEComparisonLineChart from './chapters/assumptionsAndReports/components/charts/evIceComparisonLineChart';
import RoadmapInfrastructureChart from './chapters/assumptionsAndReports/components/charts/roadmapInfrastructureChart';
import RoadmapVehiclesChart from './chapters/assumptionsAndReports/components/charts/roadmapVehiclesChart';
import Contact from './chapters/contact/contact';
import ExecutiveSummary from './chapters/executiveSummary/executiveSummary';
import FleetAnalysis from './chapters/fleetAnalysis/fleetAnalysis';
import OurSolution from './chapters/ourSolution/ourSolution';
import InterBold from './fonts/Inter-Bold.ttf';
import InterMedium from './fonts/Inter-Medium.ttf';
import Inter from './fonts/Inter-Regular.ttf';
import OxaniumMedium from './fonts/Oxanium-Medium.ttf';
import Oxanium from './fonts/Oxanium-Regular.ttf';
import PreventClosing from './preventClosing';
import useStyles from './styles';
import { IChartData, IPdfReports } from './types';

export const initialState: IChartData = {
  costComparisonLine: '',
  costComparisonBar: '',
  roadmapVehicles: '',
  roadmapInfrastructure: '',
  co2Emissions: '',
};

const Download = () => {
  const classes = useStyles();
  const { t } = useTranslation('businessCaseReports');

  const { isLoading, data: PDFOveralData } = usePdfReport();
  const [chartUrls, setChartUrls] = useState<IChartData>(initialState);
  // this is a flag to make sure all charts are drawn to start generating PDF
  const checkChartsExists = Object.keys(chartUrls).every((k) => chartUrls[k]);

  const Report = () => {
    Font.register({
      family: 'Inter',
      fonts: [
        { src: Inter },
        { src: InterMedium, fontWeight: '600' },
        { src: InterBold, fontWeight: 'bold' },
      ],
    });

    Font.register({
      family: 'Oxanium',
      fonts: [{ src: Oxanium }, { src: OxaniumMedium, fontWeight: 'bold' }],
    });

    return (
      <Document>
        <FleetAnalysis pdfReport={PDFOveralData?.data} />
        <ExecutiveSummary
          pdfReport={PDFOveralData?.data}
          roadmapInfrastructureUrl={chartUrls['roadmapInfrastructure']}
        />
        <AssumptionsAndReports
          pdfReport={PDFOveralData?.data as IPdfReports}
          evICEComparisonLineChartUrl={chartUrls['costComparisonLine']}
          evICEComparisonBarChartUrl={chartUrls['costComparisonBar']}
          roadmapVehiclesUrl={chartUrls['roadmapVehicles']}
          roadmapInfrastructureUrl={chartUrls['roadmapInfrastructure']}
          co2EmissionUrl={chartUrls['co2Emissions']}
        />
        <OurSolution />
        {/* REMOVE PACKAGE AND PRICES SECTION : DC-1124 */}
        {/* <PackagesAndPrices pdfReport={pdfReport} /> */}
        <Contact pdfReport={PDFOveralData?.data} />
      </Document>
    );
  };

  const Charts = () => (
    //  On every chart drawing we check for other ones.
    <>
      {!chartUrls['costComparisonLine'] && (
        <EVICEComparisonLineChart
          // currency={profile?.country.currencySign}
          data={PDFOveralData?.data?.costComparison?.costComparisonChart}
          isLoading={isLoading}
          setUrl={(url) => setChartUrls({ ...chartUrls, costComparisonLine: url })}
        />
      )}
      {!chartUrls['costComparisonBar'] && (
        <EvIceComparisonBarChart
          // currency={profile?.country.currencySign}
          data={PDFOveralData?.data?.costComparison?.costComparisonChart}
          isLoading={isLoading}
          setUrl={(url) => setChartUrls({ ...chartUrls, costComparisonBar: url })}
        />
      )}
      {!chartUrls['roadmapVehicles'] && (
        <RoadmapVehiclesChart
          data={PDFOveralData?.data?.roadmapVehicles}
          isLoading={isLoading}
          setUrl={(url) => setChartUrls({ ...chartUrls, roadmapVehicles: url })}
        />
      )}
      {!chartUrls['roadmapInfrastructure'] && (
        <RoadmapInfrastructureChart
          data={PDFOveralData?.data?.roadmapInfrastructure}
          isLoading={isLoading}
          setUrl={(url) => setChartUrls({ ...chartUrls, roadmapInfrastructure: url })}
        />
      )}
      {!chartUrls['co2Emissions'] && (
        <Co2EmissionLineChart
          // currency={profile?.country.currencySign}
          data={PDFOveralData?.data?.co2Emissions}
          isLoading={isLoading}
          setUrl={(url) => setChartUrls({ ...chartUrls, co2Emissions: url })}
        />
      )}
    </>
  );
  // We use portal to make sure hierarchy of component do not mixed up.
  return ReactDOM.createPortal(
    <>
      <Charts />
      <Box width="100%" mt={1}>
        {/* <Box className={classes.info} mr={1}>
          {t('downloadCaption')}
          <InfoTooltip title={t('info')} iconSize={12} />
        </Box> */}
        <PDFDownloadLink
          document={checkChartsExists ? <Report /> : <Document />}
          fileName="eConsultant_Elektrifizierungs_Report.pdf"
        >
          {({ loading }) => {
            const disabled = loading || isLoading || !checkChartsExists;
            return (
              <>
                <Button
                  className={classes.downloadButton}
                  variant="outlined"
                  endIcon={<KeyboardArrowRightIcon />}
                  disabled={disabled}
                >
                  {disabled ? t('generating') : t('checkForPotentialSaving')}
                </Button>
                <PreventClosing loading={disabled} />
              </>
            );
          }}
        </PDFDownloadLink>
      </Box>
      {/* Can enable the preview for sake of development */}

      {/* {checkChartsExists && (
        <PDFViewer style={{ width: '100%', height: 800 }}>
          <Report />
        </PDFViewer>
      )} */}
    </>,
    document.getElementById('report-pdf') || document.body,
  );
};

export default Download;
