import { Theme, alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles<Theme>((theme) => ({
  root: {
    width: '1.3em',
    height: '1.3em',
    marginLeft: theme.spacing(0.5),
  },
  tooltipWrapper: {
    display: 'inline-block',
    '& .MuiTooltip-tooltip': {
      textAlign: 'left',
      whiteSpace: 'pre-line',
      backgroundColor: alpha(theme.palette.common.black, 0.75),
      opacity: 0.8,
      color: theme.palette.common.white,
      '& .MuiTooltip-arrow': {
        color: alpha(theme.palette.common.black, 0.75),
      },
    },
  },
}));
