export const LANDING_PAGE = '/';
export const INITIAL_CHECK = '/initial-check';
export const FLEETPROFILE = '/fleetProfile';
export const ELECTRIFICATION = '/electrification';
export const ELECTRIFICATION_VEHICLES = '/electrification-vehicles';
export const WORKPLACE_OPTIONS = '/workplace-options';
export const HOME_OPTIONS = '/home-options';
export const PARKING_LOCATIONS = '/parking-locations';
export const PUBLIC_OPTIONS = '/public-options';
export const FLEET_CORRECTION = '/fleet-correction';
export const PARKED_HOURS = '/parked-hours';
export const EV_DETAILS = '/ev-details';
export const FLEET_DETAILS = '/fleet-details';
export const BIG_FLEET_REGISTER = '/big-fleet-register';
export const FLEET_REGISTER = '/fleet-register';
export const BUSINESSCASE_REPORTS = '/reports';
export const BUSINESSCASE = '/business-case';
export const DOWNLOAD = '/download';
// EXTERNALS
export const IMPRINT = 'https://www.inno2fleet.com/impressum';
export const DATA_PRIVACY = 'https://www.inno2fleet.com/datenschutz';
export const INNO2FLEET = 'https://www.inno2fleet.com/';
