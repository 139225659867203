import React from 'react';
import ContentLoader from 'react-content-loader';

const HeaderLoader = (props: any) => (
  <ContentLoader width="100%" height={167} viewBox="0 0 1200 180" {...props}>
    <rect x="0" y="0" rx="6" ry="6" width="33%" height="100" />
    <rect x="426" y="0" rx="6" ry="6" width="33%" height="100" />
    <rect x="850" y="0" rx="6" ry="6" width="33%" height="100" />
    <rect x="0" y="130" rx="6" ry="6" width="140" height="45" />
    <rect x="150" y="130" rx="6" ry="6" width="140" height="45" />
    <rect x="300" y="130" rx="6" ry="6" width="140" height="45" />
    <rect x="450" y="130" rx="6" ry="6" width="140" height="45" />
    <rect x="600" y="130" rx="6" ry="6" width="140" height="45" />
    <rect x="750" y="130" rx="6" ry="6" width="140" height="45" />
  </ContentLoader>
);

export default HeaderLoader;
