import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';

import { formatPrice } from 'framework/helpers';

import { EN, DE } from './translations/resources';
export const defaultNS = 'pages';
const lang = localStorage.getItem('i18nextLng') || 'de';
const formatLang = lang === 'de' ? 'de-DE' : 'en-EN';
const isProduction = process.env.NODE_ENV === 'production';
i18n
  // .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    /*
      This one can be removed and detector will be used.
    */
    lng: lang,
    fallbackLng: 'en',
    debug: !isProduction,
    partialBundledLanguages: true,
    resources: { en: EN, de: DE },
    defaultNS: 'pages',
    interpolation: {
      escapeValue: false,
      format: (value, rawFormat) => {
        const [format, ...additionalValues] = rawFormat
          ? rawFormat.split(',').map((v) => v.trim())
          : [];

        if (format) {
          if (format === 'price') {
            return Intl.NumberFormat(formatLang, {
              style: 'currency',
              currency: additionalValues[0],
            }).format(+value);
          }

          if (format === 'formattedPrice') {
            return Intl.NumberFormat(formatLang, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(+value);
          }
          if (format === 'floorPrice') {
            return Intl.NumberFormat(formatLang, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 5,
            }).format(+value);
          }
          if (format === 'priceOnly') {
            return Intl.NumberFormat(formatLang, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
              .format(+formatPrice(value))
              .replace(/^(\D+)/, '$1 ');
          }
          if (format === 'number') {
            return Intl.NumberFormat(formatLang, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(+value);
          }
        }

        return value;
      },
    },
    load: 'currentOnly',
    react: {
      useSuspense: false,
    },
    saveMissing: false,
  });

export default i18n;
