import { FC } from 'react';
import { Image, View } from '@react-pdf/renderer';

import styles from './styles';

interface IProps {
  type: string;
}

const PackageIcon: FC<IProps> = ({ type }) => {
  const getImagePath = (name: string) => {
    return `/img/business-case/reports/${name}.png`;
  };

  const getStyles = (type: string) => {
    return [styles.wrapper, type === 'medium' ? styles.normal : styles.light];
  };

  return (
    <View style={getStyles(type)}>
      <Image src={getImagePath(type)} style={styles.packageIcon} />
    </View>
  );
};

export default PackageIcon;
