import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import ToggleButtonGroup from 'components/toggleButtonGroup/toggleButtonGroup';

import { IBusinessCaseReportSummary } from '../../index';
import { useStyles } from '../../styles';

import HeaderLoader from './components/skeleton';

interface IList {
  id: number;
  name: string;
  disabled: boolean;
  tooltipTitle: string;
}

interface IProps {
  activeChart: number;
  // eslint-disable-next-line no-unused-vars
  setActiveChart: (val: number) => void;
  summary: IBusinessCaseReportSummary;
}
const ReportsHeader = ({ activeChart, setActiveChart, summary }: IProps) => {
  const { t, ready: tReady } = useTranslation('businessCaseReports');
  const { t: tCommon, ready: tCommonReady } = useTranslation('common');

  const list: IList[] = [
    {
      id: 2,
      name: t('comparisonEvVsICEBar'),
      disabled: false,
      tooltipTitle: '',
    },
    {
      id: 3,
      name: t('comparisonEvVsICELine'),
      disabled: false,
      tooltipTitle: '',
    },

    // {
    //   id: 7,
    //   name: t('roadmapVehicles'),
    //   disabled: false,
    //   tooltipTitle: '',
    // },
    {
      id: 8,
      name: t('roadmapInfrastructure'),
      disabled: false,
      tooltipTitle: '',
    },
    {
      id: 9,
      name: t('co2Emissions'),
      disabled: false,
      tooltipTitle: '',
    },
  ];
  const classes = useStyles();
  return (
    <Box py={3} px={1}>
      {tReady && tCommonReady ? (
        <>
          {summary ? (
            <>
              <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className={classes.headerItem}
                  >
                    <Grid item>
                      <div className={classes.icon}>
                        <img src="/img/business-case/header/icon.png" alt="header-icon" />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.headerTag}>
                        {tCommon('formattedPrice', { value: summary?.savingPotential || 0 })} €
                      </Typography>
                      {/* <span className={classes.sign}>{profile?.country?.currencySign}</span> */}
                      <Box textAlign="center" mt={1}>
                        <Typography variant="body2">{t('savingPotential')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} item>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className={classes.headerItem}
                  >
                    <Grid item>
                      <div className={classes.icon}>
                        <img src="/img/business-case/header/CO2.png" alt="header-icon" />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.headerTag}>
                        {tCommon('formattedPrice', {
                          value: summary?.co2EmissionsSaved / 1000 || 0,
                        })}
                      </Typography>
                      <span className={classes.sign}>{t('savedEmissionSign')}</span>
                      <Box textAlign="center" mt={1}>
                        <Typography variant="body2">{t('emissionSaved')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ToggleButtonGroup
                value={activeChart}
                onChange={(list) => setActiveChart(list)}
                items={list?.map((item: IList) => ({
                  label: t(item.name),
                  value: item.id,
                  disabled: item.disabled,
                  tooltipTitle: item.tooltipTitle || '',
                }))}
                className={classes.countriesContainer}
                itemClassName={classes.items}
                itemSelectedClassName={classes.itemSelected}
              />
            </>
          ) : (
            <HeaderLoader />
          )}
        </>
      ) : (
        <Box width="100%" m={3} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default ReportsHeader;
