import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  firstInput: {
    marginTop: theme.spacing(2),
  },
  terms: {
    color: theme.palette.primary.main,
  },
  number: {
    '& .numberFormat_error': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
  errorLabel: {
    margin: `0 ${theme.spacing(2)}`,
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
  form: {
    '& .MuiFormControl-root': {
      marginTop: '4px',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.hint.main,
        },
      },
    },
    '& textarea': {
      color: '#fff',
      padding: '0',
    },
    '& .MuiFormControlLabel-root': {
      border: 'none',
      '& span': {
        fontSize: '0.9rem',
      },
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        padding: '4px 8px',
      },
    },
  },
}));
