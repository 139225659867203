import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const SettingsIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.75 5.99994H12.145C11.983 5.42381 11.6371 4.91641 11.1601 4.55502C10.6831 4.19363 10.101 3.99805 9.5025 3.99805C8.90403 3.99805 8.32195 4.19363 7.84491 4.55502C7.36787 4.91641 7.022 5.42381 6.85999 5.99994H5.25C5.05109 5.99994 4.86032 6.07895 4.71967 6.2196C4.57901 6.36026 4.5 6.55103 4.5 6.74994C4.5 6.94885 4.57901 7.13959 4.71967 7.28024C4.86032 7.4209 5.05109 7.49994 5.25 7.49994H6.85999C7.022 8.07606 7.36787 8.58343 7.84491 8.94482C8.32195 9.30621 8.90403 9.5018 9.5025 9.5018C10.101 9.5018 10.6831 9.30621 11.1601 8.94482C11.6371 8.58343 11.983 8.07606 12.145 7.49994H18.75C18.8485 7.49994 18.946 7.48053 19.037 7.44284C19.128 7.40515 19.2107 7.34989 19.2803 7.28024C19.35 7.2106 19.4052 7.12792 19.4429 7.03693C19.4806 6.94593 19.5 6.84843 19.5 6.74994C19.5 6.65145 19.4806 6.55392 19.4429 6.46292C19.4052 6.37193 19.35 6.28925 19.2803 6.2196C19.2107 6.14996 19.128 6.0947 19.037 6.05701C18.946 6.01932 18.8485 5.99994 18.75 5.99994ZM9.5 7.99994C9.25277 7.99994 9.0111 7.92663 8.80554 7.78928C8.59998 7.65192 8.43976 7.45668 8.34515 7.22827C8.25054 6.99986 8.22579 6.74855 8.27402 6.50607C8.32225 6.2636 8.4413 6.04087 8.61612 5.86606C8.79093 5.69124 9.01366 5.57219 9.25613 5.52396C9.49861 5.47572 9.74995 5.50048 9.97836 5.59509C10.2068 5.6897 10.402 5.84989 10.5393 6.05545C10.6767 6.26101 10.75 6.50271 10.75 6.74994C10.75 6.91409 10.7177 7.07661 10.6548 7.22827C10.592 7.37993 10.5 7.51775 10.3839 7.63382C10.2678 7.74989 10.13 7.84197 9.97836 7.90478C9.82671 7.9676 9.66415 7.99994 9.5 7.99994Z" />
      <path d="M18.75 11.9999H17.145C16.983 11.4238 16.6371 10.9164 16.1601 10.555C15.6831 10.1936 15.101 9.99805 14.5025 9.99805C13.904 9.99805 13.3219 10.1936 12.8449 10.555C12.3679 10.9164 12.022 11.4238 11.86 11.9999H5.25C5.05109 11.9999 4.86032 12.079 4.71967 12.2196C4.57901 12.3603 4.5 12.551 4.5 12.7499C4.5 12.9489 4.57901 13.1396 4.71967 13.2802C4.86032 13.4209 5.05109 13.4999 5.25 13.4999H11.86C12.022 14.0761 12.3679 14.5834 12.8449 14.9448C13.3219 15.3062 13.904 15.5018 14.5025 15.5018C15.101 15.5018 15.6831 15.3062 16.1601 14.9448C16.6371 14.5834 16.983 14.0761 17.145 13.4999H18.75C18.9489 13.4999 19.1397 13.4209 19.2803 13.2802C19.421 13.1396 19.5 12.9489 19.5 12.7499C19.5 12.551 19.421 12.3603 19.2803 12.2196C19.1397 12.079 18.9489 11.9999 18.75 11.9999ZM14.5 13.9999C14.2528 13.9999 14.0111 13.9266 13.8055 13.7893C13.6 13.6519 13.4398 13.4567 13.3452 13.2283C13.2505 12.9999 13.2258 12.7485 13.274 12.5061C13.3222 12.2636 13.4413 12.0409 13.6161 11.8661C13.7909 11.6912 14.0137 11.5722 14.2561 11.524C14.4986 11.4757 14.75 11.5005 14.9784 11.5951C15.2068 11.6897 15.402 11.8499 15.5393 12.0555C15.6767 12.261 15.75 12.5027 15.75 12.7499C15.75 13.0815 15.6183 13.3994 15.3839 13.6338C15.1495 13.8682 14.8315 13.9999 14.5 13.9999Z" />
      <path d="M21.75 0H2.25C1.65367 0.00132078 1.08213 0.238791 0.660461 0.660461C0.238791 1.08213 0.00132078 1.65367 0 2.25V17.25C0 17.8467 0.237055 18.419 0.659012 18.841C1.08097 19.2629 1.65326 19.5 2.25 19.5H9.66998C9.50745 20.5612 9.13887 21.5803 8.58499 22.5H6.75C6.55109 22.5 6.36032 22.579 6.21967 22.7197C6.07901 22.8603 6 23.0511 6 23.25C6 23.4489 6.07901 23.6397 6.21967 23.7803C6.36032 23.921 6.55109 24 6.75 24H17.25C17.4489 24 17.6397 23.921 17.7803 23.7803C17.921 23.6397 18 23.4489 18 23.25C18 23.0511 17.921 22.8603 17.7803 22.7197C17.6397 22.579 17.4489 22.5 17.25 22.5H15.42C14.8648 21.581 14.4961 20.5615 14.335 19.5H21.75C22.0457 19.5007 22.3385 19.4429 22.6118 19.33C22.8851 19.2172 23.1334 19.0515 23.3425 18.8424C23.5515 18.6334 23.7172 18.3851 23.8301 18.1118C23.9429 17.8385 24.0007 17.5457 24 17.25V2.25C24 1.95453 23.9418 1.66193 23.8287 1.38895C23.7157 1.11596 23.5499 0.867928 23.341 0.658997C23.132 0.450065 22.884 0.284338 22.611 0.171265C22.338 0.0581916 22.0455 0 21.75 0ZM13.71 22.5H10.295C10.7502 21.5548 11.0475 20.5413 11.175 19.5H12.825C12.9543 20.5416 13.2532 21.555 13.71 22.5ZM22.5 17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18H2.25C2.05109 18 1.86032 17.921 1.71967 17.7803C1.57901 17.6397 1.5 17.4489 1.5 17.25V2.25C1.50131 2.05149 1.58075 1.86147 1.72112 1.7211C1.86148 1.58073 2.05149 1.50131 2.25 1.5H21.75C21.9489 1.5 22.1397 1.57901 22.2803 1.71967C22.421 1.86032 22.5 2.05109 22.5 2.25V17.25Z" />
    </SvgIcon>
  );
};

export default SettingsIcon;
