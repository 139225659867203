import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  /* COMMON */
  header: {
    paddingLeft: '8px',
    borderLeft: '4px solid #60F4D9',
  },
  headerTitle: {
    fontSize: '24px',
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: '18px',
    fontWeight: 'light',
    fontFamily: 'Inter',
    color: '#5F6380',
  },
  /* PAGE 2 */
  chargeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0 10px 10px',
  },
  chargeItem: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fleetIcon: {
    width: '28px',
    height: '28px',
    marginBottom: '5px',
    marginRight: '5px',
  },
  chargeBoxTitle: {
    fontSize: '14px',
    color: '#282C42',
  },
  chargeStations: {
    fontSize: 24,
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
    marginRight: 10,
    lineHeight: 1,
    color: '#282C42',
  },
  chargeBoxText: {
    fontSize: 12,
    color: '#282C42',
  },
  description: {
    border: '2px solid #5b667c',
    borderRadius: 6,
    margin: '10px 140px 30px 10px',
    padding: '12px 10px 10px',
    fontSize: 8,
    lineHeight: 1.6,
  },
  descriptionTitle: {
    fontWeight: 600,
  },
  descriptionContent: {},
  barChartWrapper: {
    marginRight: 100,
    marginLeft: -30,
  },
  /* PAGE 3 */
  statWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  stat: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    marginTop: 100,
  },
  statIcon: {
    width: 45,
    height: 45,
  },
  statText: {
    margin: '0 90px 0 7px',
  },
  statValue: {
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#282C42',
  },
  statTitle: {
    color: '#5F6380',
    fontSize: 14,
  },
});

export default styles;
