import { useTranslation } from 'react-i18next';
import { Image, Page, Text, View } from '@react-pdf/renderer';

import styles from '../styles';

const ChargeAtPublic = () => {
  const { t } = useTranslation('report');
  const summary = [
    {
      title: t('chargeAtPublic.itemTitle'),
      labels: [t('chargeAtPublic.item1'), t('chargeAtPublic.item2'), t('chargeAtPublic.item3')],
    },
  ];
  return (
    <Page orientation="landscape" style={{ position: 'relative' }}>
      <View style={styles.wrapper}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{t('chargeAtPublic.headerTitle')}</Text>
          <Text style={styles.headerSubtitle}>{t('chargeAtPublic.headerSubtitle')}</Text>
        </View>
      </View>
      <View style={styles.publicStore}>
        <Text>{t('chargeAtPublic.public')}</Text>
      </View>
      <View style={styles.summaryWrapper}>
        {summary.map(({ labels, title }) => (
          <View key={title} style={styles.summaryListWrapper}>
            <Text
              style={{
                marginBottom: '10px',
                fontSize: '13px',
                lineHeight: '1.5px',
              }}
            >
              {title}
            </Text>
            {labels.map((val) => (
              <View key={val} style={styles.listViewHighlight}>
                <Text style={styles.listBulletHighlight}>•</Text>
                <Text style={styles.listTextHighlight}>{val}</Text>
              </View>
            ))}
          </View>
        ))}
        <View style={styles.cardWrapper}>
          <View style={styles.card}>
            <Image src="/img/business-case/reports/ourSolution/card.png" />
          </View>
          <Text style={styles.cardDesc}>{t('chargeAtPublic.cardDesc')}</Text>
        </View>
        <View style={styles.cardBg}>
          <Image src="/img/business-case/reports/ourSolution/world.png" />
        </View>
      </View>
      <View style={styles.footerLogo}>
        <Image src="/img/inno2fleet-logo-pdf.png" style={styles.logo} />
      </View>
    </Page>
  );
};
export default ChargeAtPublic;
