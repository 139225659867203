import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const CarSharingFleet = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M23.9996 3.99231C24.7959 3.99231 25.5597 4.30808 26.1233 4.87038C26.687 5.43268 27.0045 6.19559 27.0063 6.99168V8.57802L25.713 7.32495C25.6202 7.23173 25.5099 7.15771 25.3885 7.1071C25.2671 7.05648 25.1369 7.03023 25.0053 7.02992C24.8738 7.02961 24.7435 7.05522 24.6218 7.10527C24.5002 7.15531 24.3895 7.22883 24.2963 7.32161C24.2031 7.41439 24.129 7.52461 24.0784 7.64601C24.0278 7.7674 24.0016 7.89758 24.0012 8.02911C24.0009 8.16063 24.0265 8.29094 24.0766 8.41257C24.1267 8.5342 24.2002 8.64476 24.293 8.73798L27.293 11.7374C27.3888 11.8315 27.502 11.9062 27.6263 11.9573C27.8604 12.0459 28.1189 12.0459 28.353 11.9573C28.4744 11.9045 28.5851 11.83 28.6796 11.7374L31.6796 8.73798C31.7724 8.64476 31.846 8.5342 31.896 8.41257C31.9461 8.29094 31.9717 8.16063 31.9714 8.02911C31.9711 7.89758 31.9449 7.7674 31.8942 7.64601C31.8436 7.52461 31.7695 7.41439 31.6763 7.32161C31.5831 7.22883 31.4725 7.15531 31.3508 7.10527C31.2292 7.05522 31.0988 7.02961 30.9673 7.02992C30.8357 7.03023 30.7055 7.05648 30.5841 7.1071C30.4627 7.15771 30.3524 7.23173 30.2596 7.32495L28.973 8.61801V7.03167C28.9695 5.7058 28.4406 4.43535 27.5023 3.49843C26.5639 2.56152 25.2925 2.03447 23.9663 2.03271C23.7011 2.03271 23.4467 2.13804 23.2592 2.32554C23.0717 2.51304 22.9663 2.76735 22.9663 3.03251C22.9663 3.29767 23.0717 3.55197 23.2592 3.73947C23.4467 3.92697 23.7011 4.0323 23.9663 4.0323L23.9996 3.99231Z" />
        <path d="M8.00023 27.9875C7.20512 27.9857 6.44309 27.6692 5.88086 27.107C5.31863 26.5449 5.00199 25.783 5.00023 24.9881V23.4018L6.29356 24.6948C6.4822 24.8825 6.73747 24.9878 7.00356 24.9878C7.26966 24.9878 7.52493 24.8825 7.71356 24.6948C7.89922 24.5065 8.0033 24.2527 8.0033 23.9883C8.0033 23.7239 7.89922 23.4701 7.71356 23.2818L4.71356 20.2824C4.61925 20.188 4.5055 20.1152 4.38023 20.0691C4.26517 20.0209 4.14167 19.9961 4.01691 19.9961C3.89215 19.9961 3.76863 20.0209 3.65357 20.0691C3.53114 20.1169 3.41992 20.1896 3.32691 20.2824L0.326907 23.2818C0.139487 23.4701 0.0345339 23.725 0.035159 23.9907C0.0357842 24.2563 0.14194 24.5108 0.330244 24.6982C0.518547 24.8855 0.773592 24.9905 1.03927 24.9898C1.30495 24.9892 1.55949 24.8831 1.74691 24.6948L3.03357 23.4018V24.9881C3.03534 26.3134 3.56268 27.5838 4.49998 28.5209C5.43728 29.458 6.70803 29.9853 8.03357 29.9871C8.29879 29.9871 8.55315 29.8817 8.74068 29.6942C8.92822 29.5067 9.03357 29.2524 9.03357 28.9873C9.03357 28.7221 8.92822 28.4678 8.74068 28.2803C8.55315 28.0928 8.29879 27.9875 8.03357 27.9875H8.00023Z" />
        <path d="M29.9267 20.5889L28.4134 15.7299C28.3956 15.6589 28.3663 15.5914 28.3267 15.5299C28.0529 15.0431 27.6493 14.642 27.1607 14.3713C26.6722 14.1006 26.118 13.971 25.56 13.9969H22C21.7348 13.9969 21.4804 14.1022 21.2929 14.2897C21.1054 14.4772 21 14.7315 21 14.9967C21 15.2619 21.1054 15.5162 21.2929 15.7037C21.4804 15.8912 21.7348 15.9965 22 15.9965H25.6267C25.8045 15.9859 25.982 16.0215 26.1421 16.0997C26.3021 16.1779 26.4392 16.2962 26.54 16.4431L27.6467 19.9957H16.3133C16.3113 19.7577 16.2245 19.5283 16.0684 19.3486C15.9123 19.1689 15.6973 19.0508 15.4619 19.0154C15.2265 18.9801 14.9862 19.0298 14.7842 19.1557C14.5823 19.2817 14.4318 19.4755 14.36 19.7024L14.08 20.5889L12.7867 22.8084C12.2832 23.3528 12.0024 24.0664 12 24.808V26.9809C12.0037 27.5992 12.1976 28.2014 12.5554 28.7058C12.9132 29.2102 13.4175 29.5923 14 29.8003V30.98C14 31.2452 14.1054 31.4995 14.2929 31.687C14.4804 31.8745 14.7348 31.9798 15 31.9798C15.2652 31.9798 15.5196 31.8745 15.7071 31.687C15.8946 31.4995 16 31.2452 16 30.98V29.9869H28V30.9867C28 31.2519 28.1054 31.5062 28.2929 31.6937C28.4804 31.8812 28.7348 31.9865 29 31.9865C29.2652 31.9865 29.5196 31.8812 29.7071 31.6937C29.8946 31.5062 30 31.2519 30 30.9867V29.8069C30.5819 29.5982 31.0858 29.2159 31.4435 28.7117C31.8011 28.2074 31.9954 27.6057 32 26.9875V24.8146C31.9957 24.0735 31.7152 23.3606 31.2133 22.8151L29.9267 20.5889ZM30.0067 26.9942C30.0049 27.2588 29.899 27.5121 29.7119 27.6992C29.5247 27.8863 29.2714 27.9922 29.0067 27.994H15.0067C14.7415 27.994 14.4871 27.8887 14.2996 27.7012C14.112 27.5137 14.0067 27.2594 14.0067 26.9942V24.8213C14.0083 24.5565 14.1136 24.3029 14.3 24.1148C14.3605 24.0542 14.4141 23.9871 14.46 23.9148L15.58 21.9952H28.4333L29.56 23.9148C29.6038 23.9886 29.6576 24.0559 29.72 24.1148C29.904 24.3045 30.0089 24.5571 30.0133 24.8213L30.0067 26.9942Z" />
        <path d="M19 23.9883H17C16.7348 23.9883 16.4804 24.0936 16.2929 24.2811C16.1054 24.4686 16 24.7229 16 24.9881C16 25.2532 16.1054 25.5075 16.2929 25.695C16.4804 25.8825 16.7348 25.9879 17 25.9879H19C19.2652 25.9879 19.5196 25.8825 19.7071 25.695C19.8946 25.5075 20 25.2532 20 24.9881C20 24.7229 19.8946 24.4686 19.7071 24.2811C19.5196 24.0936 19.2652 23.9883 19 23.9883Z" />
        <path d="M27 23.9883H25C24.7348 23.9883 24.4804 24.0936 24.2929 24.2811C24.1054 24.4686 24 24.7229 24 24.9881C24 25.2532 24.1054 25.5075 24.2929 25.695C24.4804 25.8825 24.7348 25.9879 25 25.9879H27C27.2652 25.9879 27.5196 25.8825 27.7071 25.695C27.8946 25.5075 28 25.2532 28 24.9881C28 24.7229 27.8946 24.4686 27.7071 24.2811C27.5196 24.0936 27.2652 23.9883 27 23.9883Z" />
        <path d="M2 15.8167V16.9965C2 17.2616 2.10537 17.5159 2.29291 17.7034C2.48044 17.8909 2.73478 17.9963 3 17.9963C3.26522 17.9963 3.51958 17.8909 3.70711 17.7034C3.89465 17.5159 4 17.2616 4 16.9965V15.99H16V16.9898C16 17.255 16.1054 17.5093 16.2929 17.6968C16.4804 17.8843 16.7348 17.9896 17 17.9896C17.2652 17.9896 17.5196 17.8843 17.7071 17.6968C17.8946 17.5093 18 17.255 18 16.9898V15.81C18.5835 15.6038 19.089 15.2221 19.4471 14.7173C19.8051 14.2126 19.9983 13.6094 20 12.9906V10.8177C20.0001 10.077 19.7217 9.36327 19.22 8.81816L17.9267 6.59198L16.4134 1.73296C16.3956 1.66201 16.3663 1.59446 16.3267 1.53301C16.0474 1.04512 15.6388 0.643806 15.1459 0.373303C14.653 0.102801 14.095 -0.0263745 13.5333 8.78749e-06H6.50667C5.93987 -0.0303599 5.37573 0.0968114 4.87679 0.367405C4.37785 0.637998 3.96362 1.04143 3.68001 1.53301C3.64035 1.59446 3.61106 1.66201 3.59334 1.73296L2.08002 6.59198L0.786682 8.81149C0.283152 9.35594 0.0023928 10.0695 0 10.8111V12.984C0.000928553 13.6046 0.193551 14.2098 0.551534 14.7168C0.909517 15.2239 1.41539 15.608 2 15.8167ZM5.46667 2.44615C5.57666 2.2925 5.72506 2.17039 5.89703 2.09202C6.069 2.01366 6.25853 1.98179 6.44668 1.99959H13.6267C13.8045 1.989 13.982 2.02456 14.1421 2.1028C14.3021 2.18105 14.4392 2.29932 14.54 2.44615L15.6467 5.99876H4.36001L5.46667 2.44615ZM2 10.8244C2.00167 10.5596 2.10697 10.306 2.29333 10.1179C2.35381 10.0573 2.40747 9.99024 2.45335 9.91795L3.57334 7.99834H16.4267L17.5533 9.91795C17.5992 9.99024 17.6529 10.0573 17.7133 10.1179C17.8979 10.3067 18.0008 10.5604 18 10.8244V12.9973C18 13.2625 17.8946 13.5168 17.7071 13.7043C17.5196 13.8918 17.2652 13.9971 17 13.9971H3C2.73478 13.9971 2.48044 13.8918 2.29291 13.7043C2.10537 13.5168 2 13.2625 2 12.9973V10.8244Z" />
        <path d="M5 11.9908H7C7.26522 11.9908 7.51958 11.8855 7.70711 11.698C7.89465 11.5105 8 11.2562 8 10.991C8 10.7258 7.89465 10.4715 7.70711 10.284C7.51958 10.0965 7.26522 9.99121 7 9.99121H5C4.73478 9.99121 4.48044 10.0965 4.29291 10.284C4.10537 10.4715 4 10.7258 4 10.991C4 11.2562 4.10537 11.5105 4.29291 11.698C4.48044 11.8855 4.73478 11.9908 5 11.9908Z" />
        <path d="M13 11.9908H15C15.2652 11.9908 15.5196 11.8855 15.7071 11.698C15.8946 11.5105 16 11.2562 16 10.991C16 10.7258 15.8946 10.4715 15.7071 10.284C15.5196 10.0965 15.2652 9.99121 15 9.99121H13C12.7348 9.99121 12.4804 10.0965 12.2929 10.284C12.1054 10.4715 12 10.7258 12 10.991C12 11.2562 12.1054 11.5105 12.2929 11.698C12.4804 11.8855 12.7348 11.9908 13 11.9908Z" />
      </g>
    </SvgIcon>
  );
};

export default CarSharingFleet;
