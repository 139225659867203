import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const FleetIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M15 7.5C15 6.75832 14.7801 6.0333 14.368 5.41661C13.9559 4.79993 13.3703 4.31929 12.6851 4.03546C11.9998 3.75163 11.2458 3.67736 10.5184 3.82205C9.79098 3.96675 9.1228 4.32391 8.59835 4.84836C8.0739 5.37281 7.71675 6.04099 7.57206 6.76842C7.42736 7.49585 7.50162 8.24984 7.78545 8.93506C8.06928 9.62028 8.54993 10.206 9.16662 10.618C9.7833 11.0301 10.5083 11.25 11.25 11.25C12.2446 11.25 13.1984 10.8549 13.9016 10.1517C14.6049 9.4484 15 8.49456 15 7.5ZM8.99999 7.5C8.99999 7.05499 9.13195 6.61998 9.37919 6.24997C9.62642 5.87996 9.97783 5.59158 10.389 5.42128C10.8001 5.25098 11.2525 5.20643 11.689 5.29325C12.1254 5.38006 12.5263 5.59435 12.841 5.90901C13.1557 6.22368 13.3699 6.6246 13.4568 7.06105C13.5436 7.49751 13.499 7.94991 13.3287 8.36104C13.1584 8.77217 12.87 9.12357 12.5 9.37081C12.13 9.61804 11.695 9.75 11.25 9.75C10.6533 9.75 10.081 9.51295 9.659 9.09099C9.23705 8.66903 8.99999 8.09674 8.99999 7.5Z" />
      <path d="M10.5839 20.52C10.7759 20.6635 11.0092 20.7407 11.2489 20.74C11.4273 20.7402 11.6032 20.6982 11.7623 20.6174C11.9213 20.5366 12.0589 20.4192 12.1639 20.275C14.1639 17.545 18.7539 10.84 18.7539 7.5C18.7539 5.51088 17.9637 3.60322 16.5572 2.1967C15.1507 0.790178 13.243 0 11.2539 0C9.26478 0 7.35713 0.790178 5.95061 2.1967C4.54408 3.60322 3.75391 5.51088 3.75391 7.5C3.75391 10.84 8.36391 17.545 10.3489 20.28C10.4144 20.3716 10.4937 20.4526 10.5839 20.52ZM11.2489 1.52C12.8402 1.52 14.3663 2.15214 15.4915 3.27736C16.6168 4.40258 17.2489 5.92871 17.2489 7.52C17.2489 9.81 14.0789 15.02 11.2489 19.02C8.4139 15.055 5.2489 9.835 5.2489 7.52C5.24627 6.73039 5.39953 5.94802 5.69987 5.21776C6.00022 4.48749 6.44176 3.82371 6.99918 3.26443C7.55659 2.70516 8.21892 2.26141 8.94818 1.95863C9.67744 1.65586 10.4593 1.5 11.2489 1.5V1.52Z" />
      <path d="M17.375 17.0448C17.2782 17.0257 17.1785 17.0259 17.0817 17.0454C16.985 17.0649 16.893 17.1032 16.8111 17.1582C16.7291 17.2133 16.6588 17.2839 16.6041 17.3661C16.5495 17.4483 16.5115 17.5404 16.4925 17.6373C16.4735 17.7341 16.4737 17.8338 16.4932 17.9305C16.5126 18.0273 16.551 18.1193 16.606 18.2012C16.661 18.2832 16.7316 18.3535 16.8138 18.4081C16.896 18.4628 16.9882 18.5007 17.085 18.5198C20.015 19.0998 21.005 19.9498 21.005 20.2498C21.005 20.9198 17.685 22.4998 11.25 22.4998C4.815 22.4998 1.5 20.9198 1.5 20.2498C1.5 19.9498 2.50001 19.0998 5.40001 18.5198C5.49901 18.503 5.59364 18.4665 5.67828 18.4125C5.76293 18.3585 5.83586 18.288 5.89276 18.2053C5.94967 18.1226 5.98938 18.0293 6.00955 17.9309C6.02972 17.8326 6.02993 17.7312 6.01018 17.6327C5.99042 17.5343 5.9511 17.4408 5.89455 17.3578C5.83799 17.2749 5.76534 17.2041 5.68092 17.1497C5.59651 17.0954 5.50204 17.0585 5.40311 17.0413C5.30418 17.0241 5.20281 17.027 5.10501 17.0498C2.78001 17.5148 0 18.4448 0 20.2498C0 22.8248 5.835 23.9998 11.25 23.9998C16.665 23.9998 22.505 22.8248 22.505 20.2498C22.505 18.4398 19.715 17.5098 17.375 17.0448Z" />
    </SvgIcon>
  );
};

export default FleetIcon;
