import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ParkingWrapper = styled(Box)(({ theme }) => ({
  'input + fieldset': {
    borderColor: '#A1A7BB',
  },
  ' fieldset': {
    borderColor: '#A1A7BB',
  },
  '&:hover fieldset': {
    borderColor: '#A1A7BB',
  },
  '.Mui-focused fieldset': {
    borderColor: '#A1A7BB',
  },
  button: {
    marginTop: theme.spacing(2),
    background: 'rgba(191, 193, 204, 0.1)',
    border: '1px dashed rgba(191, 193, 204, 0.3)',
    borderRadius: '10px',
    color: theme.palette.hint.main,
  },
  '.Mui-disabled': {
    fieldset: {
      borderColor: '#737373!important',
    },
    input: {
      '-webkit-text-fill-color': '#737373!important',
    },
  },
  '.inputs': {
    maxHeight: '230px',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      height: '1px',
      backgroundColor: '#2a2734',
      margin: '9.6px 0 9.6px 4px',
      width: '4px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#838383',
      borderRadius: '8px',
    },
  },
  '.deleteIcon': {
    position: 'relative',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
  },
}));
