import { useQuery } from '@tanstack/react-query';
import useCalculate from 'hooks/calculate.zustand';

import businessCaseService from 'services/businessCase.service';

export const useBusinessCaseSummary = () => {
  const calcData = useCalculate((state) => state.data);
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const query = useQuery(
    ['getBusinessCaseSummary'],
    () => businessCaseService.putBusinessCaseSummary(calcData),
    {
      enabled: false,
      onSuccess: () => makeRefetch(false),
    },
  );
  !query.data && query.refetch();
  return query;
};
export const usePdfReport = () => {
  const query = useQuery(['pdfReport'], () => businessCaseService.getPdfReport(), {
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  !query.data && query.refetch();
  return query;
};
export const useEvIceComparison = () => {
  const query = useQuery(['evIceComparisonGet'], () => businessCaseService.getEvIceComparison(), {
    enabled: false,
  });
  !query.data && query.refetch();
  return query;
};
export const usePDFOveralData = () => {
  const query = useQuery(['overalData'], () => businessCaseService.getMockData(), {
    enabled: false,
  });
  !query.data && query.refetch();
  return query;
};

export const useCo2Emissions = () => {
  const query = useQuery(['co2EmissionsGet'], () => businessCaseService.getCo2Emissions(), {
    enabled: false,
  });
  !query.data && query.refetch();
  return query;
};

export const useGetRoadmapInfra = () => {
  const query = useQuery(
    ['getRoadmapInfra'],
    () => businessCaseService.getRoadmapInfrastructure(),
    {
      enabled: false,
    },
  );
  !query.data && query.refetch();

  return query;
};

export const useGetRoadmap = () => {
  const query = useQuery(['getRoadmap'], () => businessCaseService.getRoadmapVehicles(), {
    enabled: false,
  });
  !query.data && query.refetch();

  return query;
};
