import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import styles from '../styles';

interface IChargeTranslation {
  firstHighlightList: string[];
  secondHighlightList: string[];
  thirdHighlightList: string[];
  firstHighlightTitle: string;
  secondHighlightTitle: string;
  thirdHighlightTitle: string;
}
const ChargeAtWork = () => {
  const { t } = useTranslation('report');
  const translations = t('chargeAtWork', {
    returnObjects: true,
  }) as IChargeTranslation;
  const summary = [
    {
      title: translations.firstHighlightTitle,
      labels: translations.firstHighlightList,
    },
    {
      title: translations.secondHighlightTitle,
      labels: translations.secondHighlightList,
    },
    {
      title: translations.thirdHighlightTitle,
      labels: translations.thirdHighlightList,
    },
  ];
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{t('chargeAtWork.headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{t('chargeAtWork.headerSubtitle')}</Text>
      </View>
      <View style={styles.highlightWrapper}>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtWork.firstHighlight')}</Text>
        </View>
        <Text style={styles.plus}>+</Text>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtWork.secondHighlight')}</Text>
        </View>
        <Text style={styles.plus}>+</Text>
        <View style={styles.highlight}>
          <Text style={styles.highlightText}>{t('chargeAtWork.thirdHighlight')}</Text>
        </View>
      </View>
      <View style={styles.summaryWrapper}>
        {summary.map(({ labels, title }) => (
          <View key={title} style={styles.summaryItem}>
            <Text style={styles.summaryTitle}>{title}</Text>
            {labels.map((val) => (
              <View key={val} style={styles.listViewHighlight}>
                <Text style={styles.listBulletHighlight}>•</Text>
                <Text style={styles.listTextHighlight}>{val}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </PageLayout>
  );
};
export default ChargeAtWork;
