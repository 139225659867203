import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const InfoIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M14.25 15.75H13.5C13.3011 15.75 13.1103 15.671 12.9697 15.5303C12.829 15.3897 12.75 15.1989 12.75 15V11.25C12.75 10.8522 12.592 10.4706 12.3107 10.1893C12.0293 9.90803 11.6478 9.75 11.25 9.75H10.5C10.3011 9.75 10.1103 9.82901 9.96967 9.96967C9.82901 10.1103 9.75 10.3011 9.75 10.5C9.75 10.6989 9.82901 10.8897 9.96967 11.0303C10.1103 11.171 10.3011 11.25 10.5 11.25H11.25V15C11.2513 15.5963 11.4888 16.1679 11.9105 16.5895C12.3321 17.0112 12.9037 17.2487 13.5 17.25H14.25C14.4489 17.25 14.6397 17.171 14.7803 17.0303C14.921 16.8897 15 16.6989 15 16.5C15 16.3011 14.921 16.1103 14.7803 15.9697C14.6397 15.829 14.4489 15.75 14.25 15.75Z" />
        <path d="M11.6299 8.25001C11.8524 8.25001 12.0699 8.18402 12.2549 8.0604C12.4399 7.93679 12.5841 7.76109 12.6692 7.55552C12.7544 7.34995 12.7767 7.12375 12.7333 6.90553C12.6899 6.6873 12.5827 6.48684 12.4254 6.32951C12.268 6.17217 12.0676 6.06502 11.8494 6.02162C11.6311 5.97821 11.4049 6.00049 11.1994 6.08564C10.9938 6.17079 10.8181 6.31497 10.6945 6.49998C10.5709 6.68498 10.5049 6.9025 10.5049 7.12501C10.5049 7.42338 10.6234 7.70953 10.8344 7.92051C11.0454 8.13149 11.3315 8.25001 11.6299 8.25001ZM11.6299 6.75001C11.7041 6.75001 11.7765 6.77201 11.8382 6.81321C11.8999 6.85442 11.948 6.91296 11.9763 6.98148C12.0047 7.05001 12.0121 7.12542 11.9977 7.19816C11.9832 7.2709 11.9475 7.33773 11.895 7.39018C11.8426 7.44262 11.7758 7.47834 11.703 7.49281C11.6303 7.50728 11.5549 7.49983 11.4864 7.47144C11.4179 7.44306 11.3593 7.39502 11.3181 7.33335C11.2769 7.27168 11.2549 7.19918 11.2549 7.12501C11.2562 7.02596 11.2961 6.93132 11.3661 6.86128C11.4362 6.79123 11.5308 6.7513 11.6299 6.75001Z" />
        <path d="M12 0C9.62663 0 7.30655 0.703792 5.33316 2.02237C3.35977 3.34095 1.8217 5.21509 0.913451 7.40781C0.00519943 9.60052 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6688 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51471C18.2348 1.26427 15.1826 0 12 0ZM12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0995 1.29661 11.9883 1.70176 9.95154C2.1069 7.91474 3.10693 6.04383 4.57539 4.57538C6.04384 3.10693 7.91475 2.1069 9.95155 1.70175C11.9884 1.29661 14.0996 1.50454 16.0182 2.29926C17.9368 3.09398 19.5767 4.43979 20.7304 6.1665C21.8842 7.89322 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default InfoIcon;
