import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleColumn: {
    flexGrow: 1,
  },
  packagesTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 6,
    marginBottom: 6,
  },
});

export default styles;
