import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: `${theme.spacing(6)} 0`,
  },
  betaWrapper: {
    position: 'absolute',
    right: '4%',
    top: '4%',
    zIndex: 20,
  },
  content: {
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    minHeight: '100vh',
    maxHeight: '120vh',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0 20px 20px 0',
    position: 'relative',
    left: '-1px',
  },
  sideWrapper: {
    height: '100vh',
    position: 'fixed',
    width: 'min-content',
    padding: `0 ${theme.spacing(2)} 0 0`,
    backgroundColor: theme.palette.common.white,
    '& .main': {
      width: '100px',
      display: 'flex',

      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      borderRadius: '0 20px 20px 0',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.common.white,
    },
  },
}));
export default useStyles;
