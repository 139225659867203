import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';

import BarChartSkeleton from 'components/pages/businessCase/reports/components/skeleton';
import { useStyles } from 'components/pages/businessCase/reports/styles';
import { mapChartData } from 'components/pages/initialCheck/sidebarWithChart';

interface IProps {
  data: any;
  isLoading: boolean;
  isDone?: () => void;
}

const Co2Emission = ({ data, isLoading, isDone }: IProps) => {
  const classes = useStyles();

  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('businessCaseReports');

  const d = new Date();
  let firstYear = d.getFullYear();
  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const pathX = Math.floor(x - payload.offset) + 0.5;
    if (payload.index % 12 == 0) {
      return (
        <>
          <path d={`M${pathX},${y + 22}v${-30}`} stroke="#666" />
        </>
      );
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={17} y={2} dy={16} textAnchor="end" fill="#666">
          {firstYear + Math.round(payload.index / 12) - 1}
        </text>
      </g>
    );
  };
  const renderTooltipText = (value: number, name: string) => {
    return [
      `${tCommon('formattedPrice', { value })} ${t('co2Emission.unit')}`,
      t(`co2Emission.${name}`),
    ];
  };
  const formatter = (value: string) =>
    `${tCommon('formattedPrice', { value })} ${t('co2Emission.unit')}`;
  const labelFormatter = (value: string) => {
    return `${tCommon('month')} ${+value + 1}/ ${((+value + 1) / 12).toFixed(1)} ${tCommon(
      'year',
    )}`;
  };
  const renderLegendText = (value: string) => (
    <span style={{ color: theme.palette.common.black, margin: '30px 6px 0px 6px' }}>
      {t(`co2Emission.${value}`)}
    </span>
  );
  return (
    <ResponsiveContainer width="100%" height="100%">
      {isLoading ? (
        <BarChartSkeleton />
      ) : (
        <LineChart
          className={classes.wrapper}
          width={500}
          height={300}
          data={mapChartData(data)}
          margin={{
            top: 50,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={5} tickLine={false} dataKey="name" tick={<CustomizedAxisTick />} />
          <YAxis tickLine={false} width={185} tickFormatter={formatter} />
          {/* @ts-ignore */}
          <Tooltip formatter={renderTooltipText} labelFormatter={labelFormatter} />
          <Legend
            wrapperStyle={{ marginLeft: '14%', paddingTop: '20px', width: 'auto' }}
            align="left"
            iconType="rect"
            formatter={renderLegendText}
          />
          <Line
            dot={false}
            type="linear"
            dataKey="mix"
            strokeWidth={2}
            stroke={theme.palette.chart.backendCosts}
            animationDuration={1}
            onAnimationEnd={() => {
              isDone?.();
            }}
          />
          <Line
            dot={false}
            type="linear"
            dataKey="ice"
            strokeWidth={2}
            stroke={theme.palette.chart.fleet}
            animationDuration={1}
            onAnimationEnd={() => {
              isDone?.();
            }}
          />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};
export default Co2Emission;
