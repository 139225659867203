import { useTranslation } from 'react-i18next';

interface IParams {
  [key: string]: any;
}

export const formatPrice = (price: number) => (price / 100).toFixed(2);
export const getTranslateFn = (i18nKey: string) => (params?: IParams) => {
  const { t } = useTranslation(i18nKey);
  return t(i18nKey, params || {});
};

export const getSvg = async (url: string, noFillReplace?: boolean) => {
  try {
    const response = await fetch(url);
    const responseText = await response.text();

    if (responseText.indexOf('<svg') === -1) {
      return;
    }

    return noFillReplace
      ? responseText
      : responseText.replace(/fill="#[\d, A-F, a-f]{6}"/g, 'fill="currentColor"');
  } catch (error) {
    return '';
  }
};
export const isNullish = (obj: Object) =>
  Object.values(obj).every((x) => {
    return x === null || x === '';
  });
