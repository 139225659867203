import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';
import {
  IParkingLocation,
  IPdfReports,
} from 'components/pages/businessCase/reports/components/pdfReport/types';

import { assumptionsImagesPath } from '../assumptionsAndReports';
import styles from '../styles';
import { TOptionsBase } from 'i18next';

interface IProps {
  pdfReport: IPdfReports;
}
const handleActiveStyle = (flag: boolean) => {
  return flag ? styles.primaryBorderColor : styles.deactiveBorderColor;
};
const FleetData = ({ pdfReport }: IProps) => {
  const { t } = useTranslation('report');
  const { t: tCommon } = useTranslation('common');

  const T = (
    key: string,
    option?: TOptionsBase & object & { locations: number; country: string },
  ) => t(`specifiedFleetData.${key}`, option!);
  const TCurrentFleet = (
    key: string,
    option?: TOptionsBase & object & { leasing: any; vehicle: number; ev: number },
  ) => t(`currentFleetDetail.${key}`, option!);

  const currency = pdfReport?.country?.CurrencySign || '€';
  return (
    <>
      <PageLayout>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
          <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
        </View>
        <View style={styles.fleet}>
          <View style={styles.fleetIcon}>
            <Image src={`${assumptionsImagesPath}/fleet-image.png`} />
          </View>
          <Text style={styles.fleetTitle}>{TCurrentFleet('headerTitle')}</Text>
          <Text style={styles.fleetDescription}>
            {TCurrentFleet('headerSubtitle', {
              leasing: TCurrentFleet(pdfReport?.acquiringType),
              vehicle: pdfReport?.totalCount,
              ev: pdfReport?.bevCount,
            })}
          </Text>

          <Text style={styles.summary}>
            <Text style={styles.boldValue}>{`${pdfReport?.dailyMileage.isDefault ? '*' : ''}${
              pdfReport?.dailyMileage.value
            } `}</Text>
            {TCurrentFleet('dailyKilometer')}
          </Text>

          <Text style={styles.summary}>
            <Text style={styles.boldValue}>{`${
              pdfReport?.operationDaysPerWeek.isDefault ? '*' : ''
            }${pdfReport?.operationDaysPerWeek.value} `}</Text>
            {TCurrentFleet('operatingDays')}
          </Text>
          <Text style={styles.summary}>
            {/*TODO !!!*/}
            <Text style={styles.boldValue}>{`${
              pdfReport?.monthlyLeasingCost
                ? pdfReport?.monthlyLeasingCost.isDefault
                  ? '*'
                  : ''
                : pdfReport?.monthlyPurchaseCost.isDefault
                ? '*'
                : ''
            }${
              pdfReport?.acquiringType === 'lease'
                ? tCommon('formattedPrice', { value: pdfReport?.monthlyLeasingCost.value })
                : tCommon('formattedPrice', { value: pdfReport?.monthlyPurchaseCost.value })
            } ${currency} `}</Text>

            {pdfReport?.acquiringType === 'lease'
              ? TCurrentFleet('leasingType')
              : TCurrentFleet('purchaseType')}
          </Text>
          <Text style={styles.summary}>
            <Text style={styles.boldValue}>{`${
              pdfReport?.currentFuelCosts.isDefault ? '*' : ''
            }${tCommon('formattedPrice', {
              value: pdfReport?.currentFuelCosts.value,
            })} ${currency} `}</Text>
            {TCurrentFleet('currentFuelCosts')}
          </Text>
          <Text style={styles.summary}>
            <Text style={styles.boldValue}>{`${
              pdfReport?.totalMaintenanceCost.isDefault ? '*' : ''
            }${tCommon('formattedPrice', {
              value: pdfReport?.totalMaintenanceCost.value,
            })} ${currency} `}</Text>
            {TCurrentFleet('maintenanceCosts')}
          </Text>
        </View>
      </PageLayout>
      <PageLayout>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{T('secondHeaderTitle')}</Text>
          <Text style={styles.headerSubtitle}>{T('secondHeaderSubTitle')}</Text>
        </View>
        <View style={styles.fleetWrapper}>
          <Text style={styles.headerTitle}>{T('mainTitle')}</Text>
          <Text style={styles.headerSubtitle}>
            {T('mainSubtitle', {
              locations: pdfReport?.sitesCount || 0,
              country: T(pdfReport?.country?.name),
            })}
          </Text>
        </View>
        <View style={styles.chargeWrapper}>
          <Text
            style={[
              styles.chargeBox,
              handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtWork),
            ]}
          >
            {pdfReport?.infrastructureDetails?.chargeAtWork && (
              <View style={styles.checked}>
                <Image src={`${assumptionsImagesPath}/checked.png`} />
              </View>
            )}{' '}
            <View style={styles.icon}>
              <Image
                src={
                  pdfReport?.infrastructureDetails?.chargeAtWork
                    ? `${assumptionsImagesPath}/chargeWorkColored.png`
                    : `${assumptionsImagesPath}/chargeWorkGray.png`
                }
              />
            </View>{' '}
            <Text
              style={[
                styles.chargeBoxTitle,
                handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtWork),
              ]}
            >
              charge@work
            </Text>
          </Text>
          <Text
            style={[
              styles.chargeBox,
              handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtHome),
            ]}
          >
            {pdfReport?.infrastructureDetails?.chargeAtHome && (
              <View style={styles.checked}>
                <Image src={`${assumptionsImagesPath}/checked.png`} />
              </View>
            )}{' '}
            <View style={styles.icon}>
              <Image
                src={
                  pdfReport?.infrastructureDetails?.chargeAtHome
                    ? `${assumptionsImagesPath}/chargeHomeColored.png`
                    : `${assumptionsImagesPath}/chargeHomeGray.png`
                }
              />
            </View>{' '}
            <Text
              style={[
                styles.chargeBoxTitle,
                handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtHome),
              ]}
            >
              charge@home
            </Text>
          </Text>
          <Text
            style={[
              styles.chargeBox,
              handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtPublic),
            ]}
          >
            {pdfReport?.infrastructureDetails?.chargeAtPublic && (
              <View style={styles.checked}>
                <Image src={`${assumptionsImagesPath}/checked.png`} />
              </View>
            )}{' '}
            <View style={styles.icon}>
              <Image
                src={
                  pdfReport?.infrastructureDetails?.chargeAtPublic
                    ? `${assumptionsImagesPath}/chargePublicColored.png`
                    : `${assumptionsImagesPath}/chargePublicGray.png`
                }
              />
            </View>{' '}
            <Text
              style={[
                styles.chargeBoxTitle,
                handleActiveStyle(pdfReport?.infrastructureDetails?.chargeAtPublic),
              ]}
            >
              charge@public
            </Text>
          </Text>
        </View>
        <View style={styles.chargeSummary}>
          {pdfReport?.infrastructureDetails?.parkingLocations?.map(
            (
              { energyTariff, energyTariffUnit, parkingSpaces, postalCode }: IParkingLocation,
              index: number,
            ) => (
              <View style={styles.summaryRow} key={`parkinglocation-${index}`}>
                <Text style={[styles.SummaryTitle, styles.summaryDesc]}>{`Standort ${
                  index + 1
                }`}</Text>
                <Text style={styles.summaryDesc}>
                  {parkingSpaces}
                  <Text style={styles.summaryUnit}> {T('parkingSpaces')}</Text>
                </Text>
                <Text style={styles.summaryDesc}>
                  {postalCode}
                  <Text style={styles.summaryUnit}> {T('postalCode')}</Text>
                </Text>
                <Text style={styles.summaryDesc}>
                  {`${energyTariff.isDefault ? '*' : ''}${tCommon('floorPrice', {
                    value: energyTariff.value,
                  })} ${energyTariffUnit}`}
                  <Text style={styles.summaryUnit}> {T('energyRate')}</Text>
                </Text>
              </View>
            ),
          )}
        </View>
      </PageLayout>
    </>
  );
};
export default FleetData;
