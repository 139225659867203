import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';

import BarChartSkeleton from 'components/pages/businessCase/reports/components/skeleton';
import { useStyles } from 'components/pages/businessCase/reports/styles';

interface IProps {
  currency: string;
  isLoading: boolean;
  data: any;
  isDone?: () => void;
}
const EVICELineChart = ({ currency, data, isLoading, isDone }: IProps) => {
  const { t: tCommon } = useTranslation('common');
  const classes = useStyles();
  const { t } = useTranslation('businessCaseReports');

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={17} y={0} dy={16} textAnchor="end" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };
  const renderTooltipText = (value: number, name: string) => {
    return [`${tCommon('formattedPrice', { value })}`, t(`costEVICE.${name}`)];
  };
  const formatter = (value: string) => `${tCommon('formattedPrice', { value })} ${currency}`;
  const labelFormatter = (value: string) => {
    return `${value} ${tCommon('year')}`;
  };

  const renderLegendText = (value: string) => (
    <span style={{ color: theme.palette.common.black, margin: '30px 6px 0px 6px' }}>
      {t(`costEVICE.${value}`)}
    </span>
  );

  return (
    <>
      {isLoading ? (
        <BarChartSkeleton />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            className={classes.wrapper}
            width={500}
            height={270}
            data={data}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickLine={false} dataKey="year" tick={<CustomizedAxisTick />} />
            <YAxis tickLine={false} width={185} tickFormatter={formatter} />
            {/* @ts-ignore */}
            <Tooltip formatter={renderTooltipText} labelFormatter={labelFormatter} />
            <Legend
              wrapperStyle={{
                marginLeft: '14%',
                paddingTop: '20px',
                width: 'auto',
              }}
              align="left"
              iconType="rect"
              formatter={renderLegendText}
            />
            <Line
              dot={false}
              type="linear"
              dataKey="iceTotal"
              strokeWidth={2}
              stroke={theme.palette.chart.energyFuel}
              animationDuration={1}
              onAnimationEnd={() => {
                isDone?.();
              }}
            />
            <Line
              dot={false}
              type="linear"
              dataKey="mixTotal"
              strokeWidth={2}
              animationDuration={1}
              stroke={theme.palette.chart.backendCosts}
              onAnimationEnd={() => isDone?.()}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
export default EVICELineChart;
