import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogWrapper = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    backgroundColor: theme.palette.text.primary,
    color: '#fff',
    p: {
      color: theme.palette.hint.main,
    },
  },
  input: {
    color: '#fff!important',
  },
  'input + fieldset': {
    borderColor: '#A1A7BB',
  },
  ' fieldset': {
    borderColor: '#A1A7BB',
  },
  '&:hover fieldset': {
    borderColor: '#A1A7BB',
  },
  '.Mui-focused fieldset': {
    borderColor: '#A1A7BB',
  },
  '.MuiFormControlLabel-root': {
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.hint.main}`,
    padding: `0 ${theme.spacing(1)}`,
    borderRadius: '8px',
  },
  '.MuiFormControlLabel-label': {
    marginTop: '0!important',
  },
  '.selected': {
    backgroundColor: theme.palette.primary.main,
    '.MuiTypography-root': {
      color: '#000',
    },
    '.MuiRadio-root': {
      color: '#000',
    },
  },
  '.Mui-selected': {
    background: theme.palette.primary.main,
    '.MuiTypography-root': {
      color: '#000!important',
    },
  },
}));
