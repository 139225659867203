import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const Sheet = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M19.2481 3.75221H16.1834C16.0068 2.70321 15.4641 1.75075 14.6519 1.06385C13.8396 0.376943 12.8102 0 11.7464 0C10.6827 0 9.65328 0.376943 8.841 1.06385C8.02873 1.75075 7.4861 2.70321 7.30942 3.75221H4.24975C3.65308 3.75221 3.08085 3.98923 2.65894 4.41114C2.23703 4.83305 2 5.4053 2 6.00197V21.7502C2 22.3469 2.23703 22.9192 2.65894 23.3411C3.08085 23.763 3.65308 24 4.24975 24H19.2481C19.8444 23.9987 20.4158 23.7612 20.8375 23.3396C21.2591 22.918 21.4965 22.3465 21.4979 21.7502V6.00197C21.4965 5.4057 21.2591 4.83423 20.8375 4.4126C20.4158 3.99098 19.8444 3.75354 19.2481 3.75221ZM19.998 21.7502C19.998 21.9491 19.919 22.1399 19.7784 22.2805C19.6377 22.4212 19.447 22.5002 19.2481 22.5002H4.24975C4.05086 22.5002 3.86012 22.4212 3.71948 22.2805C3.57884 22.1399 3.49984 21.9491 3.49984 21.7502V6.00197C3.49984 5.80308 3.57884 5.61233 3.71948 5.47169C3.86012 5.33106 4.05086 5.25205 4.24975 5.25205H7.99934C8.19823 5.25205 8.38898 5.17305 8.52962 5.03241C8.67025 4.89177 8.74926 4.70102 8.74926 4.50213C8.74926 3.70657 9.0653 2.94357 9.62784 2.38103C10.1904 1.81848 10.9534 1.50246 11.7489 1.50246C12.5445 1.50246 13.3075 1.81848 13.87 2.38103C14.4326 2.94357 14.7486 3.70657 14.7486 4.50213C14.7486 4.70102 14.8276 4.89177 14.9683 5.03241C15.1089 5.17305 15.2996 5.25205 15.4985 5.25205H19.2481C19.447 5.25205 19.6377 5.33106 19.7784 5.47169C19.919 5.61233 19.998 5.80308 19.998 6.00197V21.7502Z" />
        <path d="M11.7489 5.25206C11.9714 5.25206 12.1889 5.18611 12.3738 5.0625C12.5588 4.9389 12.703 4.76322 12.7882 4.55768C12.8733 4.35213 12.8956 4.12593 12.8522 3.90772C12.8088 3.68952 12.7016 3.48911 12.5443 3.3318C12.387 3.17448 12.1866 3.06731 11.9684 3.02391C11.7501 2.98051 11.524 3.00279 11.3184 3.08793C11.1129 3.17307 10.9372 3.31727 10.8136 3.50225C10.69 3.68724 10.624 3.9047 10.624 4.12718C10.624 4.42552 10.7425 4.71161 10.9535 4.92257C11.1644 5.13352 11.4506 5.25206 11.7489 5.25206ZM11.7489 3.75222C11.8231 3.75222 11.8956 3.77419 11.9572 3.81539C12.0189 3.85659 12.0669 3.91519 12.0953 3.9837C12.1237 4.05222 12.1311 4.12762 12.1167 4.20036C12.1022 4.27309 12.0665 4.33985 12.014 4.39229C11.9616 4.44473 11.8948 4.48047 11.8221 4.49494C11.7493 4.50941 11.6739 4.50196 11.6054 4.47358C11.5369 4.4452 11.4783 4.39713 11.4371 4.33547C11.3959 4.27381 11.3739 4.20134 11.3739 4.12718C11.3752 4.02814 11.4152 3.93352 11.4852 3.86348C11.5552 3.79344 11.6499 3.75352 11.7489 3.75222Z" />
        <path d="M6.49943 11.2514H11.7489C11.9477 11.2514 12.1385 11.1724 12.2791 11.0318C12.4198 10.8911 12.4988 10.7004 12.4988 10.5015C12.4988 10.3026 12.4198 10.1118 12.2791 9.9712C12.1385 9.83056 11.9477 9.75156 11.7489 9.75156H6.49943C6.30054 9.75156 6.10979 9.83056 5.96916 9.9712C5.82852 10.1118 5.74951 10.3026 5.74951 10.5015C5.74951 10.7004 5.82852 10.8911 5.96916 11.0318C6.10979 11.1724 6.30054 11.2514 6.49943 11.2514Z" />
        <path d="M16.9982 9.75156H14.7485C14.5496 9.75156 14.3588 9.83056 14.2182 9.9712C14.0775 10.1118 13.9985 10.3026 13.9985 10.5015C13.9985 10.7004 14.0775 10.8911 14.2182 11.0318C14.3588 11.1724 14.5496 11.2514 14.7485 11.2514H16.9982C17.1971 11.2514 17.3878 11.1724 17.5285 11.0318C17.6691 10.8911 17.7481 10.7004 17.7481 10.5015C17.7481 10.3026 17.6691 10.1118 17.5285 9.9712C17.3878 9.83056 17.1971 9.75156 16.9982 9.75156Z" />
        <path d="M16.9984 13.4761H11.7489C11.5501 13.4761 11.3593 13.5551 11.2187 13.6958C11.078 13.8364 10.999 14.0272 10.999 14.226C10.999 14.4249 11.078 14.6157 11.2187 14.7563C11.3593 14.897 11.5501 14.976 11.7489 14.976H16.9984C17.1973 14.976 17.388 14.897 17.5286 14.7563C17.6693 14.6157 17.7483 14.4249 17.7483 14.226C17.7483 14.0272 17.6693 13.8364 17.5286 13.6958C17.388 13.5551 17.1973 13.4761 16.9984 13.4761Z" />
        <path d="M6.49943 14.976H8.74918C8.94807 14.976 9.13882 14.897 9.27946 14.7563C9.42009 14.6157 9.4991 14.4249 9.4991 14.226C9.4991 14.0272 9.42009 13.8364 9.27946 13.6958C9.13882 13.5551 8.94807 13.4761 8.74918 13.4761H6.49943C6.30054 13.4761 6.10979 13.5551 5.96916 13.6958C5.82852 13.8364 5.74951 14.0272 5.74951 14.226C5.74951 14.4249 5.82852 14.6157 5.96916 14.7563C6.10979 14.897 6.30054 14.976 6.49943 14.976Z" />
        <path d="M11.7489 17.2507H6.49943C6.30054 17.2507 6.10979 17.3297 5.96916 17.4704C5.82852 17.611 5.74951 17.8018 5.74951 18.0007C5.74951 18.1995 5.82852 18.3903 5.96916 18.5309C6.10979 18.6716 6.30054 18.7506 6.49943 18.7506H11.7489C11.9477 18.7506 12.1385 18.6716 12.2791 18.5309C12.4198 18.3903 12.4988 18.1995 12.4988 18.0007C12.4988 17.8018 12.4198 17.611 12.2791 17.4704C12.1385 17.3297 11.9477 17.2507 11.7489 17.2507Z" />
        <path d="M16.9982 17.2507H14.7485C14.5496 17.2507 14.3588 17.3297 14.2182 17.4704C14.0775 17.611 13.9985 17.8018 13.9985 18.0007C13.9985 18.1995 14.0775 18.3903 14.2182 18.5309C14.3588 18.6716 14.5496 18.7506 14.7485 18.7506H16.9982C17.1971 18.7506 17.3878 18.6716 17.5285 18.5309C17.6691 18.3903 17.7481 18.1995 17.7481 18.0007C17.7481 17.8018 17.6691 17.611 17.5285 17.4704C17.3878 17.3297 17.1971 17.2507 16.9982 17.2507Z" />
      </g>
    </SvgIcon>
  );
};

export default Sheet;
