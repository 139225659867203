import React from 'react';
import { Grid, GridSize } from '@mui/material';

import CookieConsent from 'components/cookieConsent/cookieConsent';

import useStyles from './styles';

export interface ISteps {
  url?: string;
  label: string;
}

export interface ILayoutWithSidebar {
  children: React.ReactNode;
  sidebarContent: React.ReactNode;
  secondSidebarContent?: React.ReactNode;
  sideWidth?: GridSize;
}

const LayoutWithSidebar = ({
  children,
  sidebarContent,
  sideWidth = 4,
  secondSidebarContent,
}: ILayoutWithSidebar) => {
  // @ts-ignore
  const width = (sideWidth / 12) * 100;
  const classes = useStyles({ width });
  const secondSideWidth = 3.25;
  return (
    <>
      <Grid container>
        <Grid item xs={sideWidth!} sx={{ backgroundColor: '#fff' }}>
          <div className={classes.sideWrapper}>
            <div className="main">{sidebarContent}</div>
          </div>
        </Grid>
        {/*  @ts-ignore*/}
        <Grid className={classes.content} item xs={12 - sideWidth - secondSideWidth}>
          {children}
        </Grid>
        {secondSidebarContent && (
          <Grid
            item
            sm={secondSideWidth}
            md={secondSideWidth}
            lg={secondSideWidth}
            sx={{ background: 'white' }}
          >
            {secondSidebarContent}
          </Grid>
        )}
      </Grid>
      <CookieConsent />
    </>
  );
};

export default LayoutWithSidebar;
