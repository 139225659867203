import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import styles from '../styles';

const AdditionalAnalysis = () => {
  const { t } = useTranslation('report');
  const summary = [
    {
      title: t('ourAnalysis.list1Title'),
      subtitle: t('ourAnalysis.list1Subtitle'),
      icon: '/img/business-case/reports/ourSolution/reportComputer.png',
      labels: [
        { label: t('ourAnalysis.list1Item1'), nested: false },
        { label: t('ourAnalysis.list1Item2'), nested: false },
        { label: t('ourAnalysis.list1Item3'), nested: false },
        { label: t('ourAnalysis.list1Item4'), nested: false },
        { label: t('ourAnalysis.list1Item5'), nested: false },
        { label: t('ourAnalysis.list1Item6'), nested: false },
      ],
    },

    {
      title: t('ourAnalysis.list2Title'),
      subtitle: t('ourAnalysis.list2Subtitle'),
      icon: '/img/business-case/reports/ourSolution/techConsultant.png',
      labels: [
        { label: t('ourAnalysis.list2Item1'), nested: false },
        { label: t('ourAnalysis.list2Item2'), nested: true },
        { label: t('ourAnalysis.list2Item3'), nested: false },
        { label: t('ourAnalysis.list2Item4'), nested: true },
        { label: t('ourAnalysis.list2Item5'), nested: true },
        { label: t('ourAnalysis.list2Item6'), nested: true },
        { label: t('ourAnalysis.list2Item7'), nested: true },
      ],
    },
  ];
  const handleNested = (flag: boolean) => {
    return flag ? styles.nested : styles.notNested;
  };
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{t('ourAnalysis.headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{t('ourAnalysis.headerSubtitle')}</Text>
      </View>
      <View style={styles.prosWrapper}>
        {summary.map(({ labels, title, icon, subtitle }) => (
          <View key={title} style={styles.prosItem}>
            <View style={styles.headerWithIcon}>
              <View style={styles.headerIcon}>
                <Image src={icon} />
              </View>

              <Text style={styles.headerItemText}>{title}</Text>
            </View>
            <Text style={styles.listWrapper}>{subtitle}</Text>
            {labels.map(({ label, nested }) => (
              <View key={label} style={[styles.listViewHighlight, handleNested(nested)]}>
                <Text style={styles.listBulletHighlight}>•</Text>
                <Text style={styles.listTextPros}>{label}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </PageLayout>
  );
};
export default AdditionalAnalysis;
