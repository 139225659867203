/* eslint-disable no-unused-vars */
// import { NotificationsStore } from '@inno2grid/ui';

import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from 'constants/user';
import { toast } from 'react-toastify';

export enum EMethod {
  post = 'POST',
  get = 'GET',
  delete = 'DELETE',
  put = 'PUT',
  patch = 'PATCH',
}

export interface IHttpConfig {
  apiURL?: string;
  headers?: any;
  credentials?: 'include' | 'omit' | 'same-origin';
}

export interface IRequestParams {
  url: string;
  headers?: any;
  method?: EMethod;
  data?: any;
  params?: any;
  query?: any;
  auth?: boolean;
  directUrl?: boolean;
}

interface IQueryObjectValue {
  [key: string]: string[] | Date[] | Date | string | null | undefined;
}

interface IQueryObject {
  [key: string]:
    | IQueryObjectValue
    | IQueryObjectValue[]
    | string[]
    | number[]
    | number
    | Date[]
    | Date
    | string
    | null
    | undefined
    | boolean;
}

type TPairsValue = string | number | Date | boolean | undefined | null;
type TPair = [string[], TPairsValue];

export function getPairs(data: IQueryObject, keys: string[] = []) {
  return Object.entries(data).reduce((pairs: TPair[], [key, value]) => {
    if (Array.isArray(value)) {
      for (const element of value) {
        typeof element === 'object'
          ? pairs.push(...getPairs(element as IQueryObjectValue, [...keys, key, '']))
          : pairs.push([[...keys, key, ''], element]);
      }
    } else if (typeof value === 'object') {
      pairs.push(...getPairs(value as IQueryObjectValue, [...keys, key]));
    } else {
      pairs.push([[...keys, key], value]);
    }
    return pairs;
  }, []);
}

export function createQueryPairs(data: IQueryObject) {
  const pairs = getPairs(data);

  return pairs
    .map(
      ([[key0, ...keysRest], value]) =>
        `${key0}${keysRest.map((a: string) => `[${a}]`).join('')}=${value}`,
    )
    .join('&');
}

const disableDefNotifications = ['/auth/refresh', '/auth/profile/email/verify', '/auth/reset'];

class HttpService {
  config: IHttpConfig = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  };

  constructor(params: IHttpConfig) {
    this.config = { ...this.config, ...params };
  }

  set baseUrl(url: string) {
    this.config.apiURL = url;
  }

  getURL = (url: string, direct = false) => {
    return new URL(!direct ? `${this.config.apiURL}/${url}` : url);
  };

  getHeaders = (headers: any, auth?: boolean) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const lang = localStorage.getItem('i18nextLng');
    const isFormData = headers?.['Content-Type'] === 'multipart/form-data';
    const Headers = isFormData ? { 'x-custom-lang': lang } : { ...headers, 'x-custom-lang': lang };
    const headersObj =
      token && auth ? { ...Headers, Authorization: `Bearer ${token}` } : { ...Headers };
    return isFormData ? headersObj : Object.assign({}, this.config.headers, headersObj);
  };

  checkHttpStatus = async (response: any) => {
    if (response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
      window.location.href = '/';
      return;
    }
    if (!response.ok) {
      throw await response.json();
    }

    return response;
  };

  handleError(error: any) {
    if (error) {
      let message;
      if (Array.isArray(error.message)) {
        message = error.message.join(',');
      } else {
        message = error.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // TODO
      // return NotificationsStore.error(message || error.message || '');
    }
  }

  request = async (params: IRequestParams) => {
    const isFormData = params?.headers?.['Content-Type'] === 'multipart/form-data';
    const headers = this.getHeaders(params.headers, params.auth);
    const url = this.getURL(params.url, params.directUrl);
    if (params.params) {
      url.search = createQueryPairs(params.params);
    }
    const requestParams = {
      ...this.config,
      headers,
      method: params.method,
      body: isFormData ? params.data : JSON.stringify(params.data),
    };
    try {
      const fetchResponse = await fetch(url.toString(), requestParams);
      const responseOk = await this.checkHttpStatus(fetchResponse);

      const text = responseOk && (await responseOk.text());

      return text ? JSON.parse(text) : {};
    } catch (error: any) {
      if (!disableDefNotifications.filter((urlPart) => url.toString().includes(urlPart)).length) {
        this.handleError(error);
      }
      const { message = '', ...rest } = error;
      throw { ...rest, message: Array.isArray(message) ? message[0] : message };
    }
  };

  post = (url: string, data?: any, auth: boolean = true, headers?: any) => {
    return this.request({ url, data, auth, headers, method: EMethod.post });
  };

  put = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: EMethod.put });
  };

  patch = (url: string, data?: any, auth: boolean = true, headers?: any) => {
    return this.request({ url, data, auth, headers, method: EMethod.patch });
  };

  get = (url: string, params?: any, auth: boolean = true) => {
    return this.request({ url, params, auth, method: EMethod.get });
  };

  delete = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: EMethod.delete });
  };
}

export default HttpService;
