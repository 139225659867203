import { FormProvider, useForm } from 'react-hook-form';
import { Box, TextField, Typography, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { useDetectView } from 'hooks/resposive.hook';
import initiCheckService from 'services/intialCheck.service';

import Dialog from '../../dialog/dialog';

import { useStyles } from './style';

export interface IMobileEmail {
  email: string;
}
const MobileModal = () => {
  const { isMobile } = useDetectView();
  const {
    mutateAsync: sentMobileEmail,
    isLoading: sentMobileEmailIsLoading,
    isSuccess,
  } = useMutation(['mobileEmailSend'], initiCheckService.mobileEmailSend);
  const methods = useForm();
  const { register, handleSubmit } = methods;
  const onSubmit = async (data: IMobileEmail) => {
    await sentMobileEmail(data);
  };
  const classes = useStyles();
  return (
    <Dialog maxWidth="xl" fullScreen={true} open={isMobile}>
      <div className={classes.main}>
        <img src="/img/inno2fleet-logo-pdf.png" alt="logo" width="175px" />
        <Box mt={2}>
          <Typography variant="h4">eConsultant auf dem Smartphone</Typography>
        </Box>
        {isSuccess ? (
          <Box>
            <Box my={2}>
              <img width="100px" src="/img/computer.png" alt="responsive" />
            </Box>
            <Typography variant="h4">Danke</Typography>
            <Box textAlign="left" mt={3}>
              <Typography>
                Sie haben soeben eine E-Mail erhalten, mit der Sie auf Ihrem Desktop auf unser
                Elektrifizierungstool zugreifen können.
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box mt={3} textAlign="left">
              <Typography variant="body1">
                Flottenelektrifizierung lässt sich noch nicht mit wenigen Klicks auf dem
                Mobiltelefon erledigen. Das gilt auch für unsere digitale Beratung.
              </Typography>
              <Box mt={2}>
                <Typography variant="body1">
                  Lassen Sie sich den Link zum eConsultant unkompliziert in Ihren Posteingang
                  schicken. So können Sie das volle Potenzial Ihrer Flotte ausschöpfen und auf Ihrem
                  Desktop Ihre ideale Flottenelektrifizierung planen.
                </Typography>
              </Box>
            </Box>
            <Box my={3}>
              <FormProvider {...methods}>
                {/* @ts-ignore */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    className={classes.input}
                    label="E-Mail"
                    variant="outlined"
                    type="email"
                    {...register('email', { required: 'Bitte geben Sie Ihre E-Mail Adresse ein' })}
                  />
                  <Box mt={2}>
                    <Button
                      className={classes.button}
                      type="submit"
                      disabled={sentMobileEmailIsLoading}
                    >
                      Link erhalten
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          </>
        )}
        <>
          <Box mt={5} textAlign="left">
            <Typography>
              Wollen Sie mehr über inno2fleet erfahren? Hier finden Sie alle unsere Leistungen zum
              Thema Flottenelektrifizierung.
            </Typography>
          </Box>
          <Box mt={4}>
            <a href="https://www.inno2fleet.com/">
              <Button className={classes.button} variant="outlined">
                Gehe zu inno2fleet
              </Button>
            </a>
          </Box>
        </>
      </div>
    </Dialog>
  );
};
export default MobileModal;
