import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>(() => ({
  modalWrapper: {
    height: '100vh',
    width: '100vw',
  },
  main: {
    textAlign: 'center',
  },
  input: {
    '& input': {
      width: '220px',
      height: '2.2rem',
    },
  },
  button: {
    width: '248px',
  },
}));
