import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  //TODO
  header: {
    paddingLeft: '8px',
    borderLeft: '4px solid #60F4D9',
  },
  listWrapper: {
    marginBottom: '20px',
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    zIndex: -1,
  },
  logo: {
    width: 98,
  },
  headerItemText: {
    marginBottom: '25px',
    fontSize: '12px',
    lineHeight: '1.5px',
  },
  headerIconWrapper: {
    padding: '5px',
  },
  wrapper: {
    fontFamily: 'Inter',
    margin: '30px 70px',
  },
  headerDesc: {
    marginBottom: '25px',
    fontSize: '12px',
  },
  nested: {
    marginLeft: '15px',
  },
  notNested: {
    marginLeft: '0px',
  },
  headerTitle: {
    fontSize: '24px',
    fontFamily: 'Oxanium',
    fontWeight: 'bold',
  },

  headerSubtitle: {
    fontSize: '18px',
    fontWeight: 'light',
    fontFamily: 'Inter',
    color: '#5F6380',
    zIndex: 1,
  },
  steps: {
    position: 'relative',
    padding: '120px 0',
  },
  stepsIcon: {
    width: '28px',
    height: '28px',
    marginLeft: '10px',
  },
  stepsTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepsHead: {
    fontSize: '12px',
  },
  listView: {
    margin: '1px 4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  listViewHighlight: {
    margin: '1px 4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  listBullet: {
    fontSize: 10,
    color: '#4DC3AE',
    marginRight: '3px',
    marginTop: -2,
  },
  listBulletHighlight: {
    fontSize: 10,
    color: '#4DC3AE',
    marginRight: '3px',
    marginTop: -2,
  },

  listTextHighlight: {
    fontSize: '10px',
    color: '#5F6380',
    lineHeight: '1.2px',
  },
  listTextPros: {
    fontSize: '8px',
    color: '#5F6380',
    lineHeight: '1.4px',
  },
  listText: {
    fontSize: '8px',
    color: '#5F6380',
  },
  stepNumber: {
    width: '30px',
    height: '30px',
    paddingTop: '8px',
    paddingLeft: '12px',
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  stepLineTitle: {
    fontSize: '12px',
    marginLeft: '10px',
  },
  stepLineWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  technicalConcept: {
    position: 'absolute',
    top: '19%',
    left: '12%',
  },
  elecConsulting: {
    position: 'absolute',
    bottom: '5%',
    left: '-4%',
  },
  homePublic: {
    position: 'absolute',
    bottom: '7%',
    left: '39%',
  },
  optimization: {
    position: 'absolute',
    bottom: '22%',
    left: '75%',
  },
  serviceAndOperations: {
    position: 'absolute',
    top: '17%',
    left: '50%',
  },
  firstStepColor: {
    backgroundColor: '#E5FCF7',
  },
  secondStepColor: {
    backgroundColor: '#B3F6E8',
  },
  thirdStepColor: {
    backgroundColor: '#6EEAC8',
  },
  forthStepColor: {
    backgroundColor: '#99ABF9',
    color: '#fff',
  },
  fifthStepColor: {
    backgroundColor: '#1436EE',
    color: '#fff',
  },

  marginBottom: {
    marginBottom: '35px',
  },
  marginTop: {
    marginTop: '25px',
  },
  forthTopMargin: {
    marginTop: '10px',
  },
  separator: {
    height: 2,
    width: '40%',
    margin: '5px 5px 0',
    borderRadius: 4,
  },
  highlight: {
    width: '28%',
    padding: '8px 16px',
    backgroundColor: '#BFFBF0',
    borderRadius: '15px',
    textAlign: 'center',
  },
  highlightText: {
    fontSize: '12px',
  },
  plus: {
    fontSize: '21px',
    padding: '10px 20px',
  },
  highlightWrapper: {
    margin: '0 10px',
    padding: '25px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  summaryWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItem: 'center',
    padding: '20px 0',
    alignItems: 'center',
  },
  prosWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',

    padding: '20px 0',
  },
  fleetWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    flexWrap: 'wrap',
    padding: '20px 0',
  },
  summaryItem: {
    minHeight: '250xpx',
    borderRadius: '8px',
    margin: '9px',
    border: '1px solid #eaeaea',
    padding: '14px',
    width: '32%',
  },
  summaryTitle: {
    marginBottom: '25px',
    fontSize: '12px',
    lineHeight: '1.5px',
  },
  summaryListWrapper: {
    minHeight: '300px',
    borderRadius: '8px',
    margin: '9px',
    marginLeft: '90px',
    border: '1px solid #eaeaea',
    padding: '14px',
    width: '28%',
  },
  prosItem: {
    margin: '9px',
    padding: '12px 8px',
    width: '50%',
  },
  fleetItem: {
    margin: '9px',
    padding: '12px 15px',
    flexGrow: 0,
    maxWidth: '45%',
    flexBasis: '45%',
  },
  card: {
    width: '200px',
  },
  cardDesc: {
    width: '170',
    textAlign: 'center',
    fontSize: '8px',
    marginLeft: '13px',
  },
  cardBg: {
    position: 'absolute',
    width: '600px',
    height: 'auto',
    right: '0',
    top: '-75%',
    zIndex: -1,
  },
  cardWrapper: {
    position: 'relative',
  },
  publicStore: {
    margin: '40px 90px',
    backgroundColor: '#BFFBF0',
    width: '188px',
    padding: '7px 15px',
    textAlign: 'center',
    borderRadius: '15px',
    fontSize: '12px',
  },
  headerWithIcon: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerIcon: {
    width: '28px',
    height: '28px',
    marginRight: '8px',
  },
});
export default styles;
