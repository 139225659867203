import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';
import { IPdfReports } from 'components/pages/businessCase/reports/components/pdfReport/types';

import { assumptionsImagesPath } from '../assumptionsAndReports';
import styles from '../styles';
import { TOptionsBase } from 'i18next';

interface IProps {
  pdfReport: IPdfReports;
}

const InvestmentCosts = ({ pdfReport }: IProps) => {
  const { t } = useTranslation('report');
  const { t: tCommon } = useTranslation('common');
  const T = (key: string, option?: TOptionsBase & object & { place: string }) =>
    t(`investmentCosts.${key}`, option!);

  const currency = pdfReport?.country?.CurrencySign || '€';
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
      </View>
      <View>
        <Text style={styles.totalCostDesc}>
          <Text style={styles.totalCostTitle}>{T('descHeaderTitle')}</Text>
          {T('descHeaderSubtitle')}
        </Text>
      </View>
      <View style={styles.chargeDescWrapper}>
        <View>
          <Image
            style={styles.investmentCostBg}
            src={`${assumptionsImagesPath}/totalCostOverview.png`}
          />
        </View>

        <View style={styles.chargeAtWork}>
          {pdfReport?.workChargingStations ? (
            <>
              <View style={styles.chargeAtWorkHeader}>
                <Image
                  src={`${assumptionsImagesPath}/chargeWorkBlack.png`}
                  style={styles.fleetIcon}
                />
                <View>
                  <Text>charge@work</Text>
                </View>
              </View>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.title', { place: '@work' })}</Text>
                <Text style={styles.chargeAtWorkValues}>{pdfReport?.workChargingStations}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.sub')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtWork.capexInfraChargePoint,
                })} ${currency}`}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.desc')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`ca.${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtWork.capexInfraChargePointInstallation,
                })} ${currency}`}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailLastRow}>
                <Text>{T('chargeAt.sum')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtWork.totalInfraCost,
                })} ${currency}`}</Text>
              </View>
            </>
          ) : (
            <Text>You have not choose this plan.</Text>
          )}
        </View>
        <View style={styles.chargeAtHome}>
          <View style={styles.chargeAtWorkHeader}>
            <Image src={`${assumptionsImagesPath}/chargeHomeBlack.png`} style={styles.fleetIcon} />
            <View>
              <Text>charge@home</Text>
            </View>
          </View>
          {pdfReport?.homeWallboxes ? (
            <>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.title', { place: '@home' })} </Text>
                <Text style={styles.chargeAtWorkValues}>{pdfReport?.homeWallboxes}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.sub')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtHome.capexInfraChargePoint,
                })} ${currency}`}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailRow}>
                <Text>{T('chargeAt.desc')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`ca.${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtHome.capexInfraChargePointInstallation,
                })}  ${currency}`}</Text>
              </View>
              <View style={styles.chargeAtWorkDetailLastRow}>
                <Text>{T('chargeAt.sum')}</Text>
                <Text style={styles.chargeAtWorkValues}>{`${tCommon('formattedPrice', {
                  value: pdfReport.capexChargingInfraAtHome.totalInfraCost,
                })}  ${currency}`}</Text>
              </View>
            </>
          ) : (
            <Text>You have not choose this plan.</Text>
          )}
        </View>
        <View style={styles.chargeAtWorkDesc}>
          <Text style={{ fontWeight: 'normal' }}>
            <Text style={styles.bold}>{T('rightSideTopTitle')}</Text>
            {T('rightSideTopSubtitle')}
          </Text>
        </View>

        <View style={styles.chargeAtHomeDesc}>
          <Text style={{ fontWeight: 'normal' }}>
            <Text style={styles.bold}>{T('rightSideBottomTitle')}</Text>
            {T('rightSideBottomSubtitle')}
          </Text>
        </View>
        <View style={styles.chargeOverview}>
          <Text style={styles.chargeOverviewAmount}>{`${tCommon('formattedPrice', {
            value: pdfReport?.estimatedInvestmentCostMin,
          })} ${currency} -\n ${tCommon('formattedPrice', {
            value: pdfReport?.estimatedInvestmentCostMax,
          })} ${currency}`}</Text>
          <Text style={styles.chargeOverviewDesc}>{T('centerDesc')}</Text>
        </View>
      </View>
    </PageLayout>
  );
};
export default InvestmentCosts;
