import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

import InfoTooltip from 'components/infoTooltip/infoTooltip';

import { BootstrapTooltip } from '../bootstrapTooltip';

import { useStyle } from './style';
import { DefaultTFuncReturn } from 'i18next';
import { Radio } from '@mui/material';

export interface ToggleButtonItem {
  label: string;
  value: any;
  icon?: React.ReactNode;
  info?: DefaultTFuncReturn;
  disabled?: boolean;
  tooltipTitle?: string;
}

export interface IProps {
  value?: any;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  className?: string;
  itemClassName?: string;
  itemSelectedClassName?: string;
  items: ToggleButtonItem[];
  radio?: boolean;
  error?: boolean;
  helperText?: DefaultTFuncReturn;
  tooltipTitle?: string;
}

const ToggleButtonGroup = ({
  items,
  className,
  itemClassName,
  itemSelectedClassName,
  value,
  onChange,
  radio,
  error,
  helperText,
}: IProps) => {
  const classes = useStyle();

  const findValue = (item: any) => {
    if (Array.isArray(value)) {
      return value.find((val: string) => item === val);
    }
    return value;
  };
  const handleOnChange = (buttonValue: string) => {
    if (Array.isArray(value)) {
      const values = cloneDeep(value);
      const valueExist = values.find((val: string) => val === buttonValue);

      if (valueExist) {
        return onChange(values.filter((val: string) => val !== valueExist));
      }
      return onChange([...values, buttonValue]);
    }
    onChange(buttonValue);
  };
  return (
    <div className={className}>
      {items.map((item, index: number) => (
        <BootstrapTooltip
          key={`${item.value}-${index}`}
          title={item.tooltipTitle || ('' as unknown as string)}
        >
          <ToggleButton
            className={clsx(
              classes.item,
              itemClassName,
              findValue(item?.value) === item.value && classes.selectedItem,
              findValue(item?.value) === item.value && itemSelectedClassName,
            )}
            value={item?.value || ''}
            selected={findValue(item?.value) === item.value}
            onChange={() => {
              !item.disabled && handleOnChange(item.value);
            }}
          >
            {radio && (
              <Radio value={item.value} checked={item.value === value} className={classes.radio} />
            )}
            {item.icon && <div className={classes.icon}>{item.icon}</div>}
            <div className={classes.text}>{item.label}</div>
            {item.info && <InfoTooltip title={item.info} />}
          </ToggleButton>
        </BootstrapTooltip>
      ))}
      {error && <p className={classes.error}>{helperText}</p>}
    </div>
  );
};

export default ToggleButtonGroup;
