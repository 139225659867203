import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import Dialog, { IAction } from 'components/dialog/dialog';

import useStyles from './style';

const Disclaimer = () => {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const dialogActions: IAction[] = [
    { label: t('continue'), onClick: () => setShow(false), color: 'primary' },
  ];

  return (
    <div>
      <div className={classes.disclaimer} onClick={() => setShow(true)}>
        *{t('disclaimer')}
      </div>
      <Dialog open={show} actions={dialogActions} onClose={() => setShow(false)}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography>{t('disclaimer')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {t('disclaimerDescription')}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
export default Disclaimer;
