import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const GeneralIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M24.005 9.70477C23.9801 9.2057 23.759 8.73668 23.39 8.39978L23.335 8.34976L12.45 0.174774C12.3191 0.0777557 12.1604 0.0253906 11.9975 0.0253906C11.8345 0.0253906 11.6759 0.0777557 11.545 0.174774L0.674995 8.37479L0.614998 8.42478C0.428725 8.59828 0.278522 8.80683 0.172989 9.03848C0.067456 9.27014 0.0086703 9.52037 0 9.77478V22.2748C0.00262471 22.7315 0.185208 23.1687 0.508141 23.4916C0.831074 23.8146 1.26831 23.9972 1.725 23.9998H22.27C22.7276 23.9959 23.1654 23.8123 23.489 23.4887C23.8126 23.1651 23.9961 22.7274 24 22.2698L24.005 9.70477ZM22.505 22.2698C22.5037 22.3304 22.4791 22.3881 22.4362 22.431C22.3934 22.4739 22.3356 22.4985 22.275 22.4998H1.73C1.669 22.4998 1.61049 22.4755 1.56736 22.4324C1.52423 22.3893 1.5 22.3308 1.5 22.2698V9.76978C1.50922 9.66903 1.55139 9.57414 1.62 9.49979L12.005 1.67477L22.39 9.50476C22.4517 9.56732 22.492 9.64786 22.505 9.73477V22.2698Z" />
    </SvgIcon>
  );
};

export default GeneralIcon;
