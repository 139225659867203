import { useQuery } from '@tanstack/react-query';
import useCalculate from 'hooks/calculate.zustand';
import { useEffect } from 'react';
import businessCaseService from 'services/businessCase.service';

export interface Root {
  error: boolean;
  message: number;
  data: IRecommendation;
}
export interface IRecommendation {
  totalInvestment: number;
  infrastructureCosts: number;
  installationCosts: number;
  operationalCosts: number;
  chargingPointsAtHome: number;
  chargingPointsAtWork: number;
  chargingPointsAtPublic: number;
  inputValues: RInputValues;
}

export interface RInputValues {
  chargingSolution: string[];
  numberOfWorksites: number;
  numberOfParkingSpots: number[];
  numberOfVehicles: number;
  acquiringType: string;
}
const useRecommendation = () => {
  const calcData = useCalculate((state) => state.data);
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const { remove, ...rest } = useQuery<Root>(
    ['recommendation'],
    () => businessCaseService.getRecommendation(calcData),
    { onSuccess: () => makeRefetch(false), refetchOnWindowFocus: false },
  );
  useEffect(() => {
    return () => remove();
  }, [remove]);

  return {
    ...rest,
  };
};
export default useRecommendation;
