import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import { DATA_PRIVACY, IMPRINT } from 'constants/routes';

import useStyles from '../../style';
import Disclaimer from '../disclaimer';

interface IProps {
  disclaimer?: boolean;
}
const SidebarFooter = ({ disclaimer = false }: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Box textAlign="center" pb={0.5} width="80%" margin="0 auto">
      {disclaimer && <Disclaimer />}

      <Typography variant="body2">{t('sidebar.dataIsEncrypted')}</Typography>
      <Grid container spacing={2} justifyContent="center" className={classes.footerLinks}>
        <Grid item>
          <a href={IMPRINT} target="_blank" rel="noreferrer">
            {t('imprint')}
          </a>
        </Grid>
        <Grid item>
          <a href={DATA_PRIVACY} target="_blank" rel="noreferrer">
            {t('dataPrivacy')}
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SidebarFooter;
