import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles<Theme>((theme) => ({
  icon: {
    backgroundColor: '#DFFDF7',
    padding: 14,
    borderRadius: '50%',
    margin: 8,
  },
  item: {
    borderRadius: '10px',
    border: `1px solid ${theme.palette.divider}`,
    color: `${theme.palette.text.primary}`,
    '&:hover': {
      '& $icon svg': {
        fill: theme.palette.primary.main,
      },
      '& $icon': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  selectedItem: {
    '& $icon svg': {
      fill: theme.palette.primary.main,
    },
    '& $icon': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.MuiToggleButton-root': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.text.primary}`,
    },
  },
  info: {
    marginLeft: theme.spacing(1),
  },
  radio: {
    padding: `0 ${theme.spacing(1)}`,
    '& .MuiIconButton-label': {
      color: theme.palette.secondary.dark,
    },
  },
  text: {
    textAlign: 'left',
    lineHeight: 1.3,
  },
  error: {
    fontSize: '0.75rem',
    margin: `${theme.spacing(0.5)} 0 0 ${theme.spacing(1.5)}`,
    color: 'red',
  },
}));
