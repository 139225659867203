import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme';

import BarChartSkeleton from 'components/pages/businessCase/reports/components/skeleton';
import { useStyles } from 'components/pages/businessCase/reports/styles';
import { mapChartNestedData } from 'components/pages/initialCheck/sidebarWithChart';

interface IProps {
  currency: string;
  data: any;
  isLoading: boolean;
  isDone?: () => void;
}
const EVICEBarChart = ({ currency, data, isLoading, isDone }: IProps) => {
  const classes = useStyles();
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('businessCaseReports');
  const chartData = mapChartNestedData(data);

  const CustomBar: FunctionComponent<any> = (props: any) => {
    const { x, index } = props;

    let evenX;
    switch (index % 2) {
      case 0:
        evenX = x + 7;
        break;
      case 1:
        evenX = x - 7;
        break;
      default:
        evenX = x;
        break;
    }
    return <Rectangle {...props} x={evenX} />;
  };
  const RenderLegend: FunctionComponent<any> = (props: any) => {
    const { payload } = props;

    const sortedPayload = payload.sort((a: any, b: any) => a.payload.order - b.payload.order);
    return (
      <ul className={classes.legendWrapper}>
        {sortedPayload.map((entry: any, index: number) => (
          <li key={`item-${index}`}>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
            >
              <path
                stroke="none"
                fill={entry.color}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </svg>
            <span style={{ fontSize: '10px' }}>{t(`costEVICE.${entry.value}`)}</span>
          </li>
        ))}
      </ul>
    );
  };
  const formatter = (value: string) => `${tCommon('number', { value })} ${currency}`;
  const renderTooltipText = (value: number, name: string) => {
    return [`${tCommon('formattedPrice', { value })} ${currency}`, t(`costEVICE.${name}`)];
  };
  const labelFormatter = (value: string) => {
    return t(`costEVICE.${value}`);
  };
  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    const pathX = Math.floor(x - payload.offset) + 0.5;
    if (payload.index % 2 === 0) {
      return (
        <>
          <g transform={`translate(${x},${y})`}>
            <text x={24} y={0} dy={16} textAnchor="end" fill="#666" style={{ fontSize: '12px' }}>
              {t(`costEVICE.${payload.value}`)}
            </text>
            <text
              x={x - 100}
              y={22}
              dy={16}
              textAnchor="end"
              fill="#666"
              style={{ fontSize: '12px' }}
            >
              {data?.[payload.index]?.year}
            </text>
          </g>
          <path d={`M${pathX + 1},${y + 22}v${-30}`} stroke="#666" style={{ fontSize: '12px' }} />
        </>
      );
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-3} y={0} dy={16} textAnchor="end" fill="#666" style={{ fontSize: '12px' }}>
          {t(`costEVICE.${payload.value}`)}
        </text>
        <text x={x - 105} y={22} dy={16} textAnchor="end" fill="#666" style={{ fontSize: '12px' }}>
          {data?.[payload.index]?.year}
        </text>
      </g>
    );
  };
  return (
    <>
      {isLoading ? (
        <BarChartSkeleton />
      ) : (
        <>
          <ResponsiveContainer debounce={1} width="100%" height="100%">
            <BarChart
              className={classes.wrapper}
              barGap={2}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient
                  id="colorUv"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor={theme.palette.chart.infra.from} />
                  <stop offset="1" stopColor={theme.palette.chart.infra.to} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                minTickGap={20}
                tickLine={false}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                width={100}
                axisLine={false}
                tickLine={false}
                tickFormatter={formatter}
                domain={([dataMin, dataMax]) => {
                  const absMax =
                    Math.round(Math.max(Math.abs(dataMin), Math.abs(dataMax)) / 50000) * 50000;
                  return [dataMin, absMax];
                }}
              />
              <Tooltip
                // @ts-ignore
                formatter={renderTooltipText}
                cursor={false}
                labelFormatter={labelFormatter}
              />
              <Legend
                content={<RenderLegend />}
                align="left"
                wrapperStyle={{
                  paddingTop: '20px',
                  paddingLeft: '5%',
                  // position: 'relative',
                  // top: '-25%',
                }}
              />
              <Bar
                radius={[0, 0, 10, 10]}
                shape={<CustomBar />}
                barSize={20}
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                stackId="a"
                fill={theme.palette.chart.energy}
                dataKey="energy"
                order={4}
              />
              <Bar
                shape={<CustomBar />}
                stackId="a"
                barSize={20}
                fill={theme.palette.chart.vehicles}
                dataKey="existingFleetCost"
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                order={1}
              />
              <Bar
                shape={<CustomBar />}
                barSize={20}
                stackId="a"
                fill={theme.palette.chart.investmentCosts}
                dataKey="investmentCost"
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                order={2}
              />
              <Bar
                shape={<CustomBar />}
                barSize={20}
                stackId="a"
                fill={theme.palette.chart.oldCost}
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                dataKey="leasingCost"
                order={3}
              />
              <Bar
                shape={<CustomBar />}
                barSize={20}
                stackId="a"
                fill={theme.palette.chart.maintenance}
                dataKey="maintenance"
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                order={6}
              />
              <Bar
                shape={<CustomBar />}
                barSize={20}
                stackId="a"
                fill={theme.palette.chart.backendCosts}
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                dataKey="backend"
                order={7}
              />
              <Bar
                radius={[10, 10, 0, 0]}
                shape={<CustomBar />}
                barSize={20}
                stackId="a"
                fill={theme.palette.chart.taxesInsurance}
                animationDuration={1}
                onAnimationEnd={() => isDone?.()}
                dataKey="tax"
                order={5}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};
export default EVICEBarChart;
