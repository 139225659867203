import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FirstPage from '../../layout/firstPage/firstPage';
import { IPdfReports } from '../../types';

import AssumptionMade from './components/assumptionMade';
import Co2Saving from './components/co2Saving';
import DetailedCostComparison from './components/detailedCostComparison';
import FleetData from './components/fleetData';
import InvestmentCosts from './components/investmentCosts';
import OperationCosts from './components/operationCosts';
import OverallCostOverview from './components/overallCostOverview';
// import RoadmapVehicles from './components/roadmapVehicles';

interface IChapter {
  evICEComparisonLineChartUrl?: string;
  evICEComparisonBarChartUrl?: string;
  roadmapVehiclesUrl?: string;
  roadmapInfrastructureUrl?: string;
  co2EmissionUrl?: string;
  pdfReport: IPdfReports;
}

export const assumptionsImagesPath = '/img/business-case/reports/assumptions';
const AssumptionsAndReports: FC<IChapter> = ({
  evICEComparisonLineChartUrl,
  evICEComparisonBarChartUrl,
  pdfReport,
  // roadmapVehiclesUrl,
  // roadmapInfrastructureUrl,
  co2EmissionUrl,
}: IChapter) => {
  const { t } = useTranslation('report');

  return (
    <>
      {/* PAGE 1 */}
      <FirstPage title={t('assumptionAndReportTitle')} chapter={2} />
      {/* PAGE 2 */}
      <AssumptionMade />
      {/* PAGE 3 & 4*/}
      <FleetData pdfReport={pdfReport} />
      {/* PAGE 5 */}
      <OverallCostOverview
        pdfReport={pdfReport}
        evICEComparisonLineChartUrl={evICEComparisonLineChartUrl}
      />
      {/* PAGE 6 */}
      <DetailedCostComparison
        pdfReport={pdfReport}
        evICEComparisonBarChartUrl={evICEComparisonBarChartUrl}
      />
      {/* PAGE 7 & 8 */}
      {/* <RoadmapVehicles
        pdfReport={pdfReport}
        roadmapVehiclesUrl={roadmapVehiclesUrl}
        roadmapInfrastructureUrl={roadmapInfrastructureUrl}
      /> */}
      {/* PAGE 9 */}
      <InvestmentCosts pdfReport={pdfReport} />
      {/*  PAGE 10 */}
      <OperationCosts pdfReport={pdfReport} />
      {/*  Page 11  */}
      <Co2Saving pdfReport={pdfReport} co2EmissionUrl={co2EmissionUrl} />
    </>
  );
};

export default AssumptionsAndReports;
