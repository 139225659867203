import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const ChartMode = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.7773 10.5H21.7773C21.9763 10.5 22.167 10.421 22.3077 10.2803C22.4483 10.1397 22.5273 9.94891 22.5273 9.75C22.5234 7.16536 21.4949 4.68771 19.6672 2.86009C17.8396 1.03247 15.362 0.00396723 12.7773 0C12.5784 0 12.3877 0.0790133 12.247 0.219666C12.1064 0.360318 12.0273 0.551088 12.0273 0.75V9.75C12.0273 9.94891 12.1064 10.1397 12.247 10.2803C12.3877 10.421 12.5784 10.5 12.7773 10.5V10.5ZM13.5273 1.535C15.4469 1.71334 17.2438 2.55711 18.607 3.92032C19.9702 5.28353 20.814 7.0804 20.9923 9H13.5273V1.535Z" />
      <path d="M10.5258 13.9998V5.2598C10.5256 5.06095 10.4464 4.87032 10.3058 4.7298C10.1646 4.59003 9.97438 4.51106 9.77576 4.5098C8.2204 4.50569 6.68664 4.87374 5.30252 5.58322C3.9184 6.29271 2.72411 7.32302 1.81935 8.58815C0.91459 9.85328 0.325627 11.3165 0.101637 12.8557C-0.122353 14.3948 0.0251245 15.9652 0.531767 17.4357C1.03841 18.9063 1.88952 20.2342 3.01401 21.3088C4.1385 22.3834 5.50373 23.1733 6.99574 23.6127C8.48775 24.052 10.0632 24.1281 11.5906 23.8345C13.118 23.5408 14.553 22.8861 15.7758 21.9248C15.8545 21.8649 15.9203 21.7898 15.9692 21.7039C16.0182 21.6179 16.0493 21.523 16.0608 21.4248C16.081 21.2284 16.0256 21.0317 15.9058 20.8748L10.5258 13.9998ZM9.77576 22.4998C7.65428 22.4975 5.61525 21.678 4.0822 20.2116C2.54914 18.7451 1.63991 16.7445 1.54338 14.6252C1.44684 12.5059 2.17042 10.4309 3.56381 8.8312C4.9572 7.23147 6.91328 6.23 9.02576 6.03481V14.2498C9.02531 14.4184 9.08168 14.5822 9.18576 14.7148L14.2608 21.1748C12.924 22.038 11.367 22.498 9.77576 22.4998V22.4998Z" />
      <path d="M21.775 12H12.775C12.634 11.9997 12.4957 12.0392 12.3761 12.1139C12.2565 12.1886 12.1604 12.2955 12.0988 12.4224C12.0372 12.5492 12.0126 12.6909 12.0278 12.8311C12.0431 12.9713 12.0976 13.1044 12.185 13.215L17.745 20.29C17.8143 20.3805 17.9037 20.4538 18.006 20.5041C18.1084 20.5544 18.2209 20.5804 18.335 20.58C18.5018 20.5789 18.6635 20.5227 18.795 20.42C19.9606 19.5108 20.9027 18.3469 21.5492 17.0174C22.1957 15.688 22.5295 14.2283 22.525 12.75C22.525 12.5511 22.446 12.3603 22.3053 12.2197C22.1647 12.079 21.9739 12 21.775 12V12ZM18.465 18.76L14.315 13.5H20.99C20.8226 15.5039 19.9238 17.3761 18.465 18.76V18.76Z" />
    </SvgIcon>
  );
};

export default ChartMode;
