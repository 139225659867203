import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DrawerWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.hint.main,
  padding: theme.spacing(2),
  h4: {
    fontFamily: 'Oxanium',
    fontSize: '22px',
    fontWeight: '400',
    color: '#fff',
  },
  '.MuiTypography-body1': {
    color: theme.palette.hint.main,
    marginTop: theme.spacing(2.5),
    fontSize: '13px',
  },
  h5: {
    color: theme.palette.hint.main,
    fontSize: '15px',
  },
  input: {
    color: '#fff!important',
  },
  'input + fieldset': {
    borderColor: '#A1A7BB',
  },
  ' fieldset': {
    borderColor: '#A1A7BB',
  },
  '&:hover fieldset': {
    borderColor: '#A1A7BB',
  },
  '.Mui-focused fieldset': {
    borderColor: '#A1A7BB',
  },
  '.MuiFormControlLabel-root': {
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.hint.main}`,
    padding: `0 ${theme.spacing(1)}`,
    borderRadius: '8px',
  },
  '.MuiFormControlLabel-label': {
    marginTop: '0!important',
  },
  '.selected': {
    backgroundColor: theme.palette.primary.main,
    '.MuiTypography-root': {
      color: '#000',
    },
    '.MuiRadio-root': {
      color: '#000',
    },
  },
  '.Mui-selected': {
    background: theme.palette.primary.main,
    '.MuiTypography-root': {
      color: '#000!important',
    },
  },
}));
export const Divider = styled('div')(({ theme }) => ({
  borderTop: '2px solid',
  borderImage: 'linear-gradient(to right,#A1A7BB,#A1A7BB00) 1 0 0 0;',
  width: '100px',
  marginLeft: theme.spacing(5),
  position: 'relative',
  top: '3px',
}));
