import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View } from '@react-pdf/renderer';

import FirstPage from '../../layout/firstPage/firstPage';
import { IPdfReports, ISection } from '../../types';

import styles from './styles';

interface IChapter {
  pdfReport?: IPdfReports;
}

interface IChapterTranslation {
  firstPage: ISection;
  welcomePage: IWelcomePage;
}

interface IWelcomePage {
  goodDay: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  executiveSummary: string;
  assumptionsAndReports: string;
  ourSolution: string;
  packagesAndPrices: string;
  contact: string;
}

const FleetAnalysis: FC<IChapter> = ({ pdfReport }: IChapter) => {
  const { t } = useTranslation('report');

  const trans = t('fleetAnalysis', {
    returnObjects: true,
    firstName: pdfReport?.fleetManager?.firstName,
    lastName: pdfReport?.fleetManager?.lastName,
  }) as IChapterTranslation;

  return (
    <>
      <FirstPage title={trans.firstPage.title} />
      <Page orientation="landscape" style={styles.page2}>
        <View style={styles.column1}>
          <Text style={styles.column1title}>{trans.welcomePage?.goodDay}</Text>
          <Text style={styles.paragraph}>{trans.welcomePage.text1}</Text>
          <Text style={styles.paragraph}>{trans.welcomePage.text2}</Text>
          <Text style={styles.paragraph}>{trans.welcomePage.text3}</Text>
          <Text style={styles.paragraph}>{trans.welcomePage.text4}</Text>
        </View>
        <View style={styles.column2}>
          <Text style={styles.column2title}>Übersicht Ihres Reports</Text>
          <View style={styles.listitems}>
            <View style={styles.listitemsContent}>
              <View style={styles.item}>
                <Text style={styles.number}>1</Text>
                <Text style={styles.title}>{trans.welcomePage.executiveSummary}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.number}>2</Text>
                <Text style={styles.title}>{trans.welcomePage.assumptionsAndReports}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.number}>3</Text>
                <Text style={styles.title}>{trans.welcomePage.ourSolution}</Text>
              </View>
              {/* REMOVE BASED ON : DC-1124 */}
              {/* <View style={styles.item}>
                <Text style={styles.number}>4</Text>
                <Text style={styles.title}>{trans.welcomePage.packagesAndPrices}</Text>
              </View> */}

              <View style={styles.item}>
                <Text style={styles.number}>4</Text>
                <Text style={styles.title}>{trans.welcomePage.contact}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </>
  );
};

export default FleetAnalysis;
