import { Box, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SideWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.text.primary,
  height: '100%',
  color: '#fff',
  h5: {
    fontFamily: 'Oxanium',
    fontSize: '22px',
    fontWeight: '600',
  },
  '.lessAttention': {
    opacity: '0.7',
  },
  '.investment': {
    p: {
      opacity: '0.6',
      fontSize: '14px',
    },
    li: {
      color: theme.palette.hint.main,
      fontSize: '14px',
      span: {
        color: theme.palette.info.light,
      },
    },
  },
}));
export const CToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  button: {
    transition: '0.2s all ease-in',
    border: '1px solid transparent!important',
    padding: theme.spacing(1.5),
    margin: theme.spacing(1),
    background: 'linear-gradient(180deg, rgba(19, 22, 48, 0.39) 0%, #131630 100%)',
    borderRadius: '16px!important',
    color: '#fff',
    '.title': {
      fontSize: '10px',
      color: theme.palette.info.light,
    },
    '.amount': {
      fontSize: '18px',
      color: '#fff',
      paddingTop: theme.spacing(0.5),
      fontWeight: '600',
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      borderColor: `${theme.palette.primary.main}!important`,
      '.title': {
        color: theme.palette.primary.main,
      },
      '.amount': {
        color: theme.palette.primary.main,
      },
    },
  },
  img: {
    backgroundColor: '#292C45',
    position: 'relative',
    top: '5px',
    padding: theme.spacing(0.5),
    borderRadius: '35%',
  },
}));
export const CEToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  button: {
    transition: '0.2s all ease-in',
    border: `1px solid ${theme.palette.text.secondary}!important`,
    padding: theme.spacing(1.5),
    margin: theme.spacing(1),
    background: 'linear-gradient(180deg, rgba(19, 22, 48, 0.39) 0%, #131630 100%)',
    borderRadius: '16px!important',
    color: '#fff',
    '.title': {
      fontSize: '11px',
      color: theme.palette.info.light,
      marginTop: '4px',
    },
    '.amount': {
      fontSize: '18px',
      color: '#fff',
      paddingTop: theme.spacing(0.5),
      fontWeight: '600',
    },
    '&.MuiToggleButton-root': {
      '&:hover': {
        '.MuiTypography-root': {
          color: '#fff!important',
        },
      },
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      borderColor: `${theme.palette.primary.main}!important`,
      '.title': {
        color: theme.palette.primary.main,
      },
      '.amount': {
        color: theme.palette.primary.main,
      },
    },
  },
  img: {
    padding: theme.spacing(0.5),
  },
}));
export const Footer = styled(Box)(({ theme }) => ({
  marginTop: '3vh',
  h3: {
    fontSize: '24px',
  },
  p: {
    fontSize: '14px',
    color: theme.palette.hint.main,
  },
}));
