import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const GovernmentMunicipal = (props: IProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M23.25 22.5H22.5V16.5H23.25C23.4489 16.5 23.6397 16.421 23.7803 16.2803C23.921 16.1397 24 15.9489 24 15.75C24 15.5511 23.921 15.3603 23.7803 15.2197C23.6397 15.079 23.4489 15 23.25 15H18.75V9.00002H19.5C19.6989 9.00002 19.8897 8.921 20.0303 8.78035C20.171 8.6397 20.25 8.44893 20.25 8.25002C20.25 8.0511 20.171 7.86033 20.0303 7.71968C19.8897 7.57903 19.6989 7.50002 19.5 7.50002H18.675C18.24 5.15002 15.805 3.29999 12.75 3.03499V0.740005C12.75 0.541093 12.671 0.350323 12.5303 0.209671C12.3897 0.0690187 12.1989 -0.00999451 12 -0.00999451C11.8011 -0.00999451 11.6103 0.0690187 11.4697 0.209671C11.329 0.350323 11.25 0.541093 11.25 0.740005V3.02498C8.2 3.28998 5.74999 5.14001 5.32999 7.49001H4.5C4.30109 7.49001 4.11032 7.56902 3.96967 7.70967C3.82901 7.85032 3.75 8.04109 3.75 8.24001C3.75 8.43892 3.82901 8.62969 3.96967 8.77034C4.11032 8.91099 4.30109 8.99001 4.5 8.99001H5.25V14.99H0.75C0.551088 14.99 0.360318 15.069 0.219666 15.2097C0.0790132 15.3503 0 15.5411 0 15.74C0 15.9389 0.0790132 16.1297 0.219666 16.2703C0.360318 16.411 0.551088 16.49 0.75 16.49H1.5V22.49H0.75C0.551088 22.49 0.360318 22.569 0.219666 22.7097C0.0790132 22.8503 0 23.0411 0 23.24C0 23.4389 0.0790132 23.6297 0.219666 23.7703C0.360318 23.911 0.551088 23.99 0.75 23.99H23.25C23.4489 23.99 23.6397 23.911 23.7803 23.7703C23.921 23.6297 24 23.4389 24 23.24C24 23.0411 23.921 22.8503 23.7803 22.7097C23.6397 22.569 23.4489 22.49 23.25 22.49V22.5ZM12 4.50002C14.535 4.50002 16.66 5.79002 17.145 7.50002H6.85999C7.34499 5.79002 9.465 4.50002 12 4.50002ZM6.75 9.00002H17.25V15H6.75V9.00002ZM3 22.5V16.5H21V22.5H3Z" />
      <path d="M7.5 18C7.30109 18 7.11032 18.079 6.96967 18.2197C6.82901 18.3603 6.75 18.5511 6.75 18.75V20.25C6.75 20.4489 6.82901 20.6397 6.96967 20.7803C7.11032 20.921 7.30109 21 7.5 21C7.69891 21 7.88968 20.921 8.03033 20.7803C8.17099 20.6397 8.25 20.4489 8.25 20.25V18.75C8.25 18.5511 8.17099 18.3603 8.03033 18.2197C7.88968 18.079 7.69891 18 7.5 18Z" />
      <path d="M10.5 18C10.3011 18 10.1103 18.079 9.96967 18.2197C9.82901 18.3603 9.75 18.5511 9.75 18.75V20.25C9.75 20.4489 9.82901 20.6397 9.96967 20.7803C10.1103 20.921 10.3011 21 10.5 21C10.6989 21 10.8897 20.921 11.0303 20.7803C11.171 20.6397 11.25 20.4489 11.25 20.25V18.75C11.25 18.5511 11.171 18.3603 11.0303 18.2197C10.8897 18.079 10.6989 18 10.5 18Z" />
      <path d="M13.5 18C13.3011 18 13.1103 18.079 12.9697 18.2197C12.829 18.3603 12.75 18.5511 12.75 18.75V20.25C12.75 20.4489 12.829 20.6397 12.9697 20.7803C13.1103 20.921 13.3011 21 13.5 21C13.6989 21 13.8897 20.921 14.0303 20.7803C14.171 20.6397 14.25 20.4489 14.25 20.25V18.75C14.25 18.5511 14.171 18.3603 14.0303 18.2197C13.8897 18.079 13.6989 18 13.5 18Z" />
      <path d="M10.5 10.5C10.3011 10.5 10.1103 10.579 9.96967 10.7197C9.82901 10.8603 9.75 11.0511 9.75 11.25V12.75C9.75 12.9489 9.82901 13.1397 9.96967 13.2803C10.1103 13.421 10.3011 13.5 10.5 13.5C10.6989 13.5 10.8897 13.421 11.0303 13.2803C11.171 13.1397 11.25 12.9489 11.25 12.75V11.25C11.25 11.0511 11.171 10.8603 11.0303 10.7197C10.8897 10.579 10.6989 10.5 10.5 10.5Z" />
      <path d="M13.5 10.5C13.3011 10.5 13.1103 10.579 12.9697 10.7197C12.829 10.8603 12.75 11.0511 12.75 11.25V12.75C12.75 12.9489 12.829 13.1397 12.9697 13.2803C13.1103 13.421 13.3011 13.5 13.5 13.5C13.6989 13.5 13.8897 13.421 14.0303 13.2803C14.171 13.1397 14.25 12.9489 14.25 12.75V11.25C14.25 11.0511 14.171 10.8603 14.0303 10.7197C13.8897 10.579 13.6989 10.5 13.5 10.5Z" />
      <path d="M16.5 18C16.3011 18 16.1103 18.079 15.9697 18.2197C15.829 18.3603 15.75 18.5511 15.75 18.75V20.25C15.75 20.4489 15.829 20.6397 15.9697 20.7803C16.1103 20.921 16.3011 21 16.5 21C16.6989 21 16.8897 20.921 17.0303 20.7803C17.171 20.6397 17.25 20.4489 17.25 20.25V18.75C17.25 18.5511 17.171 18.3603 17.0303 18.2197C16.8897 18.079 16.6989 18 16.5 18Z" />
    </SvgIcon>
  );
};

export default GovernmentMunicipal;
