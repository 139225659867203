import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import styles from '../styles';
import { TOptionsBase } from 'i18next';

interface IFiveSteps {
  technicalConcepts: string[];
  serviceAndOperation: string[];
  electrificationConsulting: string[];
  homePublicAndWork: string[];
  fleetOptimization: string[];
}
const ourSolutionImagePath = '/img/business-case/reports/ourSolution';
const FiveSteps = () => {
  const { t } = useTranslation('report');
  const T = (key: string, option?: TOptionsBase & object & { defaultValue: string }) =>
    t(`fiveSteps.${key}`, option!);

  const translations = t('fiveSteps', {
    returnObjects: true,
  }) as IFiveSteps;
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
      </View>
      <View style={styles.steps}>
        <Image style={{ width: '661px' }} src={`${ourSolutionImagePath}/arrows.png`} />
        <View style={styles.technicalConcept}>
          <View style={styles.stepsTitle}>
            <View>
              <Text style={styles.stepsHead}>{T('stepOneDesc')}</Text>
              <View style={[styles.separator, styles.secondStepColor]} />
            </View>
            <View style={styles.stepsIcon}>
              <Image src={`${ourSolutionImagePath}/reportComputer.png`} />
            </View>
          </View>
          {translations.technicalConcepts.map((val) => (
            <View key={val} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{val}</Text>
            </View>
          ))}
          <View style={[styles.stepLineWrapper, styles.marginTop]}>
            <Text style={[styles.stepNumber, styles.secondStepColor]}>2</Text>
            <Text style={styles.stepLineTitle}>{T('stepOneTitle')}</Text>
          </View>
        </View>
        <View style={styles.elecConsulting}>
          <View style={[styles.stepLineWrapper, styles.marginBottom]}>
            <Text style={[styles.stepNumber, styles.firstStepColor]}>1</Text>
            <Text style={styles.stepLineTitle}>{T('stepTwoTitle')}</Text>
          </View>
          <View style={styles.stepsTitle}>
            <View>
              <Text style={styles.stepsHead}>{T('stepTwoDesc')}</Text>
              <View style={[styles.separator, styles.firstStepColor]} />
            </View>
            <View style={styles.stepsIcon}>
              <Image src={`${ourSolutionImagePath}/presentationReport.png`} />
            </View>
          </View>
          {translations.electrificationConsulting.map((val) => (
            <View key={val} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{val}</Text>
            </View>
          ))}
        </View>
        <View style={styles.homePublic}>
          <View style={[styles.stepLineWrapper, styles.marginBottom]}>
            <Text style={[styles.stepNumber, styles.thirdStepColor]}>3</Text>
            <Text style={styles.stepLineTitle}>{T('stepThreeTitle')}</Text>
          </View>
          <View style={styles.stepsTitle}>
            <View>
              <Text style={styles.stepsHead}>{T('stepThreeDesc')}</Text>
              <View style={[styles.separator, styles.thirdStepColor]} />
            </View>
            <View style={styles.stepsIcon}>
              <Image src={`${ourSolutionImagePath}/skyline.png`} />
            </View>
          </View>
          {translations.homePublicAndWork.map((val) => (
            <View key={val} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{val}</Text>
            </View>
          ))}
        </View>
        <View style={styles.serviceAndOperations}>
          <View style={styles.stepsTitle}>
            <View>
              <Text style={styles.stepsHead}>{T('stepFourDesc')}</Text>
              <Text style={[styles.separator, styles.forthStepColor]} />
            </View>
            <View style={styles.stepsIcon}>
              <Image src={`${ourSolutionImagePath}/support.png`} />
            </View>
          </View>
          {translations.serviceAndOperation.map((val) => (
            <View key={val} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{val}</Text>
            </View>
          ))}
          <View style={[styles.stepLineWrapper, styles.forthTopMargin]}>
            <Text style={[styles.stepNumber, styles.forthStepColor]}>4</Text>
            <Text style={styles.stepLineTitle}>{T('stepFourTitle')}</Text>
          </View>
        </View>
        <View style={styles.optimization}>
          <View style={[styles.stepLineWrapper, styles.marginBottom]}>
            <Text style={[styles.stepNumber, styles.fifthStepColor]}>5</Text>
            <Text style={styles.stepLineTitle}>{T('stepFiveTitle')}</Text>
          </View>
          <View style={styles.stepsTitle}>
            <View>
              <Text style={styles.stepsHead}>{T('stepFiveDesc')}</Text>
              <View style={[styles.separator, styles.fifthStepColor]} />
            </View>
            <View style={styles.stepsIcon}>
              <Image src={`${ourSolutionImagePath}/chargingSolar.png`} />
            </View>
          </View>
          {translations.fleetOptimization.map((val) => (
            <View key={val} style={styles.listView}>
              <Text style={styles.listBullet}>•</Text>
              <Text style={styles.listText}>{val}</Text>
            </View>
          ))}
        </View>
      </View>
    </PageLayout>
  );
};
export default FiveSteps;
