import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  countriesContainer: {
    width: '100%',
    margin: `${theme.spacing(3)} -5px`,
    display: 'flex',

    justifyContent: 'flex-start',
    '& button:first-child': {
      marginLeft: '0',
    },
  },
  legendWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    listStyle: 'none',
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1.75),
      '& span': {
        marginLeft: theme.spacing(1),
      },
    },
  },
  icon: {
    padding: `12px 12px 8px 12px`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    '& img': {
      width: '25px',
    },
  },
  headerItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  items: {
    '&.MuiToggleButton-root': {
      margin: `0 ${theme.spacing(1)}`,
      border: 'none',
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.secondary,
    },
  },
  itemSelected: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.info.dark} !important`,
  },
  headerTag: {
    padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
    fontWeight: 600,
    fontFamily: 'Oxanium',
    lineHeight: '18px',
    fontSize: '22px',
    display: 'inline-block',
  },
  sign: {
    fontSize: '12px',
  },
  header: {
    fontFamily: 'Oxanium',
    fontSize: '24px',
    lineHeight: '45px',
  },
  chartBtn: {
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: '-85px',
    right: '2%',
    padding: `${theme.spacing(0.7)} ${theme.spacing(1.1)} ${theme.spacing(1.1)} ${theme.spacing(
      0.7,
    )} !important`,
    boxShadow: 'none !important',
    minWidth: 'auto',
    '& span': {
      position: 'relative',
      top: '2px',
      right: '-3px',
    },
    '& svg': {
      fill: theme.palette.text.secondary,
    },
    '&.active': {
      backgroundColor: theme.palette.info.dark,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
  wrapper: {
    '& ul li.recharts-tooltip-item': {
      color: `${theme.palette.common.black}!important`,
    },
    '& .recharts-cartesian-axis-ticks': {
      '& text': {
        fontSize: '13px',
      },
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: '12px 0 0 12px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
