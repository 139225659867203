/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DrawerWrapper, Divider } from './styles';
import { useTranslation } from 'react-i18next';
import { CEToggleButtonGroup } from '../reports/components/secondSidebar/style';
import ParkingLocation from './parkingLocation';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import businessCaseService from 'services/businessCase.service';
import { IRecommendation } from '../reports/components/secondSidebar/hooks/useRecommendation';
import useCalculate from 'hooks/calculate.zustand';

interface IProps {
  onClose: () => void;
  recommendation: IRecommendation;
}
const EditDrawer = ({ onClose, recommendation }: IProps) => {
  enum ECharge {
    chargeAtHome = 'home',
    chargeAtWork = 'work',
    chargeAtPublic = 'public',
  }
  enum EFleet {
    LEASING = 'lease',
    PURCHASE = 'purchase',
  }
  const makeRefetch = useCalculate((state) => state.makeRefetch);
  const setCalcData = useCalculate((state) => state.setData);

  const [charge, setCharge] = useState<ECharge[]>(
    recommendation.inputValues.chargingSolution as ECharge[],
  );
  const [fleet, setFleet] = useState<EFleet>(recommendation.inputValues.acquiringType as EFleet);
  const [parkingLocations, setParkingLocations] = useState<any>(
    recommendation.inputValues.numberOfParkingSpots,
  );
  const [totalVehicles, setTotalvehicles] = useState<any>(
    recommendation.inputValues.numberOfVehicles,
  );

  const { isLoading, mutate } = useMutation(
    ['teaserOverview'],
    businessCaseService.putBusinessCaseSummary,
  );

  const handleChange = (e: any, newValue: ECharge) => {
    const chargeList = [...charge];
    if (chargeList.indexOf(newValue) != -1) {
      chargeList.splice(chargeList.indexOf(newValue), 1);
    } else {
      chargeList.push(newValue);
    }
    setCharge([...chargeList]);
  };

  const handleFleetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFleet(event.target.value as EFleet);
  };
  const handleSave = () => {
    const isChargeAtWork = charge.some((value) => value === ECharge.chargeAtWork);
    const validData = {
      chargingSolution: [...charge],
      numberOfParkingSpots: isChargeAtWork ? [...parkingLocations] : [],
      numberOfVehicles: totalVehicles,
      acquiringType: fleet,
      numberOfWorksites: parkingLocations.length,
    };
    mutate(validData, {
      onSuccess: () => {
        setCalcData(validData);
        makeRefetch(true);
        setTimeout(() => {
          onClose();
        }, 2000);
      },
    });
  };
  const { t } = useTranslation('businessCaseReports');
  return (
    <DrawerWrapper maxWidth={450}>
      <>
        <Box mb={3}>
          <Typography variant="h4">{t('editDrawer.title')}</Typography>
        </Box>
        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">{t('editDrawer.chargingSolution')}</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        <Typography variant="body1">{t('editDrawer.usedCharges')}</Typography>

        <CEToggleButtonGroup
          color="primary"
          exclusive
          aria-label="type"
          onChange={handleChange}
          value={charge}
        >
          <ToggleButton value={ECharge.chargeAtHome}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '47.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/img/business-case/charge@home.png" width="50px" />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography className="title">{t('recommendations.cpAtHome')}</Typography>
                </Box>
              </Grid>
            </Grid>
          </ToggleButton>
          <ToggleButton value={ECharge.chargeAtWork}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '47.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/img/business-case/charge@work.png" width="50px" />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography className="title"> {t('recommendations.cpAtWork')}</Typography>
                </Box>
              </Grid>
            </Grid>
          </ToggleButton>
          <ToggleButton value={ECharge.chargeAtPublic}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '47.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/img/business-case/charge@public.png" width="50px" />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography className="title"> {t('recommendations.cpAtPublic')}</Typography>
                </Box>
              </Grid>
            </Grid>
          </ToggleButton>
        </CEToggleButtonGroup>
        {charge.length === 0 && (
          <FormHelperText error>{t('editDrawer.chooseOneType')}</FormHelperText>
        )}
        {charge.some((value) => value === ECharge.chargeAtWork) && (
          <>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2, mb: 1 }}
            >
              <Grid item>
                <Typography variant="h5">{t('editDrawer.companyLocation')}</Typography>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
            </Grid>
            <ParkingLocation
              saveParkingLocations={setParkingLocations}
              defaultParking={recommendation.inputValues.numberOfParkingSpots}
            />
          </>
        )}
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2, mb: 1 }}
        >
          <Grid item>
            <Typography variant="h5">{t('editDrawer.fleet')}</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        <Typography sx={{ mb: 1 }} variant="body1">
          {t('editDrawer.totalVehicleNumber')}
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={totalVehicles}
          onChange={(e) => setTotalvehicles(Number(e.target.value))}
        />
        <Typography sx={{ mb: 1 }} variant="body1">
          {t('editDrawer.myVehicle')}
        </Typography>
        <FormControl>
          <RadioGroup
            value={fleet}
            onChange={handleFleetChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              className={fleet === EFleet.LEASING ? 'selected' : ''}
              value={EFleet.LEASING}
              onChange={() => setFleet(EFleet.LEASING)}
              control={<Radio />}
              label={t('editDrawer.leasing')}
            />
            <FormControlLabel
              className={fleet === EFleet.PURCHASE ? 'selected' : ''}
              value={EFleet.PURCHASE}
              onChange={() => setFleet(EFleet.PURCHASE)}
              control={<Radio />}
              label={t('editDrawer.purchase')}
            />
          </RadioGroup>
        </FormControl>
        <Grid container sx={{ mt: 1 }} justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave()}
              disabled={isLoading || charge.length === 0}
            >
              {isLoading ? (
                <CircularProgress sx={{ width: '25px!important', height: '25px!important' }} />
              ) : (
                t('editDrawer.save')
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" color="info" onClick={() => onClose()}>
              {t('editDrawer.reset')}
            </Button>
          </Grid>
        </Grid>
      </>
    </DrawerWrapper>
  );
};
export default EditDrawer;
