import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Box } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from 'config';

import MobileModal from '../pages/mobileModal';

const Layout = ({ children }: any) => {
  const { trackPageView } = useMatomo();
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      trackPageView({});
    }, 1);
  }, [pathname, trackPageView]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MobileModal />
      </QueryClientProvider>
      <Box>{children}</Box>
    </>
  );
};

export default Layout;
