import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const OtherIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M4 0C3.20887 0 2.4355 0.234587 1.77771 0.674113C1.11991 1.11364 0.607232 1.73833 0.304482 2.46924C0.00173126 3.20014 -0.0774796 4.00443 0.0768613 4.78035C0.231202 5.55628 0.612158 6.269 1.17157 6.82841C1.73098 7.38782 2.44372 7.7688 3.21964 7.92314C3.99557 8.07748 4.79984 7.99827 5.53074 7.69552C6.26164 7.39277 6.88636 6.88005 7.32589 6.22225C7.76541 5.56445 8 4.79113 8 4C8 2.93913 7.57857 1.92169 6.82843 1.17155C6.07828 0.421404 5.06086 0 4 0ZM4 6C3.60444 6 3.21775 5.88269 2.88885 5.66292C2.55995 5.44316 2.30362 5.13079 2.15224 4.76534C2.00086 4.39989 1.96126 3.99778 2.03843 3.60982C2.1156 3.22186 2.30609 2.86548 2.58579 2.58577C2.8655 2.30607 3.22186 2.11558 3.60982 2.03841C3.99778 1.96124 4.39991 2.00085 4.76536 2.15222C5.13081 2.3036 5.44318 2.55994 5.66294 2.88883C5.8827 3.21773 6 3.60444 6 4C6 4.53043 5.78928 5.03911 5.4142 5.41418C5.03913 5.78926 4.53043 6 4 6Z" />
        <path d="M4 24C3.20887 24 2.4355 24.2346 1.77771 24.6741C1.11991 25.1136 0.607232 25.7383 0.304482 26.4692C0.00173126 27.2001 -0.0774796 28.0044 0.0768613 28.7804C0.231202 29.5563 0.612158 30.269 1.17157 30.8284C1.73098 31.3878 2.44372 31.7688 3.21964 31.9231C3.99557 32.0775 4.79984 31.9983 5.53074 31.6955C6.26164 31.3928 6.88636 30.88 7.32589 30.2223C7.76541 29.5645 8 28.7911 8 28C8 26.9391 7.57857 25.9217 6.82843 25.1715C6.07828 24.4214 5.06086 24 4 24ZM4 30C3.60444 30 3.21775 29.8827 2.88885 29.6629C2.55995 29.4432 2.30362 29.1308 2.15224 28.7653C2.00086 28.3999 1.96126 27.9978 2.03843 27.6098C2.1156 27.2219 2.30609 26.8655 2.58579 26.5858C2.8655 26.3061 3.22186 26.1156 3.60982 26.0384C3.99778 25.9612 4.39991 26.0008 4.76536 26.1522C5.13081 26.3036 5.44318 26.5599 5.66294 26.8888C5.8827 27.2177 6 27.6044 6 28C6 28.5304 5.78928 29.0391 5.4142 29.4142C5.03913 29.7893 4.53043 30 4 30Z" />
        <path d="M28 8C28.7911 8 29.5645 7.76537 30.2223 7.32584C30.8801 6.88632 31.3928 6.26162 31.6955 5.53072C31.9983 4.79981 32.0775 3.99553 31.9231 3.2196C31.7688 2.44368 31.3878 1.73096 30.8284 1.17155C30.269 0.612137 29.5563 0.231202 28.7804 0.0768613C28.0044 -0.0774795 27.2002 0.00173126 26.4693 0.304482C25.7384 0.607232 25.1136 1.11991 24.6741 1.77771C24.2346 2.4355 24 3.20887 24 4C24 5.06086 24.4214 6.07826 25.1716 6.82841C25.9217 7.57855 26.9391 8 28 8ZM28 2C28.3956 2 28.7822 2.11727 29.1111 2.33703C29.44 2.5568 29.6964 2.86916 29.8478 3.23462C29.9991 3.60007 30.0387 4.00221 29.9616 4.39018C29.8844 4.77814 29.6939 5.13448 29.4142 5.41418C29.1345 5.69389 28.7781 5.88438 28.3902 5.96155C28.0022 6.03872 27.6001 5.99911 27.2346 5.84774C26.8692 5.69636 26.5568 5.44002 26.3371 5.11112C26.1173 4.78222 26 4.39556 26 4C26 3.46956 26.2107 2.96085 26.5858 2.58577C26.9609 2.2107 27.4696 2 28 2Z" />
        <path d="M28 24C27.2089 24 26.4355 24.2346 25.7777 24.6741C25.1199 25.1136 24.6072 25.7383 24.3045 26.4692C24.0017 27.2001 23.9225 28.0044 24.0769 28.7804C24.2312 29.5563 24.6122 30.269 25.1716 30.8284C25.731 31.3878 26.4437 31.7688 27.2196 31.9231C27.9956 32.0775 28.7998 31.9983 29.5307 31.6955C30.2616 31.3928 30.8864 30.88 31.3259 30.2223C31.7654 29.5645 32 28.7911 32 28C32 26.9391 31.5786 25.9217 30.8284 25.1715C30.0783 24.4214 29.0609 24 28 24ZM28 30C27.6044 30 27.2178 29.8827 26.8889 29.6629C26.56 29.4432 26.3036 29.1308 26.1522 28.7653C26.0009 28.3999 25.9613 27.9978 26.0384 27.6098C26.1156 27.2219 26.3061 26.8655 26.5858 26.5858C26.8655 26.3061 27.2219 26.1156 27.6098 26.0384C27.9978 25.9612 28.3999 26.0008 28.7654 26.1522C29.1308 26.3036 29.4432 26.5599 29.6629 26.8888C29.8827 27.2177 30 27.6044 30 28C30 28.5304 29.7893 29.0391 29.4142 29.4142C29.0391 29.7893 28.5304 30 28 30Z" />
        <path d="M21 2H11C10.7348 2 10.4804 2.10535 10.2929 2.29289C10.1054 2.48042 10 2.73478 10 3C10 3.26522 10.1054 3.51954 10.2929 3.70707C10.4804 3.89461 10.7348 4 11 4H21C21.2652 4 21.5196 3.89461 21.7071 3.70707C21.8946 3.51954 22 3.26522 22 3C22 2.73478 21.8946 2.48042 21.7071 2.29289C21.5196 2.10535 21.2652 2 21 2Z" />
        <path d="M28 11V21C28 21.2652 28.1054 21.5195 28.2929 21.7071C28.4804 21.8946 28.7348 22 29 22C29.2652 22 29.5196 21.8946 29.7071 21.7071C29.8946 21.5195 30 21.2652 30 21V11C30 10.7348 29.8946 10.4804 29.7071 10.2929C29.5196 10.1054 29.2652 10 29 10C28.7348 10 28.4804 10.1054 28.2929 10.2929C28.1054 10.4804 28 10.7348 28 11Z" />
        <path d="M21 28H11C10.7348 28 10.4804 28.1054 10.2929 28.2929C10.1054 28.4804 10 28.7348 10 29C10 29.2652 10.1054 29.5195 10.2929 29.7071C10.4804 29.8946 10.7348 30 11 30H21C21.2652 30 21.5196 29.8946 21.7071 29.7071C21.8946 29.5195 22 29.2652 22 29C22 28.7348 21.8946 28.4804 21.7071 28.2929C21.5196 28.1054 21.2652 28 21 28Z" />
        <path d="M4 21V11C4 10.7348 3.89465 10.4804 3.70711 10.2929C3.51958 10.1054 3.26522 10 3 10C2.73478 10 2.48042 10.1054 2.29289 10.2929C2.10535 10.4804 2 10.7348 2 11V21C2 21.2652 2.10535 21.5195 2.29289 21.7071C2.48042 21.8946 2.73478 22 3 22C3.26522 22 3.51958 21.8946 3.70711 21.7071C3.89465 21.5195 4 21.2652 4 21Z" />
      </g>
    </SvgIcon>
  );
};

export default OtherIcon;
