import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import { assumptionsImagesPath } from '../assumptionsAndReports';
import styles from '../styles';
import { TOptionsBase } from 'i18next';

const AssumptionMade = () => {
  const { t } = useTranslation('report');
  const T = (key: string, option?: TOptionsBase & object & { breakEven: number }) =>
    t(`ourAssumptionMade.${key}`, option!);
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{T('headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{T('headerSubtitle')}</Text>
      </View>
      <View style={styles.fleet}>
        <View style={styles.fleetIcon}>
          <Image src={`${assumptionsImagesPath}/fleet-image.png`} />
        </View>
        <Text style={styles.fleetTitle}>{T('fleetElectrificationTitle')}</Text>
        <Text style={styles.fleetDescription}>{T('fleetElectrificationMain')}</Text>
      </View>
      <View style={styles.chargingSolar}>
        <View style={styles.fleetIcon}>
          <Image src={`${assumptionsImagesPath}/chargingSolar.png`} />
        </View>
        <Text style={styles.fleetTitle}>{T('infrastructureSpecificationsTitle')}</Text>
        <Text style={styles.fleetDescription}>{T('infrastructureSpecificationsMain')}</Text>
      </View>
    </PageLayout>
  );
};
export default AssumptionMade;
