import { FC } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as htmlToImage from 'html-to-image';

import Chart from 'components/pages/businessCase/reports/components/charts/comparisonEV&ICE/components/lineChart/chart';

interface IChart {
  currency?: string;
  data: any;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  setUrl: (url: string) => void;
}

const useStyles = makeStyles((theme) => ({
  costComparison: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: '50vh',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
}));

const EVICEComparisonLineChart: FC<IChart> = ({ data, isLoading, setUrl }) => {
  const classes = useStyles();
  const loadChart = async () => {
    const chart = document.getElementById('costComparisonLine');
    return await htmlToImage.toPng(chart!, {
      style: { transform: 'translateY(0)' },
    });
  };
  const handleLoadChart = () => {
    loadChart().then((res) => {
      res.length > 10 && setUrl(res);
    });
  };
  return (
    <Box className={classes.costComparison} id="costComparisonLine">
      <Chart currency={''} data={data} isLoading={isLoading} isDone={() => handleLoadChart()} />
    </Box>
  );
};

export default EVICEComparisonLineChart;
