import SvgIcon from 'components/svgIcon/svgIcon';

import { IProps } from '../svgIcon/svgIcon';

const RoadmapIcon = (props: IProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.9008 6.36012C19.1435 6.3265 19.3686 6.21455 19.5419 6.04127C19.7152 5.86799 19.8271 5.64287 19.8608 5.40013C19.8657 5.35025 19.8657 5.30002 19.8608 5.25014C19.8662 5.20028 19.8662 5.14997 19.8608 5.10011C19.8271 4.85738 19.7152 4.63228 19.5419 4.459C19.3686 4.28572 19.1435 4.17374 18.9008 4.14012H18.7508C18.7009 4.13493 18.6506 4.13493 18.6008 4.14012C18.358 4.17374 18.1329 4.28572 17.9596 4.459C17.7864 4.63228 17.6744 4.85738 17.6408 5.10011C17.6354 5.14997 17.6354 5.20028 17.6408 5.25014C17.6359 5.30002 17.6359 5.35025 17.6408 5.40013C17.6744 5.64287 17.7864 5.86799 17.9596 6.04127C18.1329 6.21455 18.358 6.3265 18.6008 6.36012C18.6506 6.36531 18.7009 6.36531 18.7508 6.36012H18.9008Z" />
      <path d="M18.93 13.415C19.1256 13.3911 19.3146 13.3289 19.4862 13.232C19.6578 13.1351 19.8086 13.0052 19.93 12.85C21.785 10.475 24 7.23 24 5.25C24 3.85761 23.4469 2.52226 22.4623 1.53769C21.4777 0.553123 20.1424 0 18.75 0C17.3576 0 16.0223 0.553123 15.0377 1.53769C14.0531 2.52226 13.5 3.85761 13.5 5.25C13.5 7.25 15.71 10.475 17.57 12.85C17.6446 12.9462 17.7302 13.0335 17.825 13.11C18.0882 13.3142 18.4119 13.4251 18.745 13.425L18.93 13.415ZM15 5.25C15 4.25544 15.3951 3.30159 16.0984 2.59833C16.8016 1.89507 17.7554 1.5 18.75 1.5C19.7446 1.5 20.6984 1.89507 21.4017 2.59833C22.1049 3.30159 22.5 4.25544 22.5 5.25C22.5 6.41 21.065 8.96999 18.75 11.925C16.435 8.96499 15 6.405 15 5.25Z" />
      <path d="M7.02907 7.5551C6.93787 7.51775 6.84015 7.49886 6.7416 7.49953C6.64304 7.5002 6.5456 7.52041 6.45492 7.55901C6.36423 7.59761 6.2821 7.65382 6.21329 7.72438C6.14448 7.79495 6.09037 7.87848 6.05407 7.97011L0.0540673 22.9701C-0.0199955 23.1545 -0.0178934 23.3607 0.0599114 23.5434C0.137716 23.7262 0.28486 23.8707 0.469061 23.9451C0.558313 23.9802 0.653173 23.9988 0.749059 24.0001C0.898554 24 1.0446 23.9551 1.16843 23.8714C1.29227 23.7876 1.38826 23.6688 1.44407 23.5301L7.44407 8.53011C7.48142 8.4389 7.50031 8.34119 7.49964 8.24263C7.49897 8.14408 7.47874 8.04663 7.44015 7.95595C7.40155 7.86527 7.34533 7.78315 7.27477 7.71434C7.20421 7.64553 7.1207 7.5914 7.02907 7.5551Z" />
      <path d="M19.4455 15.4702C19.4118 15.3747 19.3592 15.287 19.2907 15.2124C19.2222 15.1378 19.1393 15.0779 19.047 15.0362C18.9548 14.9945 18.855 14.972 18.7538 14.9699C18.6525 14.9678 18.5519 14.9862 18.458 15.0241C18.3641 15.0619 18.2788 15.1184 18.2073 15.19C18.1358 15.2617 18.0795 15.3472 18.0419 15.4412C18.0043 15.5352 17.9861 15.6358 17.9885 15.737C17.9908 15.8383 18.0136 15.938 18.0555 16.0302L21.0555 23.5302C21.1108 23.6692 21.2066 23.7885 21.3306 23.8723C21.4545 23.9561 21.6009 24.0007 21.7505 24.0002C21.8463 23.9983 21.9411 23.9797 22.0305 23.9451C22.2147 23.8707 22.3619 23.7263 22.4397 23.5435C22.5175 23.3607 22.5196 23.1545 22.4455 22.9702L19.4455 15.4702Z" />
      <path d="M11.25 21C11.0511 21 10.8603 21.079 10.7197 21.2197C10.579 21.3603 10.5 21.5511 10.5 21.75V23.25C10.5 23.4489 10.579 23.6397 10.7197 23.7803C10.8603 23.921 11.0511 24 11.25 24C11.4489 24 11.6397 23.921 11.7803 23.7803C11.921 23.6397 12 23.4489 12 23.25V21.75C12 21.5511 11.921 21.3603 11.7803 21.2197C11.6397 21.079 11.4489 21 11.25 21Z" />
      <path d="M11.25 15C11.0511 15 10.8603 15.079 10.7197 15.2197C10.579 15.3603 10.5 15.5511 10.5 15.75V17.25C10.5 17.4489 10.579 17.6397 10.7197 17.7803C10.8603 17.921 11.0511 18 11.25 18C11.4489 18 11.6397 17.921 11.7803 17.7803C11.921 17.6397 12 17.4489 12 17.25V15.75C12 15.5511 11.921 15.3603 11.7803 15.2197C11.6397 15.079 11.4489 15 11.25 15Z" />
      <path d="M11.25 9C11.0511 9 10.8603 9.07901 10.7197 9.21967C10.579 9.36032 10.5 9.55109 10.5 9.75V11.25C10.5 11.4489 10.579 11.6397 10.7197 11.7803C10.8603 11.921 11.0511 12 11.25 12C11.4489 12 11.6397 11.921 11.7803 11.7803C11.921 11.6397 12 11.4489 12 11.25V9.75C12 9.55109 11.921 9.36032 11.7803 9.21967C11.6397 9.07901 11.4489 9 11.25 9Z" />
    </SvgIcon>
  );
};

export default RoadmapIcon;
