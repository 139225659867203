import config from '../config';

import HttpService from './http.service';

class BusinessCaseAPI extends HttpService {
  getMockData = async () => {
    return await this.get(`business-cases/pdf-report-mock`);
  };
  getRecommendation = async (data?: any) => {
    return await this.put(`recommendation`, data);
  };
  getBusinessCaseSummary = async () => {
    return await this.get(`business-cases/chart-summary`);
  };
  putBusinessCaseSummary = async (data?: any) => {
    return await this.put(`business-cases/chart-summary`, data);
  };
  postRequestOffer = async (lead: any) => {
    return await this.post(`fleet-check/electrification-report`, { leadId: lead });
  };
  getEvIceComparison = async (data?: any) => {
    return await this.put(`business-cases/ev-ice-comparison`, data);
  };
  getCo2Emissions = async (data?: any) => {
    return await this.put(`business-cases/co2-emissions`, data);
  };
  getRoadmapVehicles = async (data?: any) => {
    return await this.put(`business-cases/roadmap-vehicles`, data);
  };
  getRoadmapInfrastructure = async (data?: any) => {
    return await this.put(`business-cases/roadmap-infrastructure`, data);
  };
  getPdfReport = async () => {
    return await this.get(`business-cases/pdf-report`);
  };
}
export default new BusinessCaseAPI({
  apiURL: config.FLEET_MANAGER_SERVICE_URL,
});
