import { useTranslation } from 'react-i18next';
import { Image, Text, View } from '@react-pdf/renderer';

import PageLayout from 'components/pages/businessCase/reports/components/pdfReport/layout/pageLayout/pageLayout';

import styles from '../styles';

const AdditionalAnalysis = () => {
  const { t } = useTranslation('report');
  const summary = [
    {
      icon: '/img/business-case/reports/ourSolution/allInCar.png',
      desc: t('successfulFleet.listItem1'),
    },
    {
      icon: '/img/business-case/reports/ourSolution/360.png',
      desc: t('successfulFleet.listItem2'),
    },
    {
      icon: '/img/business-case/reports/ourSolution/smartphone.png',
      desc: t('successfulFleet.listItem3'),
    },
    {
      icon: '/img/business-case/reports/ourSolution/network.png',
      desc: t('successfulFleet.listItem4'),
    },
    {
      icon: '/img/business-case/reports/ourSolution/setting.png',
      desc: t('successfulFleet.listItem5'),
    },
    {
      icon: '/img/business-case/reports/ourSolution/award.png',
      desc: t('successfulFleet.listItem6'),
    },
  ];
  return (
    <PageLayout>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{t('successfulFleet.headerTitle')}</Text>
        <Text style={styles.headerSubtitle}>{t('successfulFleet.headerSubtitle')}</Text>
      </View>
      <View style={styles.fleetWrapper}>
        {summary.map(({ icon, desc }) => (
          <View key={desc} style={styles.fleetItem}>
            <View style={styles.headerWithIcon}>
              <View style={styles.headerIcon}>
                <Image src={icon} />
              </View>
              <Text style={styles.headerDesc}>{desc}</Text>
            </View>
          </View>
        ))}
      </View>
    </PageLayout>
  );
};
export default AdditionalAnalysis;
