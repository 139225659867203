import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  progressBar: {
    textAlign: 'center',
    position: 'relative',
    padding: `${theme.spacing(4)} 0`,
    minHeight: '250px',
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
      fontFamily: 'Oxanium',
      fontSize: theme.typography.h2.fontSize,
    },
    '& .superProgress': {
      fontWeight: '400',
      color: theme.palette.hint.main,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  HeaderImage: {
    '& .beta': {
      position: 'absolute',
      right: '4%',
      marginTop: theme.spacing(1),
    },
  },
  footerLinks: {
    marginTop: theme.spacing(1),
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
  },
  mainProgressBar: {
    '&:before': {
      content: "''",
      width: '120%',
      height: '120%',
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      opacity: '0.2',
      border: `1px solid ${theme.palette.hint.main}`,
    },
    '&:after': {
      content: "''",
      width: '80%',
      height: '80%',
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      opacity: '0.2',
      border: `1px solid ${theme.palette.hint.main}`,
    },
  },
  progressBg: {
    color: theme.palette.hint.main,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,0)!important',
    opacity: '0.5',
  },
}));
export default useStyles;
