import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Dialog, { IAction } from 'components/dialog/dialog';
import CookieIcon from 'components/icons/cookie';
import CustomizeIcon from 'components/icons/customize';

import { DATA_PRIVACY, IMPRINT } from 'constants/routes';

import { useStyle } from './style';

interface Cookie {
  name: string;
  purpose: string;
  expirationDate: string;
  disabled?: boolean;
}

export interface IProps {}

const CookieConsent = () => {
  const classes = useStyle();
  const { t } = useTranslation('common');
  const [cookies, setCookie] = useCookies(['CookieConsent']);

  const [consentOpen, setConsentOpen] = useState(!cookies.CookieConsent);
  const [consentCustomizeOpen, setConsentCustomizeOpen] = useState(false);
  const [selected, setSelected] = useState<readonly string[]>(['refreshToken']);

  const consentActionClickHandler = (response: string) => {
    setCookie('CookieConsent', response, {
      expires: new Date(new Date().setDate(new Date().getDate() + 1)),
    });
    setConsentOpen(false);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected(['refreshToken']);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (cookie: Cookie) => {
    if (cookie.disabled) return;
    const selectedIndex = selected.indexOf(cookie.name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, cookie.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const consentOpenHandler = () => {
    if (cookies.CookieConsent !== 'rejected') setSelected(cookies.CookieConsent);
    setConsentOpen(true);
  };

  const dialogActions: IAction[] = [
    {
      label: t('cookieConsent.reject'),
      color: 'inherit',
      variant: 'outlined',
      onClick: () => consentActionClickHandler('rejected'),
    },
    {
      label: t('cookieConsent.accept'),
      variant: 'contained',
      color: 'primary',
      onClick: () => consentActionClickHandler(JSON.stringify(selected)),
    },
  ];

  const rows: Cookie[] = [
    {
      name: 'refreshToken',
      purpose: t('cookieConsent.rtPurpose'),
      expirationDate: t('cookieConsent.rtExpirationDate'),
      disabled: true,
    },
    {
      name: '_pk_id',
      purpose: t('cookieConsent.mpPurpose'),
      expirationDate: t('cookieConsent.mpExpirationDate'),
    },
  ];

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        className={classes.openConsent}
        onClick={consentOpenHandler}
      >
        <CookieIcon viewBox="0 0 25 25" />
      </Button>
      <Dialog
        open={consentOpen}
        title={t('cookieConsent.title')}
        actions={dialogActions}
        onClose={() => setConsentOpen(false)}
        classes={{ paperWidthSm: classes.dialogWidth }}
      >
        <Typography variant="body2" className={classes.consentText}>
          <Trans i18nKey="cookieConsent.content" t={t}>
            x
            <a href={DATA_PRIVACY} target="_blank" rel="noreferrer">
              y
            </a>
            z
            <a href={IMPRINT} target="_blank" rel="noreferrer">
              w
            </a>
          </Trans>
        </Typography>
        <Box mt={2}>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setConsentCustomizeOpen(!consentCustomizeOpen)}
            startIcon={<CustomizeIcon viewBox="64 64 896 896" />}
          >
            {t('cookieConsent.customize')}
          </Button>
        </Box>
        {consentCustomizeOpen && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell align="left">{t('cookieConsent.name').toUpperCase()}</TableCell>
                <TableCell align="left">{t('cookieConsent.purpose').toUpperCase()}</TableCell>
                <TableCell align="left">
                  {t('cookieConsent.expirationDate').toUpperCase()}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  selected={isSelected(row.name)}
                  onClick={() => handleClick(row)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(row.name)}
                      disabled={row.disabled}
                    />
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.purpose}</TableCell>
                  <TableCell align="left">{row.expirationDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Dialog>
    </>
  );
};

export default CookieConsent;
