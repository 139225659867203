import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(1.5),
    // @ts-ignore
    color: theme.palette.text.hint,
  },
  savingPotential: {
    color: theme.palette.primary.main,
    fontFamily: 'Oxanium',
    fontSize: '32px',
  },
  sub: {
    color: theme.palette.common.white,
  },
  tabButton: {
    minWidth: 'auto',
    backgroundColor: theme.palette.info.dark,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.info.light,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.dark, 0.8),
    },
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
}));
