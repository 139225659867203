import { FINGERPRINT } from 'constants/user';

// import { IRegister } from '../components/pages/register';
import config from '../config';

import HttpService from './http.service';

class AuthAPI extends HttpService {
  register = (data: any) => {
    const fingerprint = localStorage.getItem(FINGERPRINT);
    return this.post('auth/register', {
      ...data,
      fingerprint,
      // TODO Remove Hardcoded password after implementing email service.
      password: 'Dc@123456789',
      context: process.env.REACT_APP_CONTEXT,
    });
  };
}

export default new AuthAPI({
  apiURL: config.FLEET_MANAGER_SERVICE_URL,
});
