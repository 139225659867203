import * as React from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogWrapper } from './style';
import Register from 'components/pages/register';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from '@tanstack/react-query';

interface IProps {
  open: boolean;
  close: () => void;
  mutation: UseMutationResult;
}
export default function RegisterDialog({ open, close, mutation }: IProps) {
  const { t } = useTranslation('businessCaseReports');

  return (
    <DialogWrapper
      open={open}
      onClose={close}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('register.title')}</DialogTitle>
      <DialogContent sx={{ padding: '20px 35px' }}>
        <DialogContentText id="alert-dialog-description">
          {t('register.description')}
        </DialogContentText>
        <Register isLoading={mutation.isLoading} onSubmit={mutation.mutate} />
      </DialogContent>
    </DialogWrapper>
  );
}
