import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';

import { IPdfReports } from '../../types';

import styles from './styles';

interface IChapter {
  pdfReport?: IPdfReports;
}

interface IChapterTranslation {
  inChargeToChange: string;
}

const Contact: FC<IChapter> = ({ pdfReport }: IChapter) => {
  const { t } = useTranslation('report');

  const trans = t('contact', {
    returnObjects: true,
  }) as IChapterTranslation;

  const getImagePath = (name: string) => {
    return `/img/business-case/reports/${name}.png`;
  };
  return (
    <>
      <Page orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{trans.inChargeToChange}</Text>
        </View>
        <View style={styles.inCharge}>
          <Image style={styles.photo} src={getImagePath('inCharge')} />
          <View style={styles.info}>
            <View style={styles.name}>
              <Text>
                {pdfReport?.contactPerson.firstName} {pdfReport?.contactPerson.lastName}
              </Text>
            </View>
            <View style={styles.position}>
              <Text>{pdfReport?.contactPerson.title}</Text>
            </View>
            <View style={styles.contactInfo}>
              <Text>M: {pdfReport?.contactPerson.mobile}</Text>
            </View>
            <View style={styles.contactInfo}>
              <Text>T: {pdfReport?.contactPerson.tel}</Text>
            </View>
            <View style={styles.contactInfo}>
              <Text>
                E:{' '}
                <Link src={`mailto:${pdfReport?.contactPerson.email}`} style={styles.link}>
                  {pdfReport?.contactPerson.email}
                </Link>
              </Text>
            </View>
            <View style={styles.contactInfo}>
              <Text>
                W:{' '}
                <Link style={styles.link} src={pdfReport?.contactPerson.website || ''}>
                  {pdfReport?.contactPerson.website}
                </Link>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footerLogo}>
          <Image src="/img/inno2fleet-logo-pdf.png" style={styles.logo} />
        </View>
      </Page>
    </>
  );
};

export default Contact;
