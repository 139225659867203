import { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './styles';

interface IProps {
  title: string;
}

const PackageTitle: FC<IProps> = ({ title }) => {
  return (
    <View style={styles.titleColumn}>
      <Text style={styles.packagesTitle}>{title}</Text>
    </View>
  );
};

export default PackageTitle;
